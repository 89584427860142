.nm-layer-dialogue .nm-3col .nm-50col:first-child .nm-audicode-display {
	border: none;
	max-width: 100%;
	padding-right: 0;
	width: 100%;
}

.nm-audicode-display {
	display: flex;
	flex-wrap: nowrap;
}

.nm-audicode-display .nm-audicode-display-audicode,
.nm-audicode-display .nm-audicode-display-qrcode {
	width: 50%;
}

.nm-audicode-display .nm-audicode-display-audicode {
	position: relative;
}

.nm-audicode-display .nm-audicode-display-qrcode {
	padding-left: 30px;
	padding-top: 45px;
}

.nm-audicode-display .nm-audicode-display-audicode::after {
	border-right: 1px solid #d0d3d4;
	content: "";
	height: 85%;
	padding-left: 30px;
	position: absolute;
	right: 0;
	top: 50px;
}

.nm-layer-dialogue .nm-3col .nm-50col .nm-audicode-form {
	padding-left: 0;
}

@media all and (max-width: 749px) {
	.nm-audicode-display .nm-audicode-display-audicode {
		width: 100%;
	}

	.nm-audicode-display .nm-audicode-display-qrcode {
		display: none;
	}

	.nm-audicode-display .nm-audicode-display-audicode::after {
		border: 0;
	}

	.nm-state-is-responsive .nm-layer-dialogue .nm-3col .nm-50col {
		border: none;
		padding: 0;
		width: 100%;
	}

	.nm-state-is-responsive .nm-layer-dialogue .nm-3col .nm-50col:first-child {
		margin-bottom: 50px;
	}

	.nm-state-is-responsive .nm-layer-dialogue .nm-audicode-form .nm-remarks {
		margin-bottom: 0;
	}

	.nm-state-is-responsive .nm-layer-dialogue .nm-audicode-form input {
		height: 40px !important;
	}
}
