.nm-md-aoz {
	margin-bottom: 30px;
}

.nm-md-aoz .nm-md-aoz-landscape,
.nm-md-aoz .nm-md-aoz-portrait {
	background-repeat: no-repeat;
	background-size: 100%;
	height: 0;
	width: 100%;
}

.nm-md-aoz .nm-md-aoz-loader {
	background-image: url("../assets/img/homepage/spinner.gif");
	background-repeat: no-repeat;
	bottom: 35px;
	display: none;
	height: 5px;
	left: calc(50% - 19px);
	position: absolute;
	width: 37px;
}

.nm-md-aoz .nm-md-aoz-landscape-wrapper,
.nm-md-aoz .nm-md-aoz-portrait-wrapper {
	overflow: hidden;
	position: relative;
}

.nm-md-aoz .nm-md-aoz-landscape {
	padding-bottom: 31.25%;
	transition: padding 1s;
}

.nm-md-aoz .nm-md-aoz-portrait {
	padding-bottom: 60%;
}

.nm-md-aoz.nm-md-aoz-mode-landscape .nm-md-aoz-portrait-wrapper,
.nm-md-aoz.nm-md-aoz-mode-portrait .nm-md-aoz-landscape-wrapper {
	display: none;
}

@media all and (max-aspect-ratio: 1/1) {
	.nm-md-aoz.nm-md-aoz-mode-automatic .nm-md-aoz-landscape-wrapper {
		display: none;
	}
}

@media all and (min-aspect-ratio: 1/1) {
	.nm-md-aoz.nm-md-aoz-mode-automatic .nm-md-aoz-portrait-wrapper {
		display: none;
	}
}

.nm-aoz-description {
	margin-top: 25px;
}

.nm-aoz-price {
	margin-bottom: 15px;
}

/* remove negative margins if global 4% margin on .nm-content gets removed */
.nm-md-aoz-tilelist-wrapper {
	height: 116px;
	margin-left: -4.35%;
	margin-right: -4.35%;
	overflow: hidden;
	position: relative;
}

.nm-md-aoz .nm-aoz-tile-list {
	display: flex;
	flex-flow: row nowrap;
	overflow: hidden;
	overflow-x: scroll;
	padding-bottom: 20px;
	width: 100%;
}

.nm-md-aoz .nm-aoz-item {
	border: 2px solid transparent;
	flex-shrink: 0;
	float: left;
}

.nm-md-aoz .nm-aoz-item.nm-aoz-active {
	border: 2px solid var(--color-progressive-red);
}

.nm-md-aoz .nm-aoz-item.nm-j-configurator-status_11010 .nm-tile-element-image::after {
	background: var(--color-progressive-red) no-repeat 10px 10px url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJAQMAAAA8dl8XAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAYdEVYdENyZWF0aW9uIFRpbWUAMjkuMDMuMjAxMxbGg5cAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAAAI0lEQVQImWNgUGBgKGBgeMDAeIDBuYHhOQNDHQODDQODBAMAQu8ErolmuY0AAAAASUVORK5CYII=");
	border-radius: 15px;
	bottom: -10px;
	content: " ";
	height: 30px;
	position: absolute;
	right: -6px;
	width: 30px;
	z-index: 100;
}

.nm-md-aoz .nm-tile-element-image {
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	height: 90px;
	margin: 1px;
	width: 90px;
}

.nm-md-aoz .nm-tile-element-image {
	float: left;
	position: relative;
}

.nm-md-aoz .nm-tile-element-image img {
	width: 100%;
}

.nm-tile-block .nm-j-configurator-status_10010 .nm-tile-element-image a {
	border: 3px solid transparent;
}

.nm-pattern-tile .nm-j-configurator-status_10010 .nm-tile-element-image a::after {
	display: none;
}

.nm-md-aoz .nm-aoz-btn {
	margin: 10px 0 20px;
}

.nm-md-aoz .nm-el-btn-02 {
	max-width: 300px;
}

.nm-aoz-invisible {
	visibility: hidden;
}

.nm-md-aoz .nm-aoz-stripe {
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100%;
}

.nm-md-aoz .nm-aoz-stripe > ul {
	display: flex;
	flex-wrap: nowrap;
}

.nm-md-aoz .nm-aoz-stripe-navigation {
	height: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-md-aoz .nm-aoz-stripe-navigation li {
	background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 20%, #fff 100%);
	cursor: pointer;
	display: none;
	float: left;
	height: 90px;
}

.nm-md-aoz .nm-aoz-stripe-wrap.nm-j-aoz-show-prev-arrow .nm-aoz-stripe-navigation .nm-aoz-stripe-prev,
.nm-md-aoz .nm-aoz-stripe-wrap.nm-j-aoz-show-next-arrow .nm-aoz-stripe-navigation .nm-aoz-stripe-next {
	display: block;
}

.nm-md-aoz .nm-aoz-stripe-navigation li.nm-aoz-stripe-next {
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 20%, #fff 100%);
	float: right;
}

.nm-md-aoz .nm-aoz-stripe-navigation li svg {
	height: 48px;
	margin-top: 50%;
	width: 48px;
}

.nm-md-aoz .nm-aoz-stripe > ul li.nm-aoz-stripe-item {
	animation: nm-aoz-strip-animate-in .3s ease-out forwards;
	box-sizing: border-box;
	opacity: 0;
	position: relative;
	transform: translate3d(-100%, 0 0 );
}

@keyframes nm-aoz-strip-animate-in {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@media screen and (min-width: 750px) {
	.nm-md-aoz-tilelist-wrapper {
		margin-left: 0;
		margin-right: 0;
	}
}
