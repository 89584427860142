/*	module - summary-standard-highlights
=========================================================================== */

.nm-md-summary-standard-highlights {
	margin-bottom: 44px;
}

.nm-summary-standard-highlights-list {
	display: block;
	width: 100%;
}

.nm-md-summary-standard-highlights li {
	display: none;
	margin: 0 -webkit-calc(2.4% - 4px) 25px 0;
	margin: 0 calc(2.4% - 4px) 25px 0;
	position: relative;
	vertical-align: top;
	width: 30.37%;
}

.nm-summary-standard-highlights-list .nm-j-configurator-status_10011 {
	display: inline-block;
}

.nm-md-summary-standard-highlights .nm-list-item-image {
	display: block;
	height: 0;
	line-height: 0;
	margin-bottom: 15px;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-md-summary-standard-highlights .nm-list-item-image img {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-md-summary-standard-highlights .nm-list-item-image.nm-list-item-image-200 img {
	left: 19.67871485943776%;
	max-height: 100%;
	width: auto !important;
}

.nm-md-summary-standard-highlights .nm-el-btn.nm-el-btn-ast {
	float: right;
	max-width: none;
}

.nm-md-summary-standard-highlights .nm-el-pg {
	display: inline;
	max-width: 88%;
}

.nm-md-summary-standard-highlights .nm-info-wrap {
	margin-left: 6px;
	vertical-align: top;
}

@media all and (max-width: 1294px) {
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-highlights li {
		margin-right: -webkit-calc(2% - 5px);
		margin-right: calc(2% - 5px);
		width: 48%;
	}
}

@media screen and (max-width: 1177.6px) {
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-highlights .nm-list-item-image img {
		max-width: 100%;
	}
}

@media all and (max-width: 749px) {
	.nm-state-is-responsive .nm-3col .nm-md-summary-standard-highlights li {
		margin-right: -webkit-calc(2% - 5px);
		margin-right: calc(2% - 5px);
		width: 48%;
	}

	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-list-item-image img {
		margin-top: 0;
		max-width: 100%;
		min-width: 0;
	}
}

@media all and (max-width: 599px) {
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-highlights .nm-list-item-image {
		max-width: 100%;
	}

	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-highlights .nm-list-item-image img {
		max-width: none;
	}

	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-highlights .nm-list-item-image.nm-list-item-image-200 img {
		max-width: 100%;
	}
}

@media all and (max-width: 429px) {
	.nm-state-is-responsive .nm-3col .nm-md-summary-standard-highlights li,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-highlights li {
		margin: 0 0 30px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-list-item-image,
	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-el-pg {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: top;
	}

	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-list-item-image {
		height: auto;
		margin-right: -webkit-calc(4% - 5px);
		margin-right: calc(4% - 5px);
		overflow: visible;
		padding-bottom: 0;
		width: 39%;
	}

	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-el-pg {
		width: 48%;
	}

	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-info-wrap {
		position: absolute;
		right: 0;
		text-align: right;
		top: 0;
		width: 9%;
	}

	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-info-wrap .nm-info {
		display: inline-block;
	}

	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-list-item-image.nm-list-item-image-200 img,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-highlights .nm-list-item-image img,
	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-list-item-image img {
		height: auto;
		margin-top: 0;
		position: relative;
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-summary-standard-highlights .nm-list-item-image.nm-list-item-image-200 img {
		left: auto;
	}

	.nm-md-summary-standard-highlights .nm-el-btn-01 {
		float: left;
	}
}
