.nm-module.nm-module-options-tiles {
	margin-bottom: var(--space-xxxl);
}
/*
  to offset the reduced margin-bottom an empty div is rendered after the last options-tiles component
  this div restores the default 80px margins between modules
*/
.nm-module-options-tiles-final-spacing {
	margin-top: 80px;
}

.nm-module-options-tiles .nm-options-tiles-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-l);
}

.nm-module-options-tiles .nm-option-tile {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: var(--space-l);
}

.nm-module-options-tiles .nm-option-tile-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-s);
	width: 100%;
}

.nm-module-options-tiles .nm-option-tile-image-wrapper {
	align-self: flex-end;
	margin-bottom: var(--space-s);
	width: 230px;
}

.nm-module-options-tiles .nm-option-tile-image {
	margin: 0 auto;
	max-height: 200px;
	max-width: 230px;
	width: auto;
}

.nm-module-options-tiles .nm-option-tile-benefit {
	display: flex;
	margin-bottom: var(--space-s);
}

.nm-module-options-tiles .nm-option-tile-benefit-icon {
	flex-shrink: 0;
	height: var(--icon-size-small);
	margin-right: var(--space-s);
	stroke: #4c4c4c;
	width: var(--icon-size-small);
}

.nm-module-options-tiles .nm-option-tile-benefit-text {
	padding-top: var(--space-xxs);
}

.nm-module-options-tiles .nm-option-tile-item-wrapper {
	border: 1px solid #e5e5e5;
	margin-bottom: -1px;
	width: 100%;
}

.nm-module-options-tiles .nm-option-tile-item {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 48px auto;
	padding: var(--space-l);
	position: relative;
}

.nm-module-options-tiles .nm-option-tile-item-wrapper:last-child {
	margin-bottom: 0;
}

.nm-module-options-tiles .nm-option-tile-checkbox {
	cursor: pointer;
	grid-column: 1;
	grid-row: 1;
}

.nm-module-options-tiles .nm-option-tile-price {
	font-weight: var(--font-weight-bold);
}

.nm-module-options-tiles .nm-option-tile-price-wrapper {
	grid-column: 1;
}

.nm-module-options-tiles .nm-option-tile-text {
	color: var(--color-grey-70);
	margin-bottom: var(--space-xxs);
}

.nm-option-tile-item-image-wrapper {
	grid-column: 1;
	margin-top: var(--space-l);
}

.nm-module-options-tiles .nm-option-tile-info {
	align-items: center;
	display: flex;
	flex-direction: row-reverse;
	position: absolute;
	right: var(--space-l);
	top: var(--space-l);
}

.nm-module-options-tiles .nm-info {
	margin-left: var(--space-s);
}

.nm-icon-alert-badge,
.nm-icon-system-info-small {
	height: var(--icon-size-small);
	width: var(--icon-size-small);
}

.nm-module-options-tiles .nm-option-tile-confictlink,
.nm-module-options-tiles .nm-info {
	background: none;
	height: var(--icon-size-small);
	width: var(--icon-size-small);
}

.nm-module-options-tiles .nm-option-tile-confictlink {
	display: none;
	position: relative;
}

.nm-option-tile-non-renderable {
	margin-top: var(--space-m);
}

.nm-option-tile-non-renderable-bold {
	font-weight: var(--font-weight-bold);
}

/* option has conflict - show caution icon  */
.nm-module-options-tiles .nm-j-configurator-status_10100 .nm-option-tile-confictlink,
.nm-module-options-tiles .nm-j-configurator-status_10101 .nm-option-tile-confictlink,
.nm-module-options-tiles .nm-j-configurator-status_11100 .nm-option-tile-confictlink,
.nm-module-options-tiles .nm-j-configurator-status_11101 .nm-option-tile-confictlink {
	display: inline-block;
	position: relative;
}

/* option not de-selectable - checkbox has no cursor */
.nm-module-options-tiles .nm-j-configurator-status_10011 .nm-option-tile-checkbox,
.nm-module-options-tiles .nm-j-configurator-status_10010 .nm-option-tile-checkbox {
	cursor: default;
}

/* option is selected - checked icon is black */
/* ToDo: lower specificity - need to update core-ci first */
.nm-module-options-tiles .nm-j-configurator-status_10011 .audi-checkbox .audi-checkbox-symbol,
.nm-module-options-tiles .nm-j-configurator-status_10010 .audi-checkbox .audi-checkbox-symbol,
.nm-module-options-tiles .nm-j-configurator-status_11010 .audi-checkbox .audi-checkbox-symbol {
	color: var(--color-black);
}

/* option is selected - checked icon is black */
/* ToDo: lower specificity - need to update core-ci first */
.nm-module-options-tiles .nm-j-configurator-status_10011 .audi-checkbox .audi-checkbox-symbol .nm-icon-select-small,
.nm-module-options-tiles .nm-j-configurator-status_10010 .audi-checkbox .audi-checkbox-symbol .nm-icon-select-small,
.nm-module-options-tiles .nm-j-configurator-status_11010 .audi-checkbox .audi-checkbox-symbol .nm-icon-select-small {
	opacity: 1;
}

/* option not de-selectable - checkbox has no border, checked icon is black */
/* ToDo: lower specificity - need to update core-ci first */
.nm-module-options-tiles .nm-j-configurator-status_10011 .audi-checkbox .audi-checkbox-symbol,
.nm-module-options-tiles .nm-j-configurator-status_10010 .audi-checkbox .audi-checkbox-symbol {
	box-shadow: inset 0 0 0 1px transparent;
}

/* option is selected - option-item has black border */
.nm-module-options-tiles .nm-j-configurator-status_10011,
.nm-module-options-tiles .nm-j-configurator-status_10010,
.nm-module-options-tiles .nm-j-configurator-status_11010 {
	border-color: #000;
	z-index: 2;
}

/* option is selected - option-text is black */
.nm-module-options-tiles .nm-j-configurator-status_10011 .nm-option-tile-text,
.nm-module-options-tiles .nm-j-configurator-status_10010 .nm-option-tile-text,
.nm-module-options-tiles .nm-j-configurator-status_11010 .nm-option-tile-text {
	color: var(--color-black);
}

/* option is hidden in configuration */
.nm-module-options-tiles .nm-j-configurator-status_00000 + .nm-option-tile-non-renderable {
	display: none;
}

@media screen and (min-width: 480px) {
	.nm-module-options-tiles .nm-option-tile-item {
		grid-template-columns: 60px calc(64% - 60px) 36%;
		grid-template-rows: auto;
		padding: var(--space-l) 0;
	}

	.nm-module-options-tiles .nm-option-tile-checkbox {
		padding-left: var(--space-l);
	}

	.nm-module-options-tiles .nm-option-tile-text {
		grid-column: 2;
		grid-row: 1;
		margin-bottom: 0;
	}

	.nm-module-options-tiles .nm-option-tile-price {
		margin-bottom: var(--space-s);
		text-align: right;
	}

	.nm-module-options-tiles .nm-option-tile-price-wrapper {
		grid-column: 3;
		grid-row: 1 / span 2;
		justify-self: end;
		padding-right: var(--space-l);
	}

	.nm-module-options-tiles .nm-option-tile-info {
		position: relative;
		right: auto;
		top: auto;
	}

	.nm-option-tile-item-image-wrapper {
		grid-column: 2;
		grid-row: 2;
	}

	.nm-option-tile-item-image {
		max-width: 140px;
	}
}

@media screen and (min-width: 768px) {
	.nm-module-options-tiles .nm-option-tile-item {
		grid-template-columns: 60px calc(48% - 60px) 16% 36%;
	}

	.nm-module-options-tiles .nm-option-tile-benefits-list {
		align-self: flex-end;
		width: calc(100% - 230px - 30px);
	}

	.nm-module-options-tiles .nm-option-tile-price-wrapper {
		grid-column: 4;
		padding-left: var(--space-l);
	}

	.nm-module-options-tiles .nm-option-tile-text {
		grid-column: 2;
		grid-row: 1 / span 2;
		padding-right: var(--space-l);
	}

	.nm-module-options-tiles .nm-option-tile-text--no-image {
		grid-column: 2 / span 2;
	}

	.nm-option-tile-item-image-wrapper {
		grid-column: 3;
		grid-row: 1 / span 2;
		margin-top: 0;
	}

	.nm-option-tile-item-image {
		max-width: none;
	}
}

@media all and (min-width: 1440px) {
	[page-type=widescreen] .nm-module-options-tiles .nm-option-tile-benefits-list {
		width: calc(100% - 230px - 36px);
	}
}

@media all and (min-width: 1920px) {
	[page-type=widescreen] .nm-module-options-tiles .nm-option-tile-benefits-list {
		width: calc(100% - 230px - 42px);
	}
}
