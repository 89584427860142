/*  module - teaser-equipment-list
============================================================================= */
[page-type="widescreen"] .nm-teaser-equipment-list {
	padding-left: 4%;
	padding-right: 4%;
}

.nm-teaser-equipment-list-item {
	margin-top: var(--space-xxxl);
	width: 100%;
}

.nm-teaser-equipment-list-item__image {
	height: auto;
	margin-bottom: var(--space-s);
	width: 100%;
}

.nm-teaser-equipment-list-item__content {
	width: 100%;
}

.nm-teaser-equipment-list-item__content-header {
	display: flex;
	flex-flow: row nowrap;
}

.nm-teaser-equipment-list .nm-info-wrap {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	margin-left: auto;
	padding-left: var(--space-s);
}

.nm-teaser-equipment-list-item__conflictlink {
	display: none;
	left: auto;
	margin-right: var(--space-s);
	position: relative;
	top: auto;
}

.nm-j-configurator-status_10100 .nm-teaser-equipment-list-item__conflictlink,
.nm-j-configurator-status_10101 .nm-teaser-equipment-list-item__conflictlink,
.nm-j-configurator-status_11100 .nm-teaser-equipment-list-item__conflictlink,
.nm-j-configurator-status_11101 .nm-teaser-equipment-list-item__conflictlink {
	display: inline-block;
	position: relative;
}

.nm-teaser-equipment-list .nm-price {
	font-weight: var(--font-weight-bold);
	margin-top: var(--space-xs);
}

.nm-teaser-equipment-list-item__text {
	margin-top: var(--space-m);
}

.nm-teaser-equipment-list .nm-non-renderable-bold {
	font-weight: var(--font-weight-bold);
}

.nm-teaser-equipment-list-item__button {
	margin-top: var(--space-l);
}

.nm-teaser-equipment-list-item__icon-cancel,
.nm-teaser-equipment-list-item__icon-select,
.nm-teaser-equipment-list-item__button-text--set,
.nm-teaser-equipment-list-item__button-text--contain,
.nm-teaser-equipment-list-item__button-text--remove {
	display: none;
}

.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button {
	background-color: var(--color-white);
	border: 1px solid var(--color-black);
	color: var(--color-black);
}

.nm-j-configurator-status_00000 .nm-teaser-equipment-list-item__button {
	display: none;
}

.nm-j-configurator-status_10010 .nm-teaser-equipment-list-item__button,
.nm-j-configurator-status_10011 .nm-teaser-equipment-list-item__button {
	background-color: var(--color-grey-30);
	color: var(--color-white);
	pointer-events: none;
}

.nm-j-configurator-status_11000 .nm-teaser-equipment-list-item__button:focus,
.nm-j-configurator-status_11000 .nm-teaser-equipment-list-item__button:hover,
.nm-j-configurator-status_11100 .nm-teaser-equipment-list-item__button:focus,
.nm-j-configurator-status_11100 .nm-teaser-equipment-list-item__button:hover {
	background-color: var(--color-grey-70);
}

.nm-j-configurator-status_11000 .nm-teaser-equipment-list-item__button-text--set,
.nm-j-configurator-status_11100 .nm-teaser-equipment-list-item__button-text--set,
.nm-j-configurator-status_10010 .nm-teaser-equipment-list-item__button-text--set,
.nm-j-configurator-status_10010 .nm-teaser-equipment-list-item__icon-select,
.nm-j-configurator-status_10011 .nm-teaser-equipment-list-item__icon-select,
.nm-j-configurator-status_10011 .nm-teaser-equipment-list-item__button-text--contain,
.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button-text--contain,
.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button-text--contain,
.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__icon-select,
.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__icon-select,
.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:focus .nm-teaser-equipment-list-item__button-text--remove,
.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:hover .nm-teaser-equipment-list-item__button-text--remove {
	display: block;
}

.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:focus .nm-teaser-equipment-list-item__button-text--contain,
.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:hover .nm-teaser-equipment-list-item__button-text--contain {
	display: none;
}

.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:focus,
.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:hover {
	background-color: transparent;
	border: 1px solid var(--color-grey-70);
	color: var(--color-grey-70);
}

.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button:focus .nm-teaser-equipment-list-item__button-text--remove,
.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button:hover .nm-teaser-equipment-list-item__button-text--remove {
	display: block;
}

.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button:focus .nm-teaser-equipment-list-item__button-text--contain,
.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button:hover .nm-teaser-equipment-list-item__button-text--contain,
.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:focus .nm-teaser-equipment-list-item__icon-select,
.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:hover .nm-teaser-equipment-list-item__icon-select,
.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button:focus .nm-teaser-equipment-list-item__icon-select,
.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button:hover .nm-teaser-equipment-list-item__icon-select {
	display: none;
}

.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:focus .nm-teaser-equipment-list-item__icon-cancel,
.nm-j-configurator-status_11001 .nm-teaser-equipment-list-item__button:hover .nm-teaser-equipment-list-item__icon-cancel,
.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button:focus .nm-teaser-equipment-list-item__icon-cancel,
.nm-j-configurator-status_11010 .nm-teaser-equipment-list-item__button:hover .nm-teaser-equipment-list-item__icon-cancel {
	display: block;
}

.nm-teaser-equipment-list .nm-icon-system-info-small {
	height: 2.4rem;
	width: 2.4rem;
}

.nm-teaser-equipment--hide-standard-equipment .nm-j-configurator-status_10011,
.nm-teaser-equipment--hide-standard-equipment .nm-j-configurator-status_11001 {
	display: none;
}

@media (min-width: 620px) {
	.nm-teaser-equipment-list-item {
		align-items: flex-start;
		border-bottom: 1px solid #e5e5e5;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding-bottom: var(--space-xxl);
	}

	/* hide standard equipment if flag is set */
	.nm-teaser-equipment--hide-standard-equipment .nm-j-configurator-status_10011,
	.nm-teaser-equipment--hide-standard-equipment .nm-j-configurator-status_11001 {
		display: none;
	}

	.nm-teaser-equipment-list-item__image {
		flex-shrink: 0;
		margin-bottom: 0;
		position: relative;
		width: 40%;
	}

	.nm-teaser-equipment-list-item__content {
		width: 100%;
	}

	.nm-teaser-equipment-list-item__image + .nm-teaser-equipment-list-item__content {
		width: calc(60% - var(--space-xl));
	}
}

/*
 * this block is here and not in the inceremental vewport hierarchy since it is
 * meant to override all previous style definitions at once starting at 1100px
 * (when 2-col and 1-col get aside and smaller)
 */

@media (min-width: 1100px) {
	.nm-1col .nm-teaser-equipment-list-item {
		display: block;
	}

	.nm-1col .nm-teaser-equipment-list-item__image {
		margin-bottom: 12px;
	}

	.nm-1col .nm-teaser-equipment-list-item__image,
	.nm-1col .nm-teaser-equipment-list-item__button,
	.nm-1col .nm-teaser-equipment-list-item__content,
	body:not([page-type="widescreen"]) .nm-1col .nm-teaser-equipment-list-item__image,
	body:not([page-type="widescreen"]) .nm-1col .nm-teaser-equipment-list-item__button,
	body:not([page-type="widescreen"]) .nm-1col .nm-teaser-equipment-list-item__content {
		width: 100%;
	}
}

/*
 * this block is here as it's meant to override the component margins/paddings set in the
 * above code, within the given context
 */
.nm-2col .nm-teaser-equipment-list,
.nm-1col .nm-teaser-equipment-list,
.nm-layer .nm-teaser-equipment-list,
.modal-layer .nm-teaser-equipment-list {
	padding-left: 0;
	padding-right: 0;
}

.nm-layer .nm-1col .nm-teaser-equipment-list-item__button {
	min-width: auto;
}

/* add a margin to the bottom if the next element is not a teaser-equipment-list */
.nm-teaser-equipment-list + :not(.nm-teaser-equipment-list) {
	margin-top: 96px;
}
