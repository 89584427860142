.nm-light-experience,
.nm-light-experience-list,
.nm-light-experience-list__headline,
.nm-light-experience-list__item-container {
	width: 100%;
}

.nm-light-experience {
	display: block;
}

.nm-light-experience-list {
	margin-bottom: var(--space-xxxl);
}

.nm-light-experience-list__item-container {
	display: flex;
	flex-direction: column;
}

.nm-light-experience-list__item {
	border: 1px solid var(--color-grey-10);
	margin-top: var(--space-l);
	position: relative;
}

.nm-light-experience-list__item-checkbox {
	background-color: var(--color-white);
	cursor: pointer;
	position: absolute;
	z-index: 1;
}

.nm-light-experience-list__item-checkbox .audi-checkbox {
	margin: var(--space-s);
	padding: var(--space-s);
}

.nm-light-experience-list__item-image-container {
	cursor: pointer;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-light-experience-list__item-image-container--empty {
	background-color: var(--color-grey-10);
}

.nm-light-experience-list__item-image {
	height: auto;
	width: 100%;
}

.nm-light-experience-list__headline {
	--list-item-margin-top: var(--space-l);
	margin-bottom: calc((var(--space-xxl) - (var(--list-item-margin-top))));
}

.nm-light-experience-list__item-description {
	margin: var(--space-l) var(--space-l) 0 var(--space-l);
}

.nm-light-experience-list__item-text-info-wrapper {
	margin-bottom: calc(var(--space-xxl) + var(--space-xl) + 2.4rem);
	position: relative;
}

.nm-light-experience-list__item-text {
	margin-right: calc(var(--space-m) + var(--icon-size-small));
	word-break: break-word;
}

.nm-light-experience-list__item-info {
	display: flex;
	flex-direction: column;
	position: absolute;
	right: 0;
	top: 0;
}

.nm-light-experience-list__item-info .nm-icon-system-info-small,
.nm-light-experience-list__item-info .nm-icon-alert-badge {
	vertical-align: middle;
}

.nm-light-experience__list-item-info-layerlink + .nm-light-experience-list__item-info-conflict {
	margin-top: var(--space-s);
}

.nm-light-experience-list__item-info-conflict {
	display: none;
}

/* option has conflict - show caution icon  */
.nm-j-configurator-status_10100 .nm-light-experience-list__item-info-conflict,
.nm-j-configurator-status_10101 .nm-light-experience-list__item-info-conflict,
.nm-j-configurator-status_11100 .nm-light-experience-list__item-info-conflict,
.nm-j-configurator-status_11101 .nm-light-experience-list__item-info-conflict {
	display: inline-block;
	position: relative;
}

/* option is selected - checked icon is black */
.nm-j-configurator-status_10011 .nm-light-experience-list__item-checkbox .audi-checkbox-symbol .nm-icon-select-small,
.nm-j-configurator-status_10010 .nm-light-experience-list__item-checkbox .audi-checkbox-symbol .nm-icon-select-small,
.nm-j-configurator-status_11010 .nm-light-experience-list__item-checkbox .audi-checkbox-symbol .nm-icon-select-small {
	opacity: 1;
}

/* option not de-selectable - checkbox and image has no cursor */
.nm-j-configurator-status_10011 .nm-light-experience-list__item-checkbox,
.nm-j-configurator-status_10010 .nm-light-experience-list__item-checkbox,
.nm-j-configurator-status_10011 .nm-light-experience-list__item-image-container,
.nm-j-configurator-status_10010 .nm-light-experience-list__item-image-container {
	cursor: default;
}

/* option is selected - option-item has black border */
.nm-light-experience-list__item.nm-j-configurator-status_10011,
.nm-light-experience-list__item.nm-j-configurator-status_10010,
.nm-light-experience-list__item.nm-j-configurator-status_11010 {
	border-color: var(--color-black);
}

.nm-light-experience-list__item-price {
	bottom: var(--space-l);
	font-weight: var(--font-weight-bold);
	position: absolute;
}

@media (min-width: 768px) {
	.nm-light-experience-list__item-container {
		flex-flow: row wrap;
	}

	.nm-light-experience-list__item {
		--border-pixel: 2px;
		margin-right: var(--space-l);
		width: calc(100% / 2 - ((1 / 2 * (var(--space-l))) + (var(--border-pixel))));
	}

	.nm-light-experience-list__item_last {
		margin-right: 0;
	}
}

@media (min-width: 1280px) and (max-width: 1439px ) {
	.nm-light-experience-list__item {
		--border-pixel: 2px;
		width: calc(100% / 3 - ((2 / 3 * (var(--space-l))) + (var(--border-pixel))));
	}
}

@media (min-width: 1440px) {
	.nm-light-experience-list__item {
		--border-pixel: 2px;
		width: calc(100% / 4 - ((3 / 4 * (var(--space-l))) + (var(--border-pixel))));
	}
}
