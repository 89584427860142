.nm-module-engine-emission-wltp-base-container {
	display: flex;
	flex-direction: column;
	gap: var(--space-s);
	padding-bottom: var(--space-xxl);
}

.nm-module-engine-emission-wltp-base-container li {
	background: none;
}

.nm-module-engine-emission-wltp-base-container sup {
	top: -5px;
}

.nm-module-engine-emission-wltp-section-container > ul {
	list-style: disc outside;
	padding-left: var(--space-l);
}

.nm-module-engine-emission-wltp-section-container {
	border: 1px solid var(--color-black);
	display: flex;
	flex-flow: column wrap;
	padding: var(--space-xs) var(--space-xxs);
	row-gap: var(--space-s);
}

.nm-module-engine-emission-wltp-first-section-container {
	border: 1px solid var(--color-black);
	display: flex;
	flex-wrap: wrap;
	padding: var(--space-xs) var(--space-xxs);
	row-gap: var(--space-s);
}

.nm-module-engine-emission-wltp-label-value-pair {
	display: flex;
	flex-basis: 100%;
	gap: var(--space-xxs);
}

.nm-module-engine-emission-wltp-label-value-pair-space-between {
	display: flex;
	flex-direction: column;
	row-gap: var(--space-m);
	justify-content: space-between;
	width: 100%;
}

.nm-module-engine-emission-wltp-label-value-pair.flex-new-line {
	flex-basis: 100%;
}

.nm-module-engine-emission-wltp-label-value-pair > dt {
	font-weight: var(--font-weight-bold);
}

.nm-module-engine-emission-wltp-label-section-container {
	display: flex;
	flex-direction: column;
	border: 1px solid var(--color-black);
}

.nm-module-engine-emission-wltp-image-container {
	min-width: 200px;
}

.nm-module-engine-emission-wltp-image-description {
	display: inline-flex;
}

.nm-module-engine-emission-wltp-image-description-combined {
	text-align: end;
	padding-right: var(--space-s);
	max-width: 403px;
	width: 53%;
}

.nm-module-engine-emission-wltp-image-description-battery {
	padding-left: var(--space-s);
}

.nm-module-engine-emission-wltp-image-headline {
	margin-bottom: var(--space-s);
}

.nm-module-engine-emission-wltp-image-section {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	border: none;
	padding: var(--space-xs) var(--space-xxs);
	flex-basis: 60%;
}

.nm-module-engine-emission-wltp-image-more-info {
	padding: var(--space-xs) var(--space-xxs);
	flex-basis: 40%;
}

.nm-module-engine-emission-wltp-image-more-info-item {
	display: inline-flex;
	justify-content: space-between;
	width: 100%;
	margin-top: var(--space-l);
}

.nm-module-engine-emission-wltp-image-more-info-item-combined {
	white-space: nowrap;
	align-content: end;
}

.nm-module-engine-emission-wltp-image-more-info > ul {
	list-style: disc inside;
	width: 100%;
	padding-left: var(--space-s);
	box-sizing: border-box;
}

.nm-module-engine-emission-wltp-image-more-info-item-float {
	float: right;
}

.nm-module-engine-emission-wltp-disclaimers-section-container {
	border: 1px solid var(--color-black);
	display: flex;
	flex-direction: column;
	padding: var(--space-xs) var(--space-xxs);
	font-size: .85em;
}

.nm-module-engine-emission-wltp-disclaimers-section-container > p {
	margin: 0;
}

.nm-module-engine-emission-wltp-last-section-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: var(--space-s);
	margin-top: var(--space-s);
}

.nm-module-engine-emission-wltp-pdf-button {
	justify-content: center;
	gap: var(--space-s);
	max-width: 100%;
}

.nm-module-engine-emission-wltp-price-nowrap {
	white-space: nowrap;
}

.nm-module-engine-emission-wltp-bold-font {
	font-weight: var(--font-weight-bold);
}

.nm-module-engine-emission-wltp-emission-cost-price-item {
	display: inline-flex;
	width: 100%;
}

.nm-module-engine-emission-wltp-image {
	width: 70%;
}

.nm-module-engine-emission-wltp-emission-cost-list-item {
	margin-bottom: var(--space-m);
}

.nm-module-engine-emission-wltp-pdf-button-note {
	font-size: .85em;
	color: var(--color-grey-60);
}

.nm-module-engine-emission-wltp-emission-cost-energy-cost {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
}

.nm-module.nm-module-engine-emission {
	margin: 0;
	padding-bottom: var(--space-xxl);
}

.nm-module-engine-emission-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xl);
}

.nm-module-engine-emission-text {
	margin-bottom: var(--space-xl);
}

.nm-module-engine-emission-images-container {
	display: flex;
	flex-flow: row wrap;
}

.nm-module-engine-emission-image-container {
	margin-bottom: var(--space-xs);
	margin-left: 0px;
	margin-right: 4%;
	min-width: 200px;
	width: 48%;
}

.nm-module-engine-emission-image-container + .nm-module-engine-emission-image-container {
	margin-right: 0;
}

.nm-module-engine-emission-image {
	max-width: 200px;
}

.nm-module-engine-emission-expenses {
	display: flex;
	flex-flow: column;
	margin-bottom: var(--space-m);
	width: 100%;
}

.nm-module-engine-emission-expenses-label {
	text-align: left;
}

.nm-module-engine-emission-expenses-value {
	flex-grow: 2;
	font-weight: var(--font-weight-bold);
	margin-top: var(--space-xs);
	text-align: left;
}

.nm-module-engine-emission-expenses-date {
	margin-bottom: var(--space-xl);
}

/* text from RTE -> a tags without extra classes */
.nm-module-engine-emission-disclaimer a {
	text-decoration: underline;
}

.nm-layer-inner > .nm-module-engine-emission {
	margin: 20px 4% 0;
}

.nm-module-engine-emission.nm-module-engine-emission-compare {
	box-sizing: border-box;
	float: left;
	margin-right: var(--space-xs);
	overflow: hidden;
	width: calc(50% - 3px);
}

.nm-module-engine-emission.nm-module-engine-emission-compare + .nm-module-engine-emission.nm-module-engine-emission-compare {
	margin-right: 0;
}

.nm-module-engine-emission {
	margin-right: var(--space-xs);
}

.nm-module-engine-emission-compare:last-of-type .nm-module-engine-emission-headline {
	visibility: hidden;
}

@media all and (max-width: 479px) {
	.nm-module-engine-emission-compare .nm-module-engine-emission-image-container {
		margin-right: 0;
		min-width: 0;
		width: 100%;
	}
}

@media all and (min-width: 480px) {
	.nm-module-engine-emission-expenses-value {
		white-space: nowrap;
	}

	.nm-layer-inner > .nm-module-engine-emission {
		margin: 75px 4% 0;
	}
}

@media all and (min-width: 750px) {
	.nm-layer-inner > .nm-module-engine-emission {
		margin: 75px 8% 0;
	}
}

@media all and (min-width: 768px) {
	.nm-module-engine-emission-wltp-label-value-pair {
		flex-basis: 65%;
		white-space: nowrap;
	}

	.nm-module-engine-emission-wltp-label-value-pair-right {
		flex-basis: 35%;
	}

	.nm-module-engine-emission-wltp-label-value-pair-space-between {
		flex-direction: row;
	}

	.nm-module-engine-emission-wltp-label-section-container {
		flex-direction: row;
	}

	.nm-module-engine-emission-wltp-image-section {
		border-right: 1px solid var(--color-black);
	}

	.nm-module-engine-emission-wltp-emission-cost-price-item {
		float: right;
		display: inline;
		width: auto;
	}

	.nm-module-engine-emission-wltp-pdf-button {
		justify-content: center;
		gap: var(--space-s);
	}

	.nm-module-engine-emission-wltp-emission-cost-energy-cost {
		white-space: nowrap;
		float: right;
		width: auto;
	}

	.nm-module-engine-emission-expenses {
		flex-flow: row;
	}

	.nm-module-engine-emission-expenses-value {
		margin: 0 0 0 var(--space-xl);
		text-align: right;
		vertical-align: top;
	}

	.nm-module-engine-emission-compare:last-of-type .nm-module-engine-emission-headline {
		visibility: visible;
	}

	.nm-module-engine-emission.nm-module-engine-emission-compare {
		border: none;
		margin-right: 4%;
		width: 48%;
	}

	.nm-module-engine-emission.nm-module-engine-emission-compare:last-of-type {
		margin: 0;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-engine-emission-wltp-image {
		max-width: 100%;
		width: auto;
	}

	.nm-module-engine-emission-wltp-image-description-combined {
		padding-right: var(--space-s);
		width: 403px;
	}
}
