/*******************************************************************

	Techdata
	scope is .nm-module-summary-techdata-list. Add it to every declaration

********************************************************************/

.nm-module-summary-techdata-list {
	/* scope */
	/* back-button positioning */
	margin-top: 75px;
	position: relative;
}

.nm-module-engine-emission + .nm-module-summary-techdata-list {
	margin-top: 0px;
}

.nm-module-engine-emission.nm-summary-list-techdata-container.nm-is-compact-mode {
	display: none;
}

.nm-module-summary-techdata-list.nm-is-compact-mode {
	margin-bottom: var(--space-xxxl);
}

/* main headline */
.nm-module-summary-techdata-list .nm-headline-main {
	font-weight: var(--font-weight-bold);
	margin-bottom: 30px;
}

.nm-module-summary-techdata-list .nm-summary-list-toggle {
	display: none;
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
}

.nm-mediathek-description .nm-module-summary-techdata-list .nm-summary-list-toggle {
	display: block;
}

/* --- inpage navigation --- */
.nm-module-summary-techdata-list .nm-navigation {
	display: inline-flex;
	margin-bottom: 36px;
	margin-top: 42px;
}

.nm-module-summary-techdata-list .nm-navigation-item {
	opacity: .5;
}

/* navigational highlight */
.nm-module-summary-techdata-list .nm-navigation-item-active {
	opacity: 1;
	pointer-events: none;
}

/* navigational items */
.nm-module-summary-techdata-list .nm-navigation-listitem:not(:last-child) {
	margin-right: 30px;
}

/* techdata list headline */
.nm-module-summary-techdata-list .nm-headline-list {
	display: inline-block;
	font-weight: var(--font-weight-bold);
	margin-bottom: 24px;
}

/* --- techdata list --- */
.nm-module-summary-techdata-list .nm-techdata {
	margin-bottom: 42px;
	width: 100%;
}

/* headlines within item */
.nm-module-summary-techdata-list .nm-engine-name,
.nm-module-summary-techdata-list .nm-engine-description {
	font-weight: var(--font-weight-bold);
}

/* row */
.nm-module-summary-techdata-list .nm-techrow:first-of-type {
	border-top: 3px solid #000; /* in mobile views the border comes with the row */
}

.nm-module-summary-techdata-list .nm-techdata .nm-techkey {
	font-weight: var(--font-weight-bold);
	hyphens: auto;
	word-wrap: break-word;
}

/* key */
.nm-module-summary-techdata-list .nm-techkey {
	padding: 12px 0;
}

/* value */
.nm-module-summary-techdata-list .nm-techdesc {
	border-bottom: 1px solid #d8d8d8;
	padding-bottom: 24px;
}

/* wltp link */
.nm-module-summary-techdata-list .nm-wltp-link {
	display: inline-flex; /* controls the positioning of the arrow */
	margin-bottom: 12px;
}

/* eec class as image */
.nm-module-summary-techdata-list .nm-techdata-eec-graphic {
	width: auto;
}

/* in mediathek layer */
.nm-mediathek-description .nm-is-compact-mode .nm-summary-list-container span.nm-el-hdl-04,
.nm-mediathek-description .nm-is-compact-mode .nm-summary-list-container .nm-headline-list,
.nm-mediathek-description .nm-is-compact-mode .nm-summary-list-container .nm-wltp-link,
.nm-mediathek-description .nm-is-compact-mode .hide-in-compact-mode,
.nm-mediathek-description .nm-is-compact-mode .nm-summary-list-toggle-btn > .show-less {
	display: none;
}

.nm-mediathek-description .nm-summary-list-techdata-container strong {
	display: none;
}

.nm-mediathek-description .nm-is-compact-mode .nm-summary-list-toggle-btn > .show-more {
	display: block;
}

.nm-mediathek-description .nm-is-compact-mode .nm-summary-list {
	margin-bottom: 0;
}

.nm-mediathek-description .nm-summary-list-toggle-btn > .show-more {
	display: none;
}

.nm-mediathek-description .nm-summary-list-toggle-btn > .show-less,
.nm-mediathek-description .nm-summary-list-toggle-btn > .show-more {
	min-width: 250px;
}

.nm-mediathek-description .nm-summary-list-toggle-btn > .show-more .nm-icon-arrow,
.nm-mediathek-description .nm-summary-list-toggle-btn > .show-less .nm-icon-arrow {
	height: 2.4rem;
	vertical-align: text-bottom;
	width: 2.4rem;
}

.nm-mediathek-description .nm-summary-list-toggle-btn > .show-more .nm-icon-arrow {
	transform: rotate(90deg);
}

.nm-mediathek-description .nm-summary-list-toggle-btn > .show-less .nm-icon-arrow {
	transform: rotate(-90deg);
}

@media screen and (max-width: 689px) {
	.nm-module-summary-techdata-list .nm-summary-list-toggle {
		margin-bottom: 20px;
		position: relative;
		right: auto;
		text-align: left;
	}
}

/* main breakpoint */
@media all and (min-width: 768px) {
	.nm-module-summary-techdata-list .nm-navigation {
		margin-top: 48px;
	}

	.nm-module-summary-techdata-list .nm-techdata,
	.nm-module-summary-techdata-list .nm-techrow {
		display: flex;
		flex-flow: row wrap;
		width: 100%;
	}

	.nm-module-summary-techdata-list .nm-techdata {
		margin-bottom: 48px;
	}

	/* borders are moving to the childs of the row container */
	.nm-module-summary-techdata-list .nm-techrow:first-of-type {
		border-top: none;
	}

	.nm-module-summary-techdata-list .nm-techkey {
		border-top: 3px solid #000;
		margin-right: 6px;
		width: 32%;
	}

	.nm-module-summary-techdata-list .nm-techrow:last-of-type .nm-techkey {
		border-bottom: 3px solid #000;
	}

	.nm-module-summary-techdata-list.nm-is-compact-mode .nm-techrow:last-of-type .nm-techkey {
		border-bottom: 0;
	}

	.nm-module-summary-techdata-list .nm-techdesc {
		border-bottom: none;
		border-top: 1px solid #d8d8d8; /* top border on wider screens */
		padding: 12px 0;
		width: calc(68% - 6px); /* the techkey has a gutter of 6px */
	}

	.nm-module-summary-techdata-list .nm-techrow:last-of-type .nm-techdesc {
		border-bottom: 1px solid #d8d8d8;
	}

	.nm-module-summary-techdata-list .nm-wltp-link {
		float: right;
		margin-bottom: 0;
		padding-top: 4px;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-summary-techdata-list .nm-navigation {
		margin-bottom: 42px;
		margin-top: 60px;
	}

	.nm-module-summary-techdata-list .nm-techdata {
		margin-bottom: 60px;
	}
}

@media all and (min-width: 1440px) {
	.nm-module-summary-techdata-list .nm-headline-main {
		margin-bottom: 36px;
	}

	.nm-module-summary-techdata-list .nm-navigation-listitem:not(:last-child) {
		margin-right: 36px;
	}

	.nm-module-summary-techdata-list .nm-navigation {
		margin-bottom: 54px;
		margin-top: 78px;
	}

	.nm-module-summary-techdata-list .nm-techdata {
		margin-bottom: 78px;
	}
}

@media all and (min-width: 1920px) {
	.nm-module-summary-techdata-list .nm-headline-main {
		margin-bottom: 42px;
	}

	.nm-module-summary-techdata-list .nm-navigation-listitem:not(:last-child) {
		margin-right: 42px;
	}

	.nm-module-summary-techdata-list .nm-navigation {
		margin-bottom: 66px;
		margin-top: 96px;
	}

	.nm-module-summary-techdata-list .nm-techdata {
		margin-bottom: 96px;
	}
}
