.nm-j-configurator-container-hide {
	display: none;
}

.nm-j-configurator-status_00000,
.nm-j-configurator-status_00001,
.nm-j-configurator-status_00010,
.nm-j-configurator-status_00011,
.nm-j-configurator-status_00100,
.nm-j-configurator-status_00101,
.nm-j-configurator-status_00110,
.nm-j-configurator-status_00111,
.nm-j-configurator-status_01000,
.nm-j-configurator-status_01001,
.nm-j-configurator-status_01010,
.nm-j-configurator-status_01011,
.nm-j-configurator-status_01100,
.nm-j-configurator-status_01101,
.nm-j-configurator-status_01110,
.nm-j-configurator-status_01111,
.nm-j-configurator-status_10000 {
	display: none;
}

.nm-configurator-container-showall .nm-j-configurator-item {
	display: block;
}

.nm-configurator-container-showall tr.nm-j-configurator-item {
	display: table-row;
}
