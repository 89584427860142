/* module engine-compare */

.nm-engine-compare {
	margin-bottom: var(--space-xxxl);
	margin-top: var(--space-xl);
}

.nm-engine-compare__form {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.nm-engine-compare__engine {
	font-weight: var(--font-weight-bold);
}

.nm-engine-compare__widget {
	display: block;
	hyphens: auto;
	width: calc(50% - 3px);
}

.nm-engine-compare__widget-search,
.nm-engine-compare__widget--show-search .nm-engine-compare__widget-summary {
	display: none;
}

.nm-engine-compare__widget--show-search .nm-engine-compare__widget-search {
	display: block;
	overflow: hidden;
}

.nm-engine-compare__price {
	color: var(--color-grey-50);
	margin-top: var(--space-xxs);
}

.nm-engine-compare__button-change,
.nm-engine-compare__button-add,
.nm-engine-compare__status {
	display: flex;
	margin-top: var(--space-s);
	text-align: left;
}

.nm-engine-compare__button-change {
	margin-top: var(--space-m);
}

.nm-engine-compare__status {
	display: none;
}

/* selected option hides button and shows the link */
.nm-engine-compare__button-add.nm-j-configurator-status_11010 {
	display: none;
}

.nm-j-configurator-status_11010 + .nm-engine-compare__status {
	display: flex;
}

.nm-engine-compare__icon {
	flex-shrink: 0;
	height: 24px;
	margin-right: var(--space-s);
	width: 24px;
}

.nm-engine-compare__icon--select {
	stroke: #000;
}

/* form */
.nm-engine-compare__form-headline {
	font-weight: var(--font-weight-bold);
}

.nm-engine-compare__form-text {
	margin-top: var(--space-s);
}

.nm-engine-compare__dropdown {
	margin-bottom: 0;
	margin-top: var(--space-xl);
	width: auto;
}

.nm-engine-compare__dropdown:first-of-type {
	margin-top: var(--space-xxl);
}

.nm-engine-compare__button-submit {
	margin-top: var(--space-l);
}

/* table */
.nm-engine-compare__table {
	margin-top: var(--space-xxxl);
	max-width: 100%;
	overflow: auto;
	table-layout: fixed;
}

.nm-engine-compare__table-head {
	border-right: none;
	border-top: 3px solid #000;
	box-sizing: border-box;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	padding: var(--space-s) 0;
	text-align: left;
	width: calc(50% - 3px);
}

.nm-engine-compare__table-headline {
	font-weight: var(--font-weight-bold);
}

.nm-engine-compare__table-head:nth-child(even) .nm-engine-compare__table-headline {
	display: none;
}

.nm-engine-compare__table-row,
.nm-engine-compare__table-row:hover {
	background-color: transparent;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.nm-engine-compare__table-cell {
	background-color: transparent;
	border-bottom: var(--space-xs) solid #fff;
	border-right: none;
	border-top: 1px solid #000;
	box-sizing: border-box;
	display: flex;
	padding: var(--space-s) var(--space-xs) calc(var(--space-l) - var(--space-xs)) var(--space-xs);
	width: calc(50% - 3px);
}

.nm-engine-compare__table-cell--highlight {
	background-color: var(--color-grey-05);
}

.nm-engine-compare__table-cell--footnote {
	display: block;
}

.nm-engine-compare__table-cell--empty {
	border: none;
}

.nm-engine-compare__table-content {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.nm-engine-compare__table-text {
	hyphens: auto;
	word-break: break-word;
}

.nm-engine-compare__info {
	margin-left: var(--space-xs);
}

@media all and (max-width: 529px) { /* custom value due to default min-width of audi-button starting from 480px */
	.nm-engine-compare__button-submit {
		min-width: 0;
	}
}

@media all and (min-width: 768px) {
	.nm-engine-compare__widget,
	.nm-engine-compare__table-head,
	.nm-engine-compare__table-cell {
		width: 48%;
	}

	.nm-engine-compare__table-head:nth-child(even) .nm-engine-compare__table-headline {
		display: block;
	}
}

/* hide by scope */
.nm-scope-no-prices .nm-engine-compare__price {
	display: none;
}
