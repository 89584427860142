/*	nm-module-engine-selection - engine filter module
========================================================================== */

.nm-module-engine-selection {
	display: block;
}

.nm-module-engine-selection-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xxxl);
}

.nm-module-engine-selection-filters {
	margin-bottom: var(--space-xl);
	position: relative;
}

.nm-module-engine-selection-engine-type-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-s);
	margin-top: var(--space-xxxl);
}

.nm-module-engine-selection-footnote-link {
	margin-bottom: var(--space-xxxl);
}

.nm-module-engine-selection-engine-counter {
	color: var(--color-grey-70);
	margin-bottom: var(--space-xxxl);
}

.nm-module-engine-selection-filter-group {
	display: none;
	margin-top: var(--space-xxl);
	padding-left: var(--space-m);
	padding-right: var(--space-m);
}

.nm-module-engine-selection-filter-group:first-of-type,
.nm-module-engine-selection-extended-filters:checked ~ .nm-module-engine-selection-filter-group:first-of-type {
	margin-top: 0;
}

.nm-module-engine-selection-filter-group:last-child {
	padding-bottom: var(--space-xl);
}

.nm-module-engine-selection-filter-group-label {
	color: var(--color-grey-50);
	padding-bottom: var(--space-l);
}

.nm-module-engine-selection-extended-filters:checked ~ .nm-module-engine-selection-filter-group {
	display: block;
}

.nm-module-engine-selection-filter-label {
	display: block;
	margin-bottom: var(--space-l);
	padding-left: calc(24px + var(--space-m));
	position: relative;
}

.nm-module-engine-selection-filter-checkbox {
	left: 0;
	position: absolute;
	top: 0;
}

.nm-module-engine-selection-filter-checkbox-text {
	color: var(--color-grey-70);
}

.nm-module-engine-selection-extended-filters,
.nm-module-engine-selection-filter-radio {
	height: 0;
	opacity: 0;
	position: absolute;
	width: 0;
}

.nm-module-engine-selection-filter-radio:checked + .nm-module-engine-selection-filter-checkbox .nm-module-engine-selection-checkbox-tick {
	opacity: 1;
}

.nm-module-engine-selection-filter-radio:checked ~ .nm-module-engine-selection-filter-checkbox-text {
	color: #000;
}

.nm-module-engine-selection-filter-radio:checked ~ .nm-module-engine-selection-filter-checkbox-symbol {
	border-color: #000;
}

.nm-module-engine-selection-extended-filters-wrapper {
	background: #f2f2f2;
	box-sizing: border-box;
	margin-top: var(--space-xl);
	min-width: 15vw;
	position: relative;
	width: 100%;
}

.nm-module-engine-selection-extended-filters-dropdown {
	background: #fff;
	border-bottom: 1px solid var(--color-grey-50);
	color: var(--color-grey-70);
	display: block;
	margin-bottom: var(--space-m);
	padding: 0 24px 6px 0;
}

.nm-module-engine-selection-extended-filters-dropdown:hover {
	border-color: #000;
	box-shadow: 0 1px 0 #000;
	color: #000;
}

.audi-dropdown .nm-module-engine-selection-extended-filters-dropdown-icon {
	bottom: auto;
	top: 10px;
}

.nm-module-engine-selection-extended-filters:checked ~ .nm-module-engine-selection-extended-filters-dropdown-icon {
	transform: rotate(270deg);
}

.nm-module-engine-selection-engine {
	border: 1px solid #d9d9d9;
	margin-bottom: -1px;
	padding-top: var(--space-l);
	position: relative;
}

.nm-module-engine-selection-engine-checkbox {
	cursor: pointer;
	display: block;
	height: 24px;
	margin-bottom: var(--space-m);
	margin-left: var(--space-l);
	width: 24px;
}

.nm-module-engine-selection-engine-infolayer {
	height: 24px;
	position: absolute;
	right: var(--space-l);
	top: var(--space-l);
	width: 24px;
}

.nm-module-engine-selection-engine-layerlink-icon {
	display: inline-block;
}

.nm-module-engine-selection-engine-name {
	color: var(--color-grey-70);
	font-weight: var(--font-weight-bold);
	padding: 0 var(--space-l);
	width: 100%;
}

.nm-module-engine-selection-engine-tactical {
	background: #0000000d;
	display: inline-flex;
	font-weight: normal;
	margin-top: var(--space-m);
	padding: 8px 16px;
	vertical-align: middle;
}

.nm-module-engine-selection-tooltip,
.nm-module-engine-selection-engine-tactical .nm-tooltip-container .nm-tooltip-show {
	display: inline-flex;
	position: relative;
	height: 24px;
	vertical-align: middle;
	padding-left: var(--space-xs);
	margin-top: var(--space-m);
}

.nm-module-engine-selection-engine-tactical-text {
	padding: 0 var(--space-xs);
}

.nm-module-engine-selection-tooltip .nm-tooltip-popup .nm-tooltip-popup-text > *:not(:first-child) {
	display: block;
	margin-top: var(--space-m);
}

.nm-module-engine-selection-tooltip .nm-tooltip-popup .nm-tooltip-popup-text span {
	display: block;
}

.nm-module-engine-selection-tooltip .nm-tooltip-popup .nm-tooltip-popup-text a {
	color: inherit;
	text-decoration: underline;
}

.nm-module-engine-selection-engine-description {
	color: var(--color-grey-70);
	margin-top: var(--space-xs);
	padding: 0 var(--space-l);
}

.nm-module-engine-selection-engine-price {
	color: var(--color-grey-70);
	font-weight: var(--font-weight-bold);
	margin-top: var(--space-m);
	padding: 0 var(--space-l) var(--space-l);
}

.nm-module-engine-selection-engine-sub {
	color: var(--color-grey-70);
}

.nm-module-engine-selection-engine-techdata {
	background: #f2f2f2;
	display: flex;
	flex-flow: row wrap;
	padding: var(--space-l) var(--space-l) 0 var(--space-l);
	width: calc(100% - 2 * var(--space-l));
}

.nm-module-engine-selection-engine-techdata-entry.pkwnvkv-fields {
	min-width: 100%;
	flex-direction: column;
}

.nm-module-engine-selection-engine-techdata-entry .techdata-entry-dividers:not(:last-child) {
	margin-bottom: 12px;
}

.nm-module-engine-selection-engine-techdata.pkwnvkv-lists {
	flex-direction: column;
}

.techdata-entry-dividers {
	display: flex;
	flex-direction: column;
	word-wrap: break-word;
}

.techdata-entry-dividers > span:last-child {
	font-size: 10px;
	color: #333;
}

.nm-module-engine-selection-engine-techdata-entry {
	color: var(--color-grey-70);
	display: flex;
	flex-flow: row nowrap;
	padding-bottom: var(--space-l);
	width: 50%;
}

.nm-module-engine-selection-trimline-details {
	height: 24px;
	position: absolute;
	text-align: right;
	top: calc(0 - (24px + var(--space-xs)));
	width: 100%;
}

.nm-module-engine-selection-trimline-layerlink-icon {
	display: inline-block;
	height: 24px;
	width: 24px;
}

.nm-j-configurator-status_10011 .nm-module-engine-selection-engine-techdata-entry,
.nm-j-configurator-status_10010 .nm-module-engine-selection-engine-techdata-entry,
.nm-j-configurator-status_11010 .nm-module-engine-selection-engine-techdata-entry {
	color: #000;
}

.nm-module-engine-selection-engine-techdata-value {
	align-self: center;
	display: inline-block;
	width: calc(100% - 24px - var(--space-s));
}

.nm-module-engine-selection-engine-techdata-icon {
	align-self: center;
	height: 24px;
	margin-right: var(--space-s);
	width: 24px;
}

.nm-module-engine-selection-engine-techdata-eco-label-entry,
.nm-module-engine-selection-engine-techdata-eec-entry {
	border-top: 1px solid #d9d9d9;
	display: flex;
	justify-content: center;
	padding-bottom: var(--space-l);
	padding-top: var(--space-m);
	text-align: center;
	width: 100%;
}

.nm-module-engine-selection-engine-techdata-eco-label-entry {
	padding-bottom: var(--space-s);
	padding-top: var(--space-s);
}

.nm-module-engine-selection-legend-entry-custom-eco-label {
	align-items: center;
	display: flex;
	padding-bottom: var(--space-m);
	width: 100%;
}

.custom-eco-label-icon {
	height: 50px;
	margin-right: 0;
	vertical-align: middle;
	width: 50px;
}

.nm-module-engine-selection-custom-eec-label-icon {
	display: inline-block;
	height: 100%;
	max-height: 20px;
	max-width: 52px;
	padding-left: var(--space-xs);
	vertical-align: middle;
}

.custom-eco-label-icon--rounded {
	border-radius: 50%;
}

.nm-module-engine-selection-no-results {
	background: #f2f2f2;
	padding: var(--space-xl) 4%;
}

.nm-module-engine-selection-no-results-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-s);
}

.nm-module-engine-selection-no-results-copy {
	margin-bottom: var(--space-l);
}

.nm-module-engine-selection-legend-list {
	display: flex;
	flex-flow: row wrap;
	margin-bottom: var(--space-xl);
}

.nm-module-engine-selection-legend-headline {
	font-weight: var(--font-weight-bold);
	margin-top: var(--space-xxxl);
	padding-bottom: var(--space-l);
}

.nm-module-engine-selection-legend-entry {
	display: flex;
	flex-flow: row nowrap;
	padding-bottom: var(--space-m);
	width: 100%;
}

.nm-module-engine-selection-legend-icon {
	height: 24px;
	margin-right: var(--space-s);
	width: 24px;
}

.nm-module-engine-selection-legend-entry-custom-eco-label .custom-eco-label-icon {
	margin-right: var(--space-s);
}

/* selected */
.nm-j-configurator-status_10011.nm-module-engine-selection-engine,
.nm-j-configurator-status_10010.nm-module-engine-selection-engine,
.nm-j-configurator-status_11010.nm-module-engine-selection-engine {
	border-color: #000;
	z-index: 1;
}

.nm-j-configurator-status_10011 .nm-module-engine-selection-engine-name,
.nm-j-configurator-status_10010 .nm-module-engine-selection-engine-name,
.nm-j-configurator-status_11010 .nm-module-engine-selection-engine-name,
.nm-j-configurator-status_10011 .nm-module-engine-selection-engine-sub,
.nm-j-configurator-status_10010 .nm-module-engine-selection-engine-sub,
.nm-j-configurator-status_11010 .nm-module-engine-selection-engine-sub,
.nm-j-configurator-status_10011 .nm-module-engine-selection-engine-description,
.nm-j-configurator-status_10010 .nm-module-engine-selection-engine-description,
.nm-j-configurator-status_11010 .nm-module-engine-selection-engine-description,
.nm-j-configurator-status_10011 .nm-module-engine-selection-engine-price,
.nm-j-configurator-status_10010 .nm-module-engine-selection-engine-price,
.nm-j-configurator-status_11010 .nm-module-engine-selection-engine-price {
	color: #000;
}

.nm-j-configurator-status_10011 .nm-module-engine-selection-checkbox-symbol,
.nm-j-configurator-status_10010 .nm-module-engine-selection-checkbox-symbol,
.nm-j-configurator-status_11010 .nm-module-engine-selection-checkbox-symbol {
	border-color: #000;
}

/* TODO: reduce specificity in core-ci */
.nm-j-configurator-status_10011 .nm-module-engine-selection-checkbox-symbol .nm-module-engine-selection-checkbox-tick,
.nm-j-configurator-status_10010 .nm-module-engine-selection-checkbox-symbol .nm-module-engine-selection-checkbox-tick,
.nm-j-configurator-status_11010 .nm-module-engine-selection-checkbox-symbol .nm-module-engine-selection-checkbox-tick {
	opacity: 1;
}

@media all and (min-width: 481px) {
	.nm-module-engine-selection-engine-infos {
		align-items: center;
		display: flex;
		flex-flow: row wrap;
	}

	.nm-module-engine-selection-engine-checkbox {
		margin-bottom: 0;
		order: 1;
	}

	.nm-module-engine-selection-engine-name {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		order: 2;
		padding-left: var(--space-m);
		width: calc(100% - (3 * var(--space-l)) - var(--space-m) - 24px - 160px); /* padding, icon, price */
	}

	.nm-module-engine-selection-engine-tactical {
		margin-top: 0;
	}

	.nm-module-engine-selection-engine-name-paragraph {
		line-height: 40px;
		padding: 0 var(--space-l) 0 0;
	}

	.nm-module-engine-selection-engine-price {
		margin: 0;
		order: 3;
		padding: 0;
		text-align: right;
		width: 160px;
	}

	.nm-module-engine-selection-engine-description {
		margin-top: var(--space-s);
		order: 4;
		padding-left: calc(var(--space-l) + 24px + var(--space-m));
		padding-right: 0;
		width: calc(100% - (2 * var(--space-l)) - 48px - var(--space-m)); /* box padding, icon */
	}

	.nm-module-engine-selection-engine-infolayer {
		margin-bottom: var(--space-l);
		margin-top: var(--space-s);
		order: 5;
		position: relative;
		right: auto;
		top: auto;
	}

	.nm-module-engine-selection-engine-techdata {
		display: flex;
		flex-flow: row wrap;
		order: 6;
	}

	.nm-module-engine-selection-engine-techdata-entry {
		flex-grow: 1;
		margin-right: var(--space-xl);
		max-width: calc(33% - var(--space-xl));
		width: calc(33% - var(--space-xl));
	}

	.nm-module-engine-selection-engine-techdata-value {
		display: inline-block;
	}

	.nm-module-engine-selection-engine-techdata-eec-entry {
		justify-content: flex-end;
		padding-top: var(--space-l);
		text-align: right;
	}

	.nm-module-engine-selection-engine-techdata-eco-label-entry {
		text-align: right;
	}

	.nm-module-engine-selection-legend-entry {
		padding-right: var(--space-l);
		width: calc(50% - var(--space-l));
	}
}

@media all and (min-width: 569px) {
	.nm-module-engine-selection-extended-filters-dropdown,
	.audi-dropdown .nm-module-engine-selection-extended-filters-dropdown-icon {
		display: none;
	}

	.nm-module-engine-selection-extended-filters-wrapper {
		background: #fff;
	}

	.nm-module-engine-selection-tooltip,
	.nm-module-engine-selection-engine-tactical .nm-tooltip-container .nm-tooltip-show {
		margin-top: 0px;
	}

	.nm-module-engine-selection-filter-group,
	.nm-module-engine-selection-extended-filters:checked ~ .nm-module-engine-selection-filter-group {
		background: #fff;
		display: flex;
		flex-flow: row wrap;
		margin-top: var(--space-xxxl);
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
	}

	.nm-module-engine-selection-filter-group-label {
		padding-bottom: var(--space-m);
		width: 100%;
	}

	.nm-module-engine-selection-filter-label {
		margin-bottom: 0;
		padding-right: var(--space-l);
		width: calc(33.33% - 24px - var(--space-m) - var(--space-l));
	}

	.nm-module-engine-selection-engine-counter {
		border-bottom: 1px solid #d9d9d9;
		padding-bottom: var(--space-s);
	}

	.nm-module-engine-selection-engine-techdata-entry {
		max-width: calc(25% - var(--space-xl));
		width: calc(25% - var(--space-xl));
	}
}

@media all and (min-width: 768px) {
	.nm-module-engine-selection-engine-techdata {
		display: flex;
		flex-flow: row wrap;
		padding-bottom: 0;
	}

	.nm-module-engine-selection-engine-techdata-entry.pkwnvkv-fields {
		min-width: inherit;
	}

	ul.nm-module-engine-selection-engine-techdata.pkwnvkv-lists {
		flex-direction: row;
	}

	.nm-module-engine-selection-engine-techdata-entry {
		flex-basis: 0;
		flex-grow: 1;
		min-width: 80px;
		width: auto;
	}

	.nm-module-engine-selection-engine-techdata-eco-label-entry,
	.nm-module-engine-selection-engine-techdata-eec-entry {
		align-self: center;
		border-top: none;
		flex-grow: 1;
		justify-content: flex-end;
		padding-bottom: var(--space-l);
		padding-top: 0;
		width: auto;
	}

	.nm-module-engine-selection-extended-filters-wrapper {
		display: flex;
		flex-flow: row nowrap;
		order: 3;
	}

	.nm-module-engine-selection-filter-group,
	.nm-module-engine-selection-extended-filters:checked ~ .nm-module-engine-selection-filter-group {
		display: block;
		margin-top: 0;
		width: 33.33%;
	}

	.nm-module-engine-selection-filter-group:last-child {
		padding-bottom: 0;
	}

	.nm-module-engine-selection-filter-label {
		margin-bottom: var(--space-l);
		width: calc(100% - (2 * var(--space-l)) - var(--space-m));
	}

	.nm-module-engine-selection-filter-label:last-child {
		margin-bottom: 0;
	}

	.nm-module-engine-selection-filters {
		display: flex;
		flex-flow: row wrap;
	}

	.nm-module-engine-selection-trimline-filter-wrapper {
		order: 1;
	}

	.nm-module-engine-selection-trimline-details {
		align-self: flex-end;
		margin-bottom: calc(var(--space-s) + var(--space-xs));
		margin-left: var(--space-l);
		order: 2;
		position: relative;
		top: 0;
		width: 24px;
	}
}
