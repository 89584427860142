.nm-engine-compare-stage ~ .nm-layer-content {
	margin-top: -26px;
}

.nm-engine-compare-stage {
	height: 0;
	overflow: hidden;
	padding-bottom: 42%;
	width: 100%;
}

.nm-engine-compare-stage2 {
	position: relative;
}

.nm-engine-compare-stage img {
	bottom: 0;
	position: absolute;
}

.nm-engine-compare-stage .nm-compare-background {
	height: auto;
	left: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-engine-compare-stage .nm-compare-image-1,
.nm-engine-compare-stage .nm-compare-image-2 {
	width: 68%;
}

.nm-engine-compare-stage .nm-compare-image-1 {
	float: left;
	left: -100px;
}

.nm-engine-compare-stage .nm-compare-image-2 {
	float: right;
	right: -100px;
}

.nm-engine-compare-stage + .nm-navigation-derivative-sub {
	margin-top: -26px;
}

@media all and (max-width: 1024px) {
	.nm-engine-compare-stage .nm-compare-image-1 {
		left: -90px;
	}

	.nm-engine-compare-stage .nm-compare-image-2 {
		right: -90px;
	}
}

@media all and (max-width: 479px) {
	.nm-state-is-responsive .nm-engine-compare-stage .nm-compare-image-1 {
		left: -40px;
	}

	.nm-state-is-responsive .nm-engine-compare-stage .nm-compare-image-2 {
		right: -20px;
	}
}