/* option list https://kb.dasburo.com/pages/viewpage.action?pageId=71387167 */

.nm-module-optionlist-v2 {
	min-width: 320px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-headline-order-2 {
	margin-bottom: 36px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-headline-order-3 {
	margin-bottom: 30px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-headline-order-2,
.nm-module-optionlist-v2 .nm-module-optionlist-v2-headline-order-3 {
	font-weight: var(--font-weight-bold);
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-itemlist {
	margin-bottom: 42px; /* space-xxxl */
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-item-wrapper {
	border-color: #e5e5e5;
	border-style: solid;
	border-width: 1px;
	margin-bottom: -1px;
	position: relative;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-item-wrapper:last-child {
	margin-bottom: 0;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-item {
	min-height: 73px;
	padding: 24px;
	position: relative;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-checkbox {
	cursor: pointer;
	height: 24px;
	margin-bottom: 24px;
	margin-right: 12px;
	width: 24px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-additional-text,
.nm-module-optionlist-v2 .nm-module-optionlist-v2-itemname-text {
	color: #4c4c4c;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-conflictlink {
	display: none;
	height: 24px;
	width: 24px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-icon {
	display: inline-block;
	height: 24px;
	width: 24px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-layerlink {
	margin-left: 12px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-layerlink-icon {
	display: inline-block;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-icon-system-info-small {
	height: 24px;
	width: 24px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-itemtext {
	margin-bottom: 3px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-price {
	font-weight: var(--font-weight-bold);
	margin-bottom: 18px;
}

/* selected items (11010, 10011, 10010) */

.nm-module-optionlist-v2 .nm-j-configurator-status_11010,
.nm-module-optionlist-v2 .nm-j-configurator-status_10011,
.nm-module-optionlist-v2 .nm-j-configurator-status_10010 {
	border-color: #000;
	z-index: 1;
}

.nm-module-optionlist-v2 .nm-j-configurator-status_11010 .nm-module-optionlist-v2-itemname-text,
.nm-module-optionlist-v2 .nm-j-configurator-status_10011 .nm-module-optionlist-v2-itemname-text,
.nm-module-optionlist-v2 .nm-j-configurator-status_10010 .nm-module-optionlist-v2-itemname-text {
	color: #000;
}

/* gewählte serienausstattung */
.nm-module-optionlist-v2 .nm-j-configurator-status_10010 .nm-module-optionlist-v2-checkbox-symbol,
.nm-module-optionlist-v2 .nm-j-configurator-status_10011 .nm-module-optionlist-v2-checkbox-symbol {
	border: none;
}

.nm-module-optionlist-v2 .nm-j-configurator-status_10010 .nm-module-optionlist-v2-checkbox,
.nm-module-optionlist-v2 .nm-j-configurator-status_10011 .nm-module-optionlist-v2-checkbox {
	cursor: auto;
}

.nm-module-optionlist-v2 .nm-j-configurator-status_11010 .nm-module-optionlist-v2-checkbox-symbol,
.nm-module-optionlist-v2 .nm-j-configurator-status_10011 .nm-module-optionlist-v2-checkbox-symbol,
.nm-module-optionlist-v2 .nm-j-configurator-status_10010 .nm-module-optionlist-v2-checkbox-symbol {
	border-color: #000;
}

.nm-module-optionlist-v2 .nm-j-configurator-status_11010 .nm-module-optionlist-v2-symbol,
.nm-module-optionlist-v2 .nm-j-configurator-status_10011 .nm-module-optionlist-v2-symbol,
.nm-module-optionlist-v2 .nm-j-configurator-status_10010 .nm-module-optionlist-v2-symbol {
	opacity: 1;
}

/* show conflict icon if item has conflict status */

.nm-module-optionlist-v2 .nm-j-configurator-status_10100 .nm-module-optionlist-v2-conflictlink,
.nm-module-optionlist-v2 .nm-j-configurator-status_10101 .nm-module-optionlist-v2-conflictlink,
.nm-module-optionlist-v2 .nm-j-configurator-status_11100 .nm-module-optionlist-v2-conflictlink,
.nm-module-optionlist-v2 .nm-j-configurator-status_11101 .nm-module-optionlist-v2-conflictlink {
	display: inline-block;
	position: relative;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-icon-conflict {
	display: block;
	height: 24px;
	width: 24px;
}

/* video overlay */
.nm-module-optionlist-v2 .nm-module-optionlist-v2-video {
	position: relative;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-video,
.nm-module-optionlist-v2 .nm-module-optionlist-v2-image-layerlink {
	color: #fff;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-video:hover,
.nm-module-optionlist-v2 .nm-module-optionlist-v2-image-layerlink:hover {
	color: #e5e5e5;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-video-play-small,
.nm-module-optionlist-v2 .nm-module-optionlist-v2-video-play-large {
	fill: currentColor;
	position: absolute;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-video-play-small {
	height: 24px;
	left: calc(50% - 12px);
	opacity: 0;
	top: calc(50% - 12px);
	width: 24px;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-video-play-large {
	height: 48px;
	left: calc(50% - 24px);
	opacity: 1;
	top: calc(50% - 24px);
	width: 48px;
}

/* layout */
.nm-module-optionlist-v2 .nm-module-optionlist-v2-item {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-checkbox {
	order: 1;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-itemtext {
	order: 2;
	width: 100%;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-price-icons {
	order: 3;
	width: 100%;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-image {
	order: 4;
	width: 100%;
}

.nm-module-optionlist-v2 .nm-module-optionlist-v2-iconwrapper {
	position: absolute;
	right: 24px;
	top: 24px;
}

.nm-module-optionlist-v2-itemtext ul.nm-otp-list {
	padding-left: 16px;
	padding-top: 16px;
}

.nm-module-optionlist-v2-itemtext ul.nm-otp-list li {
	list-style: disc;
	background: none;
	padding: 0;
}

@media all and (min-width: 480px) {
	.nm-module-optionlist-v2 .nm-module-optionlist-v2-item {
		align-content: stretch;
		align-items: flex-start;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-checkbox {
		order: 1;
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-itemtext {
		order: 2;
		width: calc(64% + (48px * .64) - 24px - 36px); /* width + percentage of box-padding - right padding - width of checkbox */
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-image {
		margin-left: 36px;
		order: 4;
		width: 100%;
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-video-play-large {
		left: calc(70px - 24px);
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-image-src {
		width: 140px;
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-price-icons {
		order: 3;
		position: absolute;
		right: 24px;
		text-align: right;
		top: 24px;
		width: calc(36% - 24px);
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-iconwrapper {
		position: relative;
		right: auto;
		top: auto;
	}
}

@media all and (min-width: 768px) {
	.nm-module-optionlist-v2 .nm-module-optionlist-v2-itemlist {
		margin-bottom: 48px; /* space-xxxl */
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-video-play-small {
		opacity: 1;
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-video-play-large {
		opacity: 0;
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-checkbox {
		order: 1;
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-itemtext {
		order: 2;
		padding-right: 24px;
		width: calc(48% + (48px * .48) - 24px - 36px - 24px);
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-image {
		margin-left: 0;
		order: 3;
		width: calc(16% + (48px * .16));
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-image-src {
		height: auto;
		width: 100%;
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-price-icons {
		order: 4;
		padding-left: 24px;
		position: relative;
		right: 0;
		top: 0;
		width: calc(36% + (48px * .36) - 24px - 24px);
	}
}

@media all and (min-width: 1024px) {
	.nm-module-optionlist-v2 .nm-module-optionlist-v2-itemlist {
		margin-bottom: 60px; /* space-xxxl */
	}

	.nm-module-optionlist-v2 .nm-module-optionlist-v2-headline-order-2 {
		margin-bottom: 42px;
	}
}

@media all and (min-width: 1440px) {
	[page-type="widescreen"] .nm-module-optionlist-v2 .nm-module-optionlist-v2-itemlist {
		margin-bottom: 78px; /* space-xxxl */
	}

	[page-type="widescreen"] .nm-module-optionlist-v2 .nm-module-optionlist-v2-headline-order-2 {
		margin-bottom: 54px;
	}

	[page-type="widescreen"] .nm-module-optionlist-v2 .nm-module-optionlist-v2-headline-order-3 {
		margin-bottom: 36px;
	}
}

@media all and (min-width: 1920px) {
	[page-type="widescreen"] .nm-module-optionlist-v2 .nm-module-optionlist-v2-itemlist {
		margin-bottom: 96px; /* space-xxxl */
	}

	[page-type="widescreen"] .nm-module-optionlist-v2 .nm-module-optionlist-v2-headline-order-2 {
		margin-bottom: 66px;
	}

	[page-type="widescreen"] .nm-module-optionlist-v2 .nm-module-optionlist-v2-headline-order-3 {
		margin-bottom: 42px;
	}
}
