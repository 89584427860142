/**************************************************************************
	Module Summary Dynamic Recommendations
**************************************************************************/

.nm-module.nm-module-summary-dynamic-recommendation {
	margin-bottom: 42px; /* space-3xl */
}

[page-type="widescreen"] .nm-module-summary-dynamic-recommendation {
	padding: 0 4%;
}

.nm-module-summary-dynamic-recommendation-headline-main {
	font-weight: var(--font-weight-bold);
	margin-bottom: 24px;
}

.nm-module-summary-dynamic-recommendation-paragraph {
	margin-bottom: 18px;
}

.nm-module-summary-dynamic-recommendation-itemlist {
	margin-bottom: 36px; /* space-xxl */
}

.nm-module-summary-dynamic-recommendation-item {
	border-color: #e5e5e5;
	border-style: solid;
	border-width: 1px;
	margin-bottom: -1px;
	min-height: 73px;
	padding: 24px;
	position: relative;
}

.nm-module-summary-dynamic-recommendation-checkbox {
	cursor: pointer;
	height: 24px;
	margin-bottom: 24px;
	margin-right: 12px;
	order: 1;
	width: 24px;
}

.nm-module-summary-dynamic-recommendation-additional-text,
.nm-module-summary-dynamic-recommendation-itemname-text {
	color: #4c4c4c;
}

.nm-module-summary-dynamic-recommendation-iconlink {
	display: inline-block;
	height: 24px;
	width: 24px;
}

.nm-module-summary-dynamic-recommendation-icon-system-info-small {
	height: 24px;
	width: 24px;
}

.nm-module-summary-dynamic-recommendation-price {
	font-weight: var(--font-weight-bold);
	margin-bottom: 18px;
}

/* selected items (11010, 10011, 10010) */

.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_11010,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10011,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10010 {
	border-color: #000;
	z-index: 1;
}

.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_11010 .nm-module-summary-dynamic-recommendation-itemname-text,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10011 .nm-module-summary-dynamic-recommendation-itemname-text,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10010 .nm-module-summary-dynamic-recommendation-itemname-text {
	color: #000;
}

/* contained standard */
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10010 .nm-module-summary-dynamic-recommendation-checkbox-symbol,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10011 .nm-module-summary-dynamic-recommendation-checkbox-symbol {
	border: none;
}

.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10010 .nm-module-summary-dynamic-recommendation-checkbox,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10011 .nm-module-summary-dynamic-recommendation-checkbox {
	cursor: auto;
}

.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_11010 .nm-module-summary-dynamic-recommendation-checkbox-symbol,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10011 .nm-module-summary-dynamic-recommendation-checkbox-symbol,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10010 .nm-module-summary-dynamic-recommendation-checkbox-symbol {
	border-color: #000;
}

.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_11010 .nm-module-summary-dynamic-recommendation-symbol,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10011 .nm-module-summary-dynamic-recommendation-symbol,
.nm-module-summary-dynamic-recommendation-item.nm-j-configurator-status_10010 .nm-module-summary-dynamic-recommendation-symbol {
	opacity: 1;
}

/* video overlay */
.nm-module-summary-dynamic-recommendation-video {
	position: relative;
}

.nm-module-summary-dynamic-recommendation-video,
.nm-module-summary-dynamic-recommendation-image-layerlink {
	color: #fff;
}

.nm-module-summary-dynamic-recommendation-video:hover,
.nm-module-summary-dynamic-recommendation-image-layerlink:hover {
	color: #e5e5e5;
}

.nm-module-summary-dynamic-recommendation-video-play-small,
.nm-module-summary-dynamic-recommendation-video-play-large {
	fill: currentColor;
	position: absolute;
}

.nm-module-summary-dynamic-recommendation-video-play-small {
	height: 24px;
	left: calc(50% - 12px);
	opacity: 0;
	top: calc(50% - 12px);
	width: 24px;
}

.nm-module-summary-dynamic-recommendation-video-play-large {
	height: 48px;
	left: calc(50% - 24px);
	opacity: 1;
	top: calc(50% - 24px);
	width: 48px;
}

/* layout */
.nm-module-summary-dynamic-recommendation-item {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.nm-module-summary-dynamic-recommendation-itemtext {
	hyphens: auto;
	margin-bottom: 6px;
	order: 2;
	width: 100%;
}

.nm-module-summary-dynamic-recommendation-price-icons {
	order: 3;
	width: 100%;
}

.nm-module-summary-dynamic-recommendation-image-offerrate-wrapper {
	display: flex;
	flex-flow: column-reverse nowrap;
	order: 4;
	width: 100%;
}

.nm-module-summary-dynamic-recommendation-image {
	margin-top: 18px;
}

.nm-module-summary-dynamic-recommendation-iconwrapper {
	position: absolute;
	right: 24px;
	top: 24px;
}

.nm-module-summary-dynamic-recommendation-legend-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: 24px;
}

.nm-module-summary-dynamic-recommendation-legend-wrapper {
	align-items: center;
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
}

.nm-module-summary-dynamic-recommendation-icon-offer-rate-small {
	flex-shrink: 0;
	height: 24px;
	margin-right: 18px;
	transform: rotate(-90deg);
	width: 24px;
}

@media all and (min-width: 480px) {
	.nm-module-summary-dynamic-recommendation-item {
		align-content: stretch;
		align-items: flex-start;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	}

	.nm-module-summary-dynamic-recommendation-checkbox {
		margin-bottom: 0;
	}

	.nm-module-summary-dynamic-recommendation-itemtext {
		margin-bottom: 12px;
		width: calc(64% + (48px * .64) - 24px - 36px); /* width + percentage of box-padding - right padding - width of checkbox */
	}

	.nm-module-summary-dynamic-recommendation-image-offerrate-wrapper {
		flex-flow: row nowrap;
		margin-left: 36px;
	}

	.nm-module-summary-dynamic-recommendation-image {
		margin-right: 18px;
		margin-top: 0;
	}

	.nm-module-summary-dynamic-recommendation-offerrate-wrapper {
		order: 4;
	}

	.nm-module-summary-dynamic-recommendation-video-play-large {
		left: calc(70px - 24px);
	}

	.nm-module-summary-dynamic-recommendation-price-icons {
		text-align: right;
	}

	.nm-module-summary-dynamic-recommendation-price {
		margin-bottom: 12px;
	}

	.nm-module-summary-dynamic-recommendation-iconwrapper {
		position: relative;
		right: auto;
		top: auto;
	}
}

@media all and (min-width: 480px) and (max-width: 767px) {
	.nm-module-summary-dynamic-recommendation-image {
		width: 140px;
	}

	.nm-module-summary-dynamic-recommendation-price-icons {
		position: absolute;
		right: 24px;
		top: 24px;
		width: calc(36% - 24px);
	}
}

@media all and (min-width: 768px) {
	.nm-module.nm-module-summary-dynamic-recommendation {
		margin-bottom: 48px; /* space-3xl */
	}

	.nm-module-summary-dynamic-recommendation-video-play-small {
		opacity: 1;
	}

	.nm-module-summary-dynamic-recommendation-video-play-large {
		opacity: 0;
	}

	.nm-module-summary-dynamic-recommendation-itemtext {
		box-sizing: border-box;
		margin-bottom: 0;
		padding-right: 24px;
		width: calc(48% + (48px * .48) - 24px - 36px);
	}

	.nm-module-summary-dynamic-recommendation-image-offerrate-wrapper {
		justify-content: flex-end;
		margin-left: 0;
		width: calc(27% + (48px * .27));
	}

	.nm-module-summary-dynamic-recommendation-image {
		margin-left: 0;
		order: 3;
	}

	.nm-module-summary-dynamic-recommendation-offerrate-wrapper {
		order: 4;
	}

	.nm-module-summary-dynamic-recommendation-price-icons {
		box-sizing: border-box;
		order: 5;
		padding-left: 24px;
		width: calc(25% + (48px * .25) - 24px);
	}
}

@media all and (min-width: 1024px) {
	.nm-module.nm-module-summary-dynamic-recommendation {
		margin-bottom: 60px; /* space-3xl */
	}

	.nm-module-summary-dynamic-recommendation-itemlist {
		margin-bottom: 42px; /* space-xxl */
	}
}

@media all and (min-width: 1440px) {
	[page-type="widescreen"] .nm-module.nm-module-summary-dynamic-recommendation {
		margin-bottom: 78px; /* space-3xl */
	}

	[page-type="widescreen"] .nm-module-summary-dynamic-recommendation-itemlist {
		margin-bottom: 54px; /* space-xxl */
	}

	[page-type="widescreen"] .nm-module-summary-dynamic-recommendation-headline-order-3 {
		margin-bottom: 36px; /* space-xl */
	}
}

@media all and (min-width: 1920px) {
	[page-type="widescreen"] .nm-module.nm-module-summary-dynamic-recommendation {
		margin-bottom: 96px; /* space-3xl */
	}

	[page-type="widescreen"] .nm-module-summary-dynamic-recommendation-itemlist {
		margin-bottom: 66px; /* space-xxl */
	}

	[page-type="widescreen"] .nm-module-summary-dynamic-recommendation-headline-order-3 {
		margin-bottom: 42px; /* space-xl */
	}
}
