.nm-summary-options__headline {
	margin-bottom: var(--space-l);
}

.nm-summary-options__carname-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xs);
}

.nm-summary-options__base-price {
	margin-top: var(--space-l);
}

.nm-scope-no-prices .nm-summary-options__base-price {
	display: none;
}

@media all and (min-width: 480px) {
	.nm-summary-options__base-price {
		text-align: right;
	}
}
