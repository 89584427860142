/* summary-trimline */
.nm-md-summary-trimline h2 {
	margin-bottom: 10px;
}

/* summary list, summary techdata */
.nm-md-summary-trimline .nm-summary-list {
	margin-bottom: 70px;
}

.nm-md-summary-trimline .nm-summary-list th {
	background: #e7e9ea;
	border-bottom: 2px solid #fff;
	font-weight: 700;
	min-width: 120px;
	text-align: left;
	vertical-align: top;
	width: 30.37%;
}

.nm-md-summary-trimline .nm-summary-list th,
.nm-md-summary-trimline .nm-summary-list td {
	box-sizing: border-box;
	padding: 12px;
}

.nm-md-summary-trimline .nm-summary-list td {
	border-right: none;
}

.nm-md-summary-trimline .nm-summary-list tr:hover {
	/* overwrites hover-effect */
	background: #e7e9ea;
}

.nm-md-summary-trimline .nm-summary-list tr:last-child td {
	border-bottom: 2px solid #fff;
}

/* Item Hiding */

.nm-md-summary-trimline .nm-summary-list tr.nm-j-configurator-status_11011,
.nm-md-summary-trimline .nm-summary-list tr.nm-j-configurator-status_11010,
.nm-md-summary-trimline .nm-summary-list tr.nm-j-configurator-status_10011 {
	display: table-row;
}

.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_10010,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_11001,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_11000,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_11001,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_11000,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_10001,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_10000,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_11101,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_11100,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_10101,
.nm-md-summary-trimline .nm-summary-list .nm-j-configurator-status_10100 {
	display: none;
}
