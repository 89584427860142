.carstore-list {
	display: block;
	width: 100%;
}

.carstore-list__headline {
	font-weight: var(--font-weight-bold);
}

.carstore-list__intro-text {
	margin-top: var(--space-m);
}

.carstore-list__error-message {
	color: var(--color-red);
	margin-top: var(--space-l);
}

.carstore-list__error-message-text {
	display: block;
}

.carstore-save__button {
	margin: var(--space-l) 0;
}

@media all and (min-width: 768px) {
	.carstore-list__error-message {
		margin-top: 0;
		order: -1;
		width: 40%;
	}
}
