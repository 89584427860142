.nm-layer-wrapper-renderinglayer {
	height: 100%;
	max-width: 100%;
	overflow-y: hidden;
	text-align: center;
	width: 100%;
	z-index: 102;
}

/* inserts a ghost element for enableing the layer to be centered vertically */
.nm-layer-wrapper-renderinglayer::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.nm-layer-wrapper-renderinglayer .nm-layer-renderinglayer {
	display: inline-block;
	max-width: 100%;
	min-width: 320px;
	position: relative;
	text-align: left;
	transition: all .5s;
	vertical-align: middle;
	width: 1400px;
}

.nm-layer-wrapper-renderinglayer .nm-layer-renderinglayer.ave-layer {
	max-width: 1112px;
	width: 100%;
}

.nm-layer-renderinglayer .nm-layer-toolbar {
	height: 0;
	position: relative;
}

.nm-layer-renderinglayer.ave-layer .nm-button-close {
	z-index: 5;
}

.nm-layer-renderinglayer .nm-icon-close {
	background-position: 0 0;
}

.nm-layer-renderinglayer.ave-layer .nm-icon-close {
	z-index: 5;
}

.nm-renderinglayer-wrapper {
	background: #000;
	display: table;
	height: 100%;
	margin: 0 auto;
	max-width: 100%;
}

.nm-renderinglayer {
	display: table-cell;
	max-height: 100%;
	max-width: 100%;
	vertical-align: middle;
	width: auto;
}

.nm-renderinglayer .nm-renderinglayer-stagewrapper {
	height: 0;
	max-width: 1400px;
	padding-bottom: 60%;
	position: relative;
	transition: all .5s;
	width: 100%;
}

.nm-layer-renderinglayer.ave-layer .nm-renderinglayer .nm-renderinglayer-stagewrapper {
	max-width: 1112px;
}

.nm-renderinglayer .nm-renderinglayer-stagebrowse {
	max-height: 100%;
	max-width: 100%;
}

.nm-renderinglayer .nm-renderinglayer-stagebrowse li {
	position: absolute;
	top: -webkit-calc(50% - 20px);
	top: calc(50% - 20px);
	z-index: 1;
}

.nm-renderinglayer .nm-renderinglayer-stagebrowse a {
	display: block;
	height: 50px;
	width: 50px;
}

.nm-renderinglayer .nm-renderinglayer-stagebrowse .nm-backarrow {
	left: 20px;
	z-index: 5;
}

.nm-renderinglayer .nm-renderinglayer-stagebrowse .nm-nextarrow {
	right: 20px;
	z-index: 5;
}

.nm-renderinglayer .nm-renderinglayer-stage {
	height: 0;
	max-width: 1400px;
	overflow: hidden;
	padding-bottom: 60%;
	position: relative;
	transition: all .5s;
	width: 100%;
}

.nm-layer-renderinglayer.ave-layer .nm-renderinglayer .nm-renderinglayer-stage {
	max-width: 1112px;
}

.nm-renderinglayer .nm-renderinglayer-stageimages {
	left: 0;
	line-height: 0;
	position: relative;
	width: 200%;
}

.nm-renderinglayer .nm-renderinglayer-stageimages:hover {
	cursor: url("data:image/png;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAA//AAAd/wAAGf+AAAH9gAADbYAAA2yAAAZsAAAGbAAAAGAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//4AB//8AAf//AAD//5AA///gAP//4AD//8AF///AB///5A////5///8="), auto;
}

.nm-renderinglayer .nm-renderinglayer-stageimages:active {
	cursor: url("data:image/png;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAAH/AAAB/wAAA/0AAANsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//+AB///wAf//4AH//+AD///yT/////////////////////////////8="), auto;
}

.nm-renderinglayer .nm-renderinglayer-stageimages.nm-animate {
	transition: all .5s;
}

.nm-renderinglayer .nm-renderinglayer-imagewrapper {
	background-image: url("../assets/img/bg-stage.jpg");
	background-position: 50% 44%, 100% 100%;
	background-repeat: no-repeat;
	background-size: cover;
	display: none;
	height: 100%;
	position: absolute;
	top: 0;
	width: 50%;
}

.nm-renderinglayer .nm-renderinglayer-imagewrapper.nm-active {
	display: inline-block;
}

.nm-renderinglayer .nm-renderinglayer-image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 60%;
}

.nm-renderinglayer .nm-renderinglayer-open {
	bottom: 25px;
	display: none;
	left: 25px;
	outline: 5px solid rgba(255, 255, 255, .15);
	position: absolute;
	z-index: 1;
}

#nm-renderinglayer-exteriortoggle:checked ~ .openavailable .nm-renderinglayer-open,
#nm-renderinglayer-exterioropentoggle:checked ~ .openavailable .nm-renderinglayer-open {
	display: flex;
}

#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-stagewrapper .nm-renderinglayer-open {
	display: none;
}

.nm-renderinglayer .nm-renderinglayer-thumbnails {
	height: 0;
	margin: 20px;
	overflow: hidden;
	padding: 0;
	padding-bottom: 6.75%;
	position: relative;
}

.nm-renderinglayer .nm-renderinglayer-item {
	display: block;
	float: left;
	height: 100%;
	margin: 0 10px 0 0;
	overflow: hidden;
	transition: width .2s;
	width: calc(12.5% - 11.25px);
}

.nm-renderinglayer .nm-no-animation .nm-renderinglayer-item {
	transition: none;
}

.nm-renderinglayer .nm-rl-toggle-interior {
	margin: 0 10px 0 20px;
}

.nm-renderinglayer .nm-renderinglayer-item:last-child {
	margin: 0;
}

#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-interior,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-interior {
	margin: 0 0 0 20px;
}

.nm-renderinglayer .nm-rl-placeholder {
	cursor: pointer;
	display: block;
	height: 0;
	overflow: hidden;
	padding-bottom: 60%;
	position: relative;
	z-index: 1;
}

.nm-renderinglayer-toggle-radio {
	display: none;
}

/* all the cases when certain thumbnails must not be displayed */

/* in the exterior active case */
#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-exterior-open,
#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-exterior-open .nm-renderinglayer-thumb,
#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open,
#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-renderinglayer-thumb,
#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-interior,
#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-interior .nm-renderinglayer-thumb,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-exterior {
	border: 0;
	margin: 0;
	width: 0;
}

/* in the exterior_open active case */
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-exterior .nm-renderinglayer-thumb,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior .nm-renderinglayer-thumb,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-interior,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-interior .nm-renderinglayer-thumb,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-exterior {
	border: 0;
	margin: 0;
	width: 0;
}

/* in the interior active case */
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-exterior .nm-renderinglayer-thumb,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-exterior-open,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-item-exterior-open .nm-renderinglayer-thumb,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-renderinglayer-thumb,
.nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior,
.nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior .nm-renderinglayer-thumb {
	border: 0;
	margin: 0;
	width: 0;
}

/* cases where we have override again */
.nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open,
.nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-renderinglayer-thumb,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior .nm-renderinglayer-thumb {
	width: -webkit-calc(12.5% - 11.25px);
	width: calc(12.5% - 11.25px);
}

.nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior {
	margin: 0 10px 0 0;
}

/* cases where the plus button must not be displayed: */

/* the exterior active case */
#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior .nm-icon-plus,
#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-icon-plus,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior .nm-icon-plus {
	opacity: 0;
}

/* the exterior_open active case */
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-icon-plus,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-interior .nm-icon-plus {
	opacity: 0;
}

/* the interior active case */
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-icon-plus,
.nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior .nm-icon-plus,
#nm-renderinglayer-interiortoggle:not(:checked) ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior .nm-icon-plus,
#nm-renderinglayer-interiortoggle:not(:checked) ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-icon-plus {
	opacity: 0;
}

.nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-icon-plus {
	opacity: 1;
}

/* the thumbnail element */
.nm-renderinglayer .nm-renderinglayer-thumb {
	background-position: top left;
	background-repeat: no-repeat;
	background-size: auto 100%;
	border: 1px solid #434c53;
	box-sizing: border-box;
	display: block;
	height: -webkit-calc(100% - 2px);
	height: calc(100% - 2px);
	position: absolute;
	top: 0;
	transition: width .2s;
	width: calc(12.5% - 11.25px);
}

.nm-renderinglayer .nm-renderinglayer-thumb[data-renderimage-type="thumbnail.16-9"] {
	background-size: 100%;
}

.nm-renderinglayer .nm-renderinglayer-thumb.exterior_straight_front,
.nm-renderinglayer .nm-renderinglayer-thumb.exterior_straight_back,
.nm-renderinglayer .nm-renderinglayer-thumb.exterior_straight_front_open,
.nm-renderinglayer .nm-renderinglayer-thumb.exterior_straight_back_open,
.nm-renderinglayer .nm-renderinglayer-thumb.closeup_lights,
.nm-renderinglayer .nm-renderinglayer-thumb.closeup_wheels,
.nm-renderinglayer .nm-renderinglayer-thumb.closeup_lights_open,
.nm-renderinglayer .nm-renderinglayer-thumb.closeup_wheels_open {
	background-position: top center;
}

.nm-renderinglayer .nm-no-animation .nm-renderinglayer-thumb {
	transition: none;
}

#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-interior .nm-renderinglayer-thumb,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-interior .nm-renderinglayer-thumb,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-exterior .nm-renderinglayer-thumb,
.nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-exterior-open .nm-renderinglayer-thumb {
	border: 1px solid #707d82;
}

#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-interior:hover .nm-renderinglayer-thumb,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-interior:hover .nm-renderinglayer-thumb,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-exterior:hover .nm-renderinglayer-thumb,
.nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-exterior-open:hover .nm-renderinglayer-thumb {
	border: 1px solid #545f67;
}

.nm-renderinglayer .nm-renderinglayer-item .nm-renderinglayer-expander {
	background: #000;
	cursor: pointer;
	display: block;
	height: 100%;
	opacity: .4;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.nm-renderinglayer .nm-renderinglayer-item:hover .nm-renderinglayer-expander {
	opacity: .2;
}

#nm-renderinglayer-exteriortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior .nm-renderinglayer-expander,
#nm-renderinglayer-exterioropentoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-renderinglayer-expander,
#nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-interior .nm-renderinglayer-expander,
#nm-renderinglayer-interiortoggle:not(:checked) ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior .nm-renderinglayer-expander,
#nm-renderinglayer-interiortoggle:not(:checked) ~ .nm-renderinglayer-thumbnails .nm-rl-toggle-exterior-open .nm-renderinglayer-expander {
	opacity: 0;
}

.nm-renderinglayer .nm-icon-plus {
	background-color: #434c53;
	bottom: 0;
	cursor: pointer;
	display: block;
	height: 30px;
	opacity: 1;
	padding: 0;
	position: absolute;
	right: 0;
	transition: width .2s;
	width: 30px;
	z-index: 1;
}

.nm-renderinglayer .nm-icon-plus::before {
	content: "";
	height: 30px;
	width: 30px;
}

.nm-renderinglayer .nm-active + .nm-renderinglayer-thumb {
	border: 1px solid #fff;
}

.nm-renderinglayer .nm-renderinglayer-thumbnails .nm-renderinglayer-item a.nm-renderinglayer-expander,
.nm-renderinglayer .nm-renderinglayer-thumbnails .nm-renderinglayer-item a.nm-icon-plus {
	border: none;
}

.nm-state-is-responsive .nm-renderinglayer-wrapper::before {
	height: auto;
}

.nm-state-is-responsive .nm-layer-renderinglayer .nm-renderingcounter {
	display: none;
}

@media screen and (max-width: 1023px) {
	/* counter for small version */
	.nm-state-is-responsive .nm-layer-renderinglayer .nm-renderingcounter {
		display: inline-block;
		font-family: AudiTypeExtended, Verdana, sans-serif; /* TODO: This should reside in a typographic stylesheet */
		font-size: 16px;
		height: 30px;
		left: 10px;
		line-height: 30px;
		position: absolute;
		top: 10px;
	}

	.nm-state-is-responsive .nm-layer-renderinglayer .nm-renderingcounter-current,
	.nm-state-is-responsive .nm-layer-renderinglayer .nm-renderingcounter-total {
		display: inline-block;
	}

	.nm-state-is-responsive .nm-layer-renderinglayer .nm-renderingcounter-total {
		color: #d3d4d6;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-renderinglayer-thumbnails {
		margin: 10px;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-renderinglayer-open {
		bottom: 15px;
		left: 15px;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-backarrow {
		left: 10px;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-nextarrow {
		right: 10px;
	}

	.nm-state-is-responsive .nm-layer.nm-layer-renderinglayer .nm-icon-close {
		right: 10px;
		top: 10px;
	}

	.nm-state-is-responsive .nm-layer-renderinglayer .nm-renderinglayer-item .nm-icon-plus {
		display: none;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-renderinglayer-open {
		bottom: -54px;
	}

	.nm-state-is-responsive .nm-renderinglayer-item {
		display: none;
		float: right;
		margin-right: 0 !important;
	}

	.nm-state-is-responsive .nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-exterior,
	.nm-state-is-responsive #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-interior,
	.nm-state-is-responsive #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-item-interior {
		display: none;
	}

	.nm-state-is-responsive #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-exterior,
	.nm-state-is-responsive .nm-cabrio-open #nm-renderinglayer-interiortoggle:checked ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-exterior-open,
	.nm-state-is-responsive #nm-renderinglayer-interiortoggle:not(:checked) ~ .nm-renderinglayer-thumbnails .nm-renderinglayer-item.nm-rl-toggle-interior {
		display: block;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-renderinglayer-thumbnails,
	.nm-state-is-responsive .nm-renderinglayer .nm-rl-placeholder {
		padding-bottom: 50px;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-renderinglayer-item,
	.nm-state-is-responsive .nm-renderinglayer .nm-renderinglayer-thumb {
		height: 48px !important;
		width: 84px !important;
	}
}

@media screen and (max-width: 599px) {
	/* navigation in bottom bar */
	.nm-state-is-responsive .nm-renderinglayer .nm-backarrow {
		left: -webkit-calc(100% - 122px);
		left: calc(100% - 122px);
		top: -webkit-calc(100% + 10px);
		top: calc(100% + 10px);
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-nextarrow {
		left: -webkit-calc(100% - 60px);
		left: calc(100% - 60px);
		top: -webkit-calc(100% + 10px);
		top: calc(100% + 10px);
	}

	.nm-state-is-responsive .nm-renderinglayer-item {
		border: 5px solid rgba(255, 255, 255, .15) !important;
		margin-right: 124px !important;
	}

	.nm-state-is-responsive .nm-renderinglayer-item .nm-renderinglayer-thumb {
		top: 5px;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-renderinglayer-item,
	.nm-state-is-responsive .nm-renderinglayer .nm-renderinglayer-thumb {
		background-position: center center;
		height: 40px !important;
		width: 40px !important;
	}
}

@media screen and (max-aspect-ratio: 1/1) and (max-width: 1023px) {
	/* show layer toolbar in portrait mode */
	.nm-state-is-responsive .nm-layer-renderinglayer .nm-layer-toolbar {
		height: 60px;
	}

	.nm-state-is-responsive .nm-layer-renderinglayer.ave-layer .nm-layer-toolbar {
		height: 0;
	}
}

/*
AVE 3D rendering specialities
*/

#avecanvas {
	z-index: 4;
}

#avecanvas + .nm-renderinglayer-stagebrowse {
	display: none;
}

.nm-renderinglayer-preview {
	position: relative;
}

/*
 * color selector
 */

/* new config teaser */

.nm-renderinglayer .nm-render-color-selector-wrapper {
	bottom: 20px;
	display: none;
	left: 25px;
	position: absolute;
	z-index: 5;
}

.nm-renderinglayer .nm-render-color-selector {
	outline: 5px solid rgba(255, 255, 255, .15);
	padding: 5px 20px 5px 5px;
}

.nm-renderinglayer .nm-render-color-selector.select-closed {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTdCMjUxRkM3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjM4NkY0OUM3NzY1MTFFMkFDMThERTA5NEFENUM1QUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1N0IyNTFGQTc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1N0IyNTFGQjc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PiSjP2kAAABKSURBVHjaYrxqEPZfKNydAQgYGVDB/3crdzIwfb9wlwHEAAmgS4LkmEA60RTBJUFyLFAdIEX/3zGAFcElQQQLkrFgRTA2TBAgwADDZynL/bX51QAAAABJRU5ErkJggg==");
}

.nm-renderinglayer .nm-render-color-selector .selector-current-selected {
	height: 30px;
}

.nm-renderinglayer .nm-render-color-selector .selector-current-selected a {
	background-position: center center;
	background-size: cover;
	float: left;
	height: 30px;
	width: 30px;
}

.nm-renderinglayer .color-change-text {
	height: 30px;
	line-height: 30px;
	padding-left: 40px;
	padding-right: 10px;
}

.nm-renderinglayer .nm-render-color-selector-wrapper ul {
	background: #fff;
	border: 1px solid #d0d3d4;
	bottom: 40px;
	left: 0;
	max-height: 420px;
	overflow-y: scroll;
	position: absolute;
	width: 360px;
	z-index: 1;
}

.nm-renderinglayer .nm-render-color-selector-wrapper .nm-render-color-selector.nm-icon-expand-white + ul {
	display: none;
}

.nm-renderinglayer .nm-render-color-selector-wrapper li a,
.nm-renderinglayer .nm-render-color-selector-wrapper li span {
	display: block;
}

.nm-renderinglayer .nm-render-color-selector-wrapper li span {
	color: #000;
	padding: 12px 18px 5px 14px;
}

.nm-renderinglayer .nm-render-color-selector-wrapper li a {
	border-bottom: 1px solid #d0d3d4;
	padding: 0;
}

.nm-renderinglayer .nm-render-color-selector-wrapper .active-carline a {
	color: var(--color-progressive-red);
}

.nm-renderinglayer .nm-render-color-selector-wrapper li a:hover {
	background-color: #e7e9ea;
}

.nm-renderinglayer .nm-render-color-selector-wrapper .nm-icon-close {
	display: none;
}

/* tiles selector */
.nm-renderinglayer .nm-render-color-selector-wrapper li {
	clear: left;
}

.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value {
	background-size: cover;
	clear: none;
	display: inline-block;
	height: 40px;
	margin: 0 0 15px 15px;
	outline: 1px solid #d0d3d4;
	overflow: hidden;
	text-indent: -1000px;
	width: 60px;
}

.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_01111,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_01110,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_01101,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_01100,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_01011,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_01010,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_01001,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_01000,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_00111,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_00110,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_00101,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_00100,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_00011,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_00010,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_00001,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_00000,
.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value.nm-j-configurator-status_10000 {
	display: none;
}

.nm-renderinglayer .selector-value a {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 40px;
	padding: 0;
	width: 60px;
}

.nm-renderinglayer .nm-render-color-selector-wrapper .selector-value:hover,
.nm-renderinglayer .nm-render-color-selector-wrapper .active-color,
.nm-renderinglayer .nm-render-color-selector-wrapper .nm-j-configurator-status_11010 {
	border: 2px solid var(--color-progressive-red);
	margin-bottom: 9px;
	margin-right: -6px;
	padding: 1px;
	transform: translateX(-3px) translateY(-3px);
}

.nm-renderinglayer .nm-render-color-selector li.close-selector {
	display: none;
}

@media screen and (max-width: 799px) {
	.nm-state-is-responsive .nm-renderinglayer .nm-render-color-selector-wrapper {
		bottom: -55px;
		left: 15px;
	}
}

@media screen and (max-width: 599px) {
	.nm-state-is-responsive .nm-renderinglayer .nm-render-color-selector-wrapper .nm-icon-close {
		display: block;
		right: 0;
		top: 0;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-render-color-selector {
		padding: 5px;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-render-color-selector::after {
		background: none;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-render-color-selector-wrapper .color-change-text {
		display: none;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-render-color-selector-wrapper ul {
		height: 100%;
		left: 0;
		max-height: none;
		padding-top: 50px;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 11;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-ave-animation-selection {
		top: 0;
	}

	.nm-state-is-responsive .nm-renderinglayer .nm-ave-animation-selection .nm-icon-close {
		display: block;
		right: 0;
		top: 0;
	}
}

/*
 * animation selector
 */

.nm-renderinglayer .nm-ave-animation-selector-wrapper {
	bottom: 25px;
	left: 25px;
}

.nm-renderinglayer .nm-ave-animation-selector {
	outline: 5px solid rgba(255, 255, 255, .15);
}

@media screen and (max-width: 799px) {
	.nm-renderinglayer .nm-ave-animation-selector-wrapper {
		bottom: -55px;
		left: 15px;
	}
}
