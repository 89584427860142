/*
 * ave canvas
 */
.nm-md-ave-container {
	cursor: url("data:image/png;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAA//AAAd/wAAGf+AAAH9gAADbYAAA2yAAAZsAAAGbAAAAGAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//4AB//8AAf//AAD//5AA///gAP//4AD//8AF///AB///5A////5///8="), auto;
	height: 0;
	overflow: hidden;
	padding-bottom: 60.1%;
	position: absolute;
	width: 100%;
}

/*
 * animation selector
 */
.nm-ave-animation-selector-wrapper {
	bottom: 10px;
	left: 10px;
	position: absolute;
	z-index: 6;
}

.nm-ave-animation-selector {
	padding: 12px 30px 13px 40px;
}

.animation-selection {
	color: #fff;
	height: 24px;
	line-height: 24px;
	margin-left: 10px;
}

.nm-ave-animation-selection .nm-button-close {
	display: none;
}

.nm-ave-animation-selection {
	bottom: 38px;
	display: block;
	left: 1px;
	outline: 1px solid #d0d3d4;
	position: absolute;
}

.nm-ave-animation-selector.nm-icon-expand-white + .nm-ave-animation-selection {
	display: none;
}

.nm-ave-animation-selection .animation-option {
	background: #fff;
	border-top: 1px solid #d0d3d4;
	cursor: pointer;
	display: block;
	height: 16px;
	line-height: 16px;
	margin: 0;
	padding: 12px 16px;
	width: 150px;
}

.nm-ave-animation-selection .animation-option:nth-of-type(2) {
	border-top: 0;
}

@media screen and (max-width: 599px) {
	.nm-ave-animation-selector {
		padding: 12px 1px 13px 39px;
		z-index: 4;
	}

	.nm-ave-animation-selector-wrapper.active {
		z-index: 91;
	}

	.nm-ave-animation-selector::after {
		background: none;
	}

	.nm-ave-animation-selection .nm-button-close {
		display: block;
		right: 5px;
		top: 5px;
	}

	.nm-ave-animation-selector .options-text {
		visibility: hidden;
		width: 0;
	}

	.nm-ave-animation-selection {
		background: #fff;
		height: 100%;
		padding-top: 40px;
		position: fixed;
		top: 54px;
		width: 100%;
		z-index: 5;
	}

	.nm-ave-animation-selection .animation-option {
		border-bottom: 1px solid #d0d3d4;
		border-top: none;
		width: 100%;
	}

	.nm-ave-animation-selection .animation-option:nth-of-type(2) {
		border-top: 1px solid #d0d3d4;
	}
}

/* AVE2Cloud PoC */
.ave2cloud-container {
	background-color: #fff;
}

.ave2cloud-container::before {
	content: "Loading";
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}

.ave2cloud-container * {
	opacity: 0;
	transition: opacity 200ms ease-in-out;
}

.ave2cloud-container--ready * {
	opacity: 1;
}
