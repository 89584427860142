/*	=========================================================================
	Module Option List
	========================================================================= */

/* Package description
============================================================================= */

.nm-package-description dd,
.nm-package-description dt {
	display: inline;
	padding: 0 5px 0 0;
}

.nm-package-description .replaced {
	font-weight: bold;
}

/*	Option list - rows
============================================================================= */

.nm-options-list {
	background: #e7e9ea;
	border-collapse: collapse;
	clear: both;
	display: block;
	margin-bottom: 70px;
	position: relative;
	width: 100%;
}

.nm-md-options-list-white .nm-options-list {
	background-color: #fff;
	border-top: 1px solid #d0d3d4;
}

/* each list item is a row */
.nm-options-list > li {
	border-bottom: 2px solid #fff;
	min-height: 50px;
	padding: 0;
	position: relative;
	text-align: left;
}

.nm-md-options-list-white .nm-options-list > li {
	border-bottom: 1px solid #d0d3d4;
}

.nm-options-list-cell-cb {
	height: 40px;
	left: 10px;
	top: 10px;
	width: 40px;
}

.nm-options-list-cell-cb,
.nm-options-list .nm-info-wrap,
.nm-j-configurator-item > .nm-price,
.nm-recommendation-container .nm-options-list li > .nm-price {
	position: absolute;
}

.nm-j-configurator-item > .nm-price,
.nm-recommendation-container .nm-options-list li > .nm-price {
	right: 10px;
	top: 10px;
}

/*	Additional content (everything except checkbox and label)
============================================================================= */

.nm-options-cell-additional {
	box-sizing: border-box;
	margin: 0 185px 0 10px;
	position: relative;
}

.nm-options-list-cell-cb + .nm-options-cell-additional {
	margin-left: 50px;
	margin-right: 0;
	width: -webkit-calc(100% - 245px);
	width: calc(100% - 245px);
	word-wrap: break-word;
}

.nm-conflict-solution .nm-options-list-cell-cb + .nm-options-cell-additional {
	margin-left: 0;
	width: 100%;
}

.nm-conflict-solution .nm-options-list-cell-cb + .nm-options-cell-additional {
	width: -webkit-calc(100% - 120px);
	width: calc(100% - 120px);
}

.nm-conflict-solution.nm-conflict-solution-show .nm-options-list-cell-cb + .nm-options-cell-additional {
	margin-left: 40px;
	width: -webkit-calc(100% - 160px);
	width: calc(100% - 160px);
}

.nm-list-item-additional {
	display: block;
	position: absolute;
	right: 0;
	text-align: left;
	width: 142px;
}

.nm-md-options-list .nm-list-item-image,
.nm-md-options-container .nm-list-item-image,
.nm-md-summary-standard-equipment-list .nm-list-item-image,
.nm-options-cell-additional-142 .nm-list-item-image {
	height: 80px;
	overflow: hidden;
}

.nm-md-options-list .nm-list-item-image,
.nm-md-options-container .nm-list-item-image {
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
}

.nm-md-options-list .nm-list-item-image.nm-img-pos-middle,
.nm-md-options-container .nm-list-item-image.nm-img-pos-middle {
	background-position: left center;
}

.nm-md-options-list .nm-list-item-image.nm-img-pos-bottom,
.nm-md-options-container .nm-list-item-image.nm-img-pos-bottom {
	background-position: left bottom;
}

.nm-md-options-list .nm-list-item-image.nm-img-pos-full,
.nm-md-options-container .nm-list-item-image.nm-img-pos-full {
	background-color: #fff;
	background-position: center;
	background-size: contain;
}

.nm-list-item-additional.is-empty {
	width: 1px;
}

.nm-list-item-bicolor {
	border-top: 1px solid #fff;
	display: none;
	margin-top: 10px;
	padding: 14px 14px 0;
}

.nm-j-configurator-status_11010 .nm-list-item-bicolor {
	display: block;
	margin-bottom: 10px;
}

.nm-list-item-bicolor span {
	display: block;
	margin-bottom: 5px;
}

.nm-list-item-bicolor a {
	float: left;
	margin: 8px 0;
	max-width: none !important;
	padding: 0;
}

/*	Info and conflict icons
============================================================================= */

.nm-options-list .nm-info-wrap {
	right: 175px;
	text-align: left;
	top: 10px;
}

.nm-options-list .nm-info-wrap .nm-conflict-icon,
.nm-options-list .nm-info-wrap .nm-info {
	float: none;
	right: auto;
	top: 0;
}

/*	Image
============================================================================= */

.nm-list-item-image {
	position: relative;
	width: 100%;
}

.nm-list-item-image img {
	width: 100%;
}

.nm-list-item-text {
	display: block;
	overflow: hidden;
	padding: 10px 10px 10px 0;
	text-align: left;
	/* columnized view */
}

.nm-options-tiles .nm-list-item-text {
	float: none;
	overflow: visible;
	padding: 0;
}

.nm-list-item-video::after {
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpDQkI3OTZGMDExMjA2ODExODhDNjhGRkY5MUNDMzY5QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MDdENTRGQTMxMTYxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MDdENTRGOTMxMTYxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRBNUMxRDU4MkUyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNCQjc5NkYwMTEyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Eb1iGwAAALhJREFUeNrs2ssNgCAQhGEwVqH9l2YdSAke2JmR/HvmwBdgHwl9jHG1DeNomwYwYMCAAQP25zi/Luy9Pymbnt3SzVUEBgwYsCjYTM37npgDJ7uKapz0jSlx8uShwlmyogJnS/fVOGsdq8TZC3QVLqLzqMDFtFSrcTGwOaHvB1uNioBVoOywKpQVVomywapRFpgCJYepUFKYEiWDqVESmAMV1VIBAwYMWMZ8xycxYMCAAQMGLC9eAQYA3nsrm9Al1AgAAAAASUVORK5CYII=")
		0 0 no-repeat;
	content: "";
	height: 54px;
	left: calc(50% - 27px);
	position: absolute;
	top: calc(50% - 27px);
	width: 54px;
}

.nm-conflict-solution.nm-conflict-solution-show .nm-list-item-additional + .nm-list-item-text,
.nm-md-options-list .nm-list-item-additional + .nm-list-item-text,
.nm-md-options-container .nm-list-item-additional + .nm-list-item-text {
	margin-right: 142px;
	min-height: 60px;
}

.nm-conflict-solution.nm-conflict-solution-show .nm-list-item-additional.is-empty + .nm-list-item-text,
.nm-md-options-list .nm-list-item-additional.is-empty + .nm-list-item-text,
.nm-md-options-container .nm-list-item-additional.is-empty + .nm-list-item-text {
	margin-right: 0;
}

/*	Price
============================================================================= */

.nm-options-list .nm-price {
	text-align: right;
}

.nm-md-options-list .nm-price {
	text-align: right;
}

.nm-scope-no-prices .nm-options-list .nm-price,
.nm-scope-no-prices .nm-md-options-list .nm-price {
	visibility: hidden;
}

/*	Quantity Picker
============================================================================= */

.nm-options-list .nm-col-quantity-picker {
	float: left;
	min-width: 72px;
	position: relative;
	text-align: right;
}

.nm-j-configurator-status_11000 .nm-stz::after {
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-price-single {
	display: block;
}

.nm-price-total {
	display: none;
}

.nm-j-quantity-selected.nm-price-total {
	display: block;
}

/*	Checkbox and label
============================================================================= */

.nm-options-list .nm-checkbox-1,
.nm-options-list .nm-checkbox-3 {
	float: left;
	margin-right: 10px;
	vertical-align: top;
}

/*	Disclaimer, Remarks
============================================================================= */

.nm-options-remarks {
	margin: 18px 0 80px;
}

.nm-options-remarks span {
	display: block;
}

/* Expert mode (Headline and toggling)
============================================================================= */

.nm-options-list-head {
	background: #e7e9ea;
	margin-bottom: 2px;
}

.nm-options-list-head strong,
.nm-options-list-head a {
	margin: 10px;
}

.nm-options-list-head strong {
	float: left;
}

.nm-options-list-head a {
	float: right;
}

.nm-options-list-wrap {
	clear: none;
	margin-left: 160px;
}

.nm-2col .nm-md-options-list .nm-list-item-additional,
.nm-2col .nm-md-options-container .nm-list-item-additional,
.nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-additional {
	width: 124px;
}

.nm-2col .nm-md-options-list .nm-list-item-image,
.nm-2col .nm-md-options-container .nm-list-item-image,
.nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-image {
	height: 70px;
}

.nm-2col .nm-md-options-list .nm-list-item-additional:not(.is-empty) + .nm-list-item-text,
.nm-2col .nm-md-options-container .nm-list-item-additional:not(.is-empty) + .nm-list-item-text,
.nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-additional:not(.is-empty) + .nm-list-item-text {
	margin-right: 124px;
	min-height: 50px;
}

/* TODO: Check, whether this style should go to module.tables.css */

.nm-options-list dl {
	display: block;
	overflow: hidden;
}

.nm-quantity-count {
	display: none;
}

.j-configurator-count-not-default .nm-quantity-count {
	display: inline;
}

.nm-non-renderable {
	clear: both;
	display: inline-block;
}

/*  ------------- module specific styles -------------  */

/* options-list */
.nm-md-options-list h2 {
	margin-bottom: 10px;
}

/* options-container */
.nm-md-options-container h2 {
	margin-bottom: 10px;
}

/* trimline-list.vm */
.nm-module-trimline-list div.nm-el-pg.nm-el-pg-04 {
	margin-bottom: 21px;
}

.nm-module-trimline-list div.nm-el-pg.nm-el-pg-04:last-child {
	margin-bottom: 0;
}

/*	summary - next steps - navigation */
.nm-list-nextsteps li {
	margin-bottom: 30px;
}

/* conflict layer */
.nm-layer-conflict .nm-options-list.nm-options-tiles > li {
	display: block;
	padding: 10px;
}

/* show alternative button */
.nm-j-options-list-alternative {
	background-color: #e7e9ea;
	color: #000;
	display: block;
	margin-top: -70px;
	padding: 0 12px;
	text-decoration: none;
}

.nm-j-options-list-alternative:hover {
	background-color: #d0d3d4;
}

.nm-j-options-list-alternative span {
	background: center right no-repeat transparent
		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTdCMjUxRjg3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTdCMjUxRjk3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1N0IyNTFGNjc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1N0IyNTFGNzc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgslqbkAAABNSURBVHjaYvr//z8DDJ9hMP4PwshiTAxQcJbR5D+ngTIDCIPYMHEWZEmhcHew4DuGnWAxoHmMLMiSEhVJjFCN/2GKWLBIwthgRQABBgB5LTGo9okstAAAAABJRU5ErkJggg==");
	padding-right: 15px;
}

.nm-options-list.nm-options-list-collapsed + .nm-j-options-list-alternative span {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTdCMjUxRkM3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjM4NkY0OUM3NzY1MTFFMkFDMThERTA5NEFENUM1QUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1N0IyNTFGQTc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1N0IyNTFGQjc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PiSjP2kAAABKSURBVHjaYrxqEPZfKNydAQgYGVDB/3crdzIwfb9wlwHEAAmgS4LkmEA60RTBJUFyLFAdIEX/3zGAFcElQQQLkrFgRTA2TBAgwADDZynL/bX51QAAAABJRU5ErkJggg==");
}

.nm-options-list-alternative-text-show {
	display: none;
}

.nm-options-list.nm-options-list-collapsed + .nm-j-options-list-alternative .nm-options-list-alternative-text-hide {
	display: none;
}

.nm-options-list.nm-options-list-collapsed + .nm-j-options-list-alternative .nm-options-list-alternative-text-show {
	display: inline;
}

.nm-options-list.nm-options-list-collapsed .nm-j-configurator-item {
	display: none;
}

.nm-options-list.nm-options-list-collapsed .nm-j-configurator-item.nm-j-configurator-status_11010,
.nm-options-list.nm-options-list-collapsed .nm-j-configurator-item.nm-j-configurator-status_10010,
.nm-options-list.nm-options-list-collapsed .nm-j-configurator-item.nm-j-configurator-status_10011 {
	display: block;
}

@media screen and (max-width: 1177.6px) {
	.nm-state-is-responsive .nm-2col .nm-list-item-image img {
		height: auto;
		max-width: 124px;
		min-width: 106px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-2col .nm-list-item-additional {
		width: 124px;
	}

	.nm-state-is-responsive .nm-2col .nm-list-item-additional:not(.is-empty) + .nm-list-item-text {
		margin-right: 124px;
		min-height: 50px;
	}
}

@media screen and (max-width: 999px) {
	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-list-item-additional,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-list-item-additional,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-additional {
		width: 142px;
	}

	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-list-item-image,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-list-item-image,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-image {
		height: 80px;
	}

	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-list-item-additional:not(.is-empty) + .nm-list-item-text,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-list-item-additional:not(.is-empty) + .nm-list-item-text,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-additional:not(.is-empty) + .nm-list-item-text {
		margin-right: 142px;
		min-height: 60px;
	}

	.nm-state-is-responsive .nm-2col .nm-list-item-image img {
		height: auto;
		max-width: 142px;
		width: 142px;
	}
}

@media screen and (max-width: 749px) {
	.nm-state-is-responsive .nm-list-item-image img,
	.nm-state-is-responsive .nm-2col .nm-list-item-image img {
		max-width: 124px;
		min-width: 106px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-list-item-additional,
	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-list-item-additional,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-list-item-additional,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-additional {
		width: 124px;
	}

	.nm-state-is-responsive .nm-md-options-list .nm-list-item-image,
	.nm-state-is-responsive .nm-md-options-container .nm-list-item-image,
	.nm-state-is-responsive .nm-md-summary-standard-equipment-list .nm-list-item-image,
	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-list-item-image,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-list-item-image,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-image {
		height: 70px;
	}

	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-list-item-additional:not(.is-empty) + .nm-list-item-text,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-list-item-additional:not(.is-empty) + .nm-list-item-text,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-additional:not(.is-empty) + .nm-list-item-text,
	.nm-state-is-responsive .nm-list-item-additional + .nm-list-item-text {
		margin-right: 124px;
		min-height: 50px;
	}

	.nm-state-is-responsive .nm-conflict-solution:not(.nm-conflict-solution-show) .nm-list-item-additional + .nm-list-item-text {
		margin-right: 0;
	}

	.nm-state-is-responsive .nm-conflict-solution .nm-list-item-additional {
		display: none;
	}

	.nm-state-is-responsive .nm-conflict-solution.nm-conflict-solution-show .nm-list-item-additional {
		display: table-footer-group;
	}
}

@media screen and (max-width: 599px) {
	.nm-state-is-responsive .nm-md-options-list .nm-list-item-image,
	.nm-state-is-responsive .nm-md-options-container .nm-list-item-image,
	.nm-state-is-responsive .nm-md-summary-standard-equipment-list .nm-list-item-image,
	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-list-item-image,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-list-item-image,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-equipment-list .nm-list-item-image {
		height: 60px;
		margin-top: 10px;
		width: 106px;
	}

	.nm-state-is-responsive .nm-2col .nm-options-cell-additional {
		word-wrap: break-word;
	}

	.nm-state-is-responsive .nm-2col .nm-list-item-additional,
	.nm-state-is-responsive .nm-2col .nm-list-item-text {
		float: none;
		padding-bottom: 0;
		position: relative;
		width: 100%;
		word-wrap: break-word;
	}

	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-j-configurator-item > .nm-price,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-j-configurator-item > .nm-price,
	.nm-state-is-responsive .nm-2col .nm-recommendation-container .nm-options-list li > .nm-price {
		bottom: 10px;
		right: 10px;
	}

	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-price .nm-price,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-price .nm-price {
		bottom: 0;
		position: absolute;
		right: 0;
		white-space: nowrap;
	}

	.nm-state-is-responsive .nm-2col .nm-recommendation-container .nm-options-list li > .nm-price {
		top: auto;
	}

	.nm-state-is-responsive .nm-2col .nm-recommendation-container .nm-options-list li {
		padding: 10px 0;
	}

	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-options-cell-additional,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-options-cell-additional {
		margin: 0 70px 0 50px;
		min-height: 70px;
		padding: 10px 0 25px;
	}

	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-equipment-list.nm-md-options-list .nm-options-cell-additional,
	.nm-state-is-responsive .nm-2col .nm-md-summary-standard-equipment-list.nm-md-options-container .nm-options-cell-additional {
		margin-left: 10px;
		min-height: 30px;
		padding-bottom: 10px;
	}

	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-list-item-additional,
	.nm-state-is-responsive .nm-2col .nm-md-options-container .nm-list-item-additional {
		display: table-footer-group;
		position: relative;
	}

	.nm-state-is-responsive .nm-2col .nm-list-item-text {
		display: table-header-group;
	}

	.nm-state-is-responsive .nm-2col .nm-module-trimline-list .nm-list-item-text {
		display: block;
	}

	.nm-state-is-responsive .nm-2col .nm-list-item-additional + .nm-list-item-text {
		margin-right: 0;
		min-height: 1px;
	}

	.nm-state-is-responsive .nm-2col .nm-md-options-list .nm-list-item-image {
		position: relative;
	}

	.nm-state-is-responsive .nm-2col .nm-list-item-image {
		max-width: 124px;
	}

	.nm-state-is-responsive .nm-2col .nm-list-item-image img {
		margin-top: 10px;
		max-width: 124px;
		min-width: 106px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-2col .nm-options-list .nm-info-wrap {
		right: 10px;
		text-align: right;
		width: 50px;
	}

	.nm-state-is-responsive .nm-2col .nm-options-list .nm-info-wrap .nm-conflict-icon,
	.nm-state-is-responsive .nm-2col .nm-options-list .nm-info-wrap .nm-info {
		float: none;
		left: auto;
		position: absolute;
		right: 0;
		top: 0;
	}

	.nm-state-is-responsive .nm-2col .nm-options-list .nm-j-configurator-status_11100 .nm-info-wrap .nm-info {
		right: 30px;
	}
}

@media screen and (max-width: 479px) {
	.nm-state-is-responsive .nm-options-list-cell-cb + .nm-options-cell-additional {
		width: 100%;
	}

	.nm-state-is-responsive .nm-layer-conflict .nm-options-list .nm-price {
		position: relative;
		right: 0;
		top: 0;
	}

	.nm-state-is-responsive .nm-layer-conflict .nm-conflict-trigger .nm-j-configurator-item .nm-options-cell-additional,
	.nm-state-is-responsive .nm-layer-conflict .nm-conflict-solution .nm-options-cell-additional,
	.nm-state-is-responsive .nm-conflict-solution .nm-options-list-cell-cb + .nm-options-cell-additional {
		margin: 0;
		width: 100%;
	}

	.nm-state-is-responsive .nm-conflict-solution.nm-conflict-solution-show .nm-options-list-cell-cb + .nm-options-cell-additional {
		margin-left: 40px;
		width: -webkit-calc(100% - 40px);
		width: calc(100% - 40px);
	}

	/* TODO: check vendor prefixes */
	.nm-state-is-responsive .nm-layer-conflict .nm-button-list,
	.nm-state-is-responsive .nm-layer-transfer .nm-button-list,
	.nm-state-is-responsive .nm-layer .nm-button-row,
	.nm-state-is-responsive .nm-layer #fdproxy .nm-button-row {
		align-items: flex-start;
		display: flex;
		flex-flow: column-reverse wrap-reverse;
		float: none;
	}

	.nm-state-is-responsive .nm-layer-conflict .nm-button-list li,
	.nm-state-is-responsive .nm-layer-transfer .nm-button-list li,
	.nm-state-is-responsive .nm-layer .nm-button-row button,
	.nm-state-is-responsive .nm-layer #fdproxy .nm-button-row button {
		float: none;
		margin-bottom: 10px;
	}

	.nm-state-is-responsive .nm-layer-conflict .nm-button-list li a,
	.nm-state-is-responsive .nm-layer-transfer .nm-button-list li a,
	.nm-state-is-responsive .nm-layer .nm-button-list button,
	.nm-state-is-responsive .nm-layer #fdproxy .nm-button-list button {
		float: right;
	}
}

@media screen and (max-width: 599px) {
	.nm-state-is-responsive .nm-options-cell-additional {
		padding: 10px 0 0;
		word-wrap: break-word;
	}

	.nm-state-is-responsive .nm-list-item-additional,
	.nm-state-is-responsive .nm-list-item-text {
		display: table-footer-group;
		float: none;
		padding-bottom: 0;
		position: relative;
		width: 100%;
		word-wrap: break-word;
	}

	.nm-state-is-responsive .nm-md-options-list .nm-j-configurator-item > .nm-price,
	.nm-state-is-responsive .nm-md-options-container .nm-j-configurator-item > .nm-price {
		bottom: 10px;
		right: 10px;
	}

	.nm-state-is-responsive .nm-md-options-list .nm-price .nm-price,
	.nm-state-is-responsive .nm-md-options-container .nm-price .nm-price {
		bottom: 0;
		position: absolute;
		right: 0;
		white-space: nowrap;
	}

	.nm-state-is-responsive .nm-md-options-list .nm-options-cell-additional,
	.nm-state-is-responsive .nm-md-options-container .nm-options-cell-additional {
		margin: 0 175px 0 50px;
		min-height: 70px;
		padding: 10px 0 25px;
	}

	.nm-state-is-responsive .nm-md-options-list .nm-list-item-additional,
	.nm-state-is-responsive .nm-md-options-container .nm-list-item-additional {
		display: table-footer-group;
		position: relative;
	}

	.nm-state-is-responsive .nm-list-item-text {
		display: table-header-group;
		word-wrap: break-word;
	}

	.nm-state-is-responsive .nm-module-trimline-list .nm-list-item-text {
		display: block;
	}

	.nm-state-is-responsive .nm-list-item-additional + .nm-list-item-text {
		margin-right: 0;
		min-height: 1px;
	}

	.nm-state-is-responsive .nm-list-item-image img {
		margin-top: 10px;
		max-width: 106px;
		min-width: 106px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-summary-list .nm-price .nm-info-wrap,
	.nm-state-is-responsive .nm-summary-list .nm-price .nm-price {
		display: block;
		position: relative;
		text-align: right;
	}

	.nm-state-is-responsive .nm-summary-list .nm-price .nm-info-wrap {
		min-height: 50px;
	}

	.nm-state-is-responsive .nm-summary-list .nm-price .nm-price {
		bottom: 10px;
		position: absolute;
		right: 10px;
	}

	.nm-state-is-responsive .nm-summary-list .nm-info-wrap .nm-info {
		display: inline-block;
		text-align: right;
	}

	.nm-state-is-responsive .nm-options-list .nm-info-wrap .nm-conflict-icon,
	.nm-state-is-responsive .nm-options-list .nm-info-wrap .nm-info {
		float: none;
		left: auto;
		position: absolute;
		right: 0;
		top: 0;
	}

	.nm-state-is-responsive .nm-options-list .nm-info-wrap {
		right: 10px;
		text-align: right;
		width: 50px;
	}

	.nm-state-is-responsive .nm-list-item-additional + .nm-list-item-text {
		margin-right: 0;
		min-height: 1px;
	}

	.nm-state-is-responsive .nm-md-options-list .nm-options-cell-additional,
	.nm-state-is-responsive .nm-md-options-container .nm-options-cell-additional {
		margin: 0 70px 0 50px;
		min-height: 70px;
		padding: 10px 0 25px;
		width: auto;
	}

	.nm-state-is-responsive .nm-md-options-list .nm-options-cell-additional-summary,
	.nm-state-is-responsive .nm-md-options-container .nm-options-cell-additional-summary {
		margin: 0 70px 0 10px;
	}
}
