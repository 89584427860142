/*	module - summary-engine
=========================================================================== */
.nm-module.nm-module-summary-engine {
	margin-bottom: 0;
}

.nm-module-summary-engine .nm-module-summary-engine__headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xs);
}

.nm-module-summary-engine .nm-module-summary-engine__subheadline {
	margin-bottom: var(--space-xxl);
}

.nm-module-summary-engine .nm-summary-header {
	height: auto;
	margin-bottom: 1px;
	width: 100%;
}

.nm-module-summary-engine .nm-summary-header .renderings {
	float: left;
	width: 59%;
}

.nm-module-summary-engine .nm-summary-header .renderings .exterior,
.nm-module-summary-engine .nm-summary-header .renderings .interior {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 0;
	padding-bottom: 36%;
}

.nm-module-summary-engine .nm-summary-header .motor-background,
.nm-module-summary-engine .nm-summary-header .motor {
	background-size: cover;
	height: 0;
}

.nm-module-summary-engine .nm-summary-header .motor-background {
	background-image: url("../assets/img/hohlkehle_400_225px.jpg");
	float: left;
	padding-bottom: 42.5%;
	width: 41%;
}

.nm-module-summary-engine .nm-summary-header .motor {
	background-position: 45% center;
	padding-bottom: 103.6%;
	width: 100%;
}

.nm-module-summary-engine .layerlinks {
	display: block;
	margin-bottom: 42px;
}

.nm-module-summary-engine .nm-summary-tech-icons {
	display: block;
	position: relative;
	width: 100%;
}

.nm-module-summary-engine .nm-summary-tech-icons .nm-tech-icons-wrap {
	display: flex;
	flex-flow: row wrap;
	margin-bottom: calc(var(--space-xxxl) - var(--space-xl));
	width: 100%;
}

.nm-module-summary-engine .nm-module-summary-engine__tech-icon-data {
	display: block;
	font-weight: var(--font-weight-bold);
	margin-top: var(--space-xs);
}

.nm-module-summary-engine .nm-icon-tech {
	box-sizing: border-box;
	display: flex;
	flex-flow: column wrap;
	margin: 0 4% var(--space-xl) 0;
	padding: 0;
	text-align: center;
	width: 48%;
	word-wrap: break-word;
}

.nm-module-summary-engine .nm-icon-tech::before {
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: block;
	height: 48px;
	margin: 0 auto var(--space-xs);
	width: 100%;
}

.nm-scope-no-prices .nm-engine-summary-price {
	display: none;
}

.nm-module-summary-engine .nm-techdata {
	margin-bottom: var(--space-xxl);
}

.nm-module-summary-engine .nm-techdata .nm-techrow {
	background-color: transparent;
	flex-flow: row wrap;
	hyphens: auto;
	word-wrap: break-word;
}

.nm-module-summary-engine .nm-techdata .nm-tech-key {
	font-weight: var(--font-weight-bold);
	hyphens: auto;
	word-wrap: break-word;
}

.nm-module-summary-engine .nm-techdata .nm-tech-key,
.nm-module-summary-engine .nm-techdata .nm-techdesc {
	padding: 12px 0 24px;
}

.nm-module-summary-engine .nm-techdata .nm-techdesc {
	border-left: none;
}

.nm-module-summary-engine .nm-techdata .nm-tech-key,
.nm-module-summary-engine .nm-techdata .nm-techdesc {
	width: 100%;
}

.nm-module-summary-engine .nm-techrow:first-child .nm-tech-key {
	border-top: 3px solid #000;
}

.nm-module-summary-engine .nm-techrow:not(:last-child),
.nm-module-summary-engine .nm-techrow:last-child > dd {
	border-bottom: 1px solid #000;
}

.nm-module-summary-engine .layerlinks > .nm-layerLink {
	display: inline-flex;
}

.nm-module-summary-engine .layerlinks > .nm-layerLink + .nm-layerLink {
	margin-top: 6px;
}

.nm-module-summary-engine .nm-module-summary-engine-link-eec {
	margin-left: 20px;
}

.nm-module-summary-engine .nm-module-summary-engine-link-eec {
	margin-left: 0;
}

.nm-techdesc-custom-eec-label-icon {
	display: inline-block;
	height: 100%;
	max-height: 20px;
	max-width: 52px;
	vertical-align: text-top;
}

.nm-module-summary-engine .layerlinks {
	text-align: right;
}

.nm-module-summary-engine .nm-techdesc-link {
	align-items: center;
	cursor: pointer;
	display: inline-flex;
}

.nm-module-summary-engine .nm-techdesc-link:hover {
	color: var(--color-grey-70);
}

.nm-module-summary-engine .nm-icon-arrow {
	height: 24px;
	margin-left: var(--space-xs);
	width: 24px;
}

.nm-icon-ElectricRange::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAmhJREFUaAXtWUlOxDAQHBbxBeAdIBASXDjBgUfwEITEE/gH/AKOIAR8AcRy5sBelYkjJ3G37SwzieSWWk56KfdizzjJZJIoVaC3CuwD+Qn8Cf5ryPR9BB+BZ0qckEHvgJdazEzfXTCxWJBQOhUMJXnJfAV3D+C9krTdDQvBboYSE3aRJC/ZsmpsfZvKlwBzLGKGkhSoJK/hBhvWPGVBDKZkW5MvyvONQ7M8oDB/EcuCFU+t2rnOyDkuDikBBm8nYOXivMwSGf0SSgk4mztDYepAh8X+AVboJqYd7SdD6sAb4lljUAG0Cpt32g0pgWvEc8CgAugQNleanfmz0GxidT7MDQC+gH1dYPWJRXuRfJOJjooiBPMM/vdgKQnKqT8Hq2RPxus2bCYyGDyV3oJPwNUTLzcng6PtMXgdzGXOkffsEJOknUoE6JoMJoPeAt+BX8GuYLg8LsDP4O98vMSoLhvoCzKTFYIOLlyYlLETGrn8NPtM18jJg+rCZCe4nDRy+Wn2ma6RkwfVhRny9Ofy80w13UReo0gDKRBJbuBV/ZD+yEzAUWNKIKpcPRinDvRQ1CjI1IGocvVgnDrQQ1GjIOfdAR4lvsDVY7VJwujNfW2cdwJ8MOer/M1aZFMBj8/8QBJN6vkjGm3qIGHyOM1nAxfRx3fcdvllT0RORQ9CLhO+YWCw22B+YOHIpPimwvXAA7FOUrU0ryY+Bo9BstI34I985L20N7xzeQ0AXqUmPlWM0Ptirnlv4tCARbvRJ9D1B46itWLJOlZUE7ADsK+1ae1fCfta82mrK2KrJjCrANomUPiPfg+MPoF/5B+Fux3whygAAAAASUVORK5CYII=");
}

@media all and (max-width: 767px) {
	.nm-module-summary-engine .nm-icon-tech:nth-child(2n) {
		margin-right: 0;
	}
}

@media all and (min-width: 768px) and (max-width: 1023px) {
	.nm-module-summary-engine .nm-icon-tech:nth-child(3n) {
		margin-right: 0;
	}
}

@media all and (min-width: 768px) {
	.nm-module-summary-engine .nm-tech-key {
		border-top: 3px solid #000;
	}

	.nm-module-summary-engine .nm-techdesc {
		border-top: 1px solid #000;
	}

	.nm-module-summary-engine .nm-techrow:not(:last-child) {
		border-bottom: none;
	}

	.nm-module-summary-engine .nm-icon-tech:last-child {
		margin-bottom: 48px;
	}

	.nm-module-summary-engine .nm-techrow {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
	}

	.nm-module-summary-engine .nm-techdata .nm-tech-key {
		border-top: 3px solid #000;
		width: calc(30% - 3px);
	}

	.nm-module-summary-engine .nm-techdata .nm-techdesc {
		border-top: 1px solid #000;
		width: 70%;
	}

	.nm-module-summary-engine .nm-icon-tech {
		width: calc(92% / 3);
	}

	.nm-module-summary-engine .layerlinks {
		margin-bottom: 48px;
	}

	.nm-module-summary-engine .layerlinks > .nm-layerLink {
		display: inline-flex;
	}

	.nm-module-summary-engine .layerlinks > .nm-layerLink + .nm-layerLink {
		margin-top: 0;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-summary-engine .nm-icon-tech:last-child,
	.nm-module-summary-engine .layerlinks {
		margin-bottom: 60px;
	}

	.nm-module-summary-engine .nm-icon-tech {
		width: 22%;
	}

	.nm-module-summary-engine .nm-icon-tech:nth-child(4n) {
		margin-right: 0;
	}
}

@media all and (min-width: 1100px) {
	.nm-2col .nm-module-summary-engine .nm-icon-tech {
		width: calc(92% / 3);
	}

	.nm-2col .nm-module-summary-engine .nm-icon-tech:nth-child(4n) {
		margin-right: 4%;
	}

	.nm-2col .nm-module-summary-engine .nm-icon-tech:nth-child(3n) {
		margin-right: 0;
	}
}

@media all and (min-width: 1440px) {
	.nm-module-summary-engine .nm-summary-tech-icons {
		margin-top: 54px;
	}

	.nm-module-summary-engine .nm-techdata {
		margin-bottom: 36px;
	}

	.nm-module-summary-engine .nm-icon-tech {
		margin-bottom: 36px;
	}

	.nm-module-summary-engine .nm-icon-tech:last-child,
	.nm-module-summary-engine .layerlinks {
		margin-bottom: 78px;
	}
}

@media all and (min-width: 1920px) {
	.nm-module-summary-engine .nm-summary-tech-icons {
		margin-top: 66px;
	}

	.nm-module-summary-engine .nm-icon-tech:last-child,
	.nm-module-summary-engine .layerlinks {
		margin-bottom: 96px;
	}

	.nm-module-summary-engine .nm-icon-tech,
	.nm-module-summary-engine .nm-techdata {
		margin-bottom: 42px;
	}
}
