.nm-basket-superwrap {
	height: 0;
	position: relative;
	width: 100%;
	z-index: 8;
}

.nm-nav-fixed .nm-basket-superwrap {
	position: fixed;
	top: 54px; /* height of navigation */
}

.nm-nav-fixed.nm-has-current-consumption .nm-basket-superwrap {
	position: fixed;
	top: calc(54px + 43px);
}

.nm-basket-container {
	max-width: 100%;
	top: 0;
	width: 100%;
}

.nm-basket {
	background: #fff;
	box-sizing: border-box;
	position: relative;
	transform: translate3d(0, 0, 0);
	transition: margin-top .4s;
}

.nm-basket-bold {
	font-weight: var(--font-weight-bold);
}

.nm-basket-toggle-widgets {
	display: none;
}

.nm-basket-content {
	box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
	overflow: hidden;
	position: relative;
	transition: max-height .4s;
	transition-delay: 0s;
}

.nm-basket-toggle-widgets-label-fullarea {
	cursor: pointer;
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 100;
}

.nm-basket-content-wrap {
	box-sizing: border-box;
	display: flex;
	flex-wrap: nowrap;
	max-height: 0;
	transition: max-height .4s;
}

.nm-basket-toggle:checked ~ .nm-basket-superwrap .nm-basket.nm-basket-widget-is-open .nm-basket-content {
	transition-delay: .4s;
}

.nm-basket-toggle:checked ~ .nm-basket-superwrap .nm-basket-content-wrap {
	max-height: 285px;
}

.nm-basket-icon-link {
	align-items: center;
	display: flex;
}

.nm-basket-icon {
	align-self: flex-start;
	background: none; /* hide legacy background-images */
	flex-shrink: 0;
	height: 24px;
	margin-right: var(--space-s);
	width: 24px;
}

.nm-basket-icon-linktext {
	hyphens: auto;
}

.nm-basket-linklist-linkitem:first-of-type {
	margin-top: 0;
}

.nm-basket-linklist-linkitem {
	margin-top: var(--space-m);
}

.nm-basket-image {
	height: auto;
	width: 100%;
}

.nm-basket-price-row {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--space-s);
	position: relative;
	width: 100%;
}

.nm-scope-no-prices .nm-basket-price-row {
	display: none;
}

.nm-car-price .nm-basket-price-row:last-child,
.nm-car-price-total .nm-basket-price-row:last-child {
	margin-bottom: 0;
}

.nm-car-rotrprice-label {
	font-weight: var(--font-weight-bold);
}

.nm-basket-price-text {
	white-space: nowrap;
}

.nm-basket-image-wrap-white-bg {
	background-color: #fff;
}

.nm-basket-zoom {
	background-color: #333;
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyMnB4IiBoZWlnaHQ9IjE1cHgiIHZpZXdCb3g9Ii03LjUgMTQgMjIgMTUiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgLTcuNSAxNCAyMiAxNSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHRpdGxlPmNhbWVyYS1zbWFsbDwvdGl0bGU+PGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+PGcgaWQ9IlBhZ2UtMSI+PGcgaWQ9ImNhbWVyYS1zbWFsbCI+PHBhdGggaWQ9IlN0cm9rZS0xIiBmaWxsPSJub25lIiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMC45OTgiIGQ9Ik05LDE2LjVoNXYxMS4wMDRjMCwwLjU1MS0wLjQ0OCwwLjk5Ni0xLDAuOTk2SC03di0xMWMwLTAuNTUsMC40NDgtMSwxLTFoNHYtMS4wMDRjMC0wLjU1MSwwLjQ0OC0wLjk5NiwxLTAuOTk2aDljMC41NTEsMCwwLjk5OCwwLjQ0MywxLDAuOTkzVjE2LjVMOSwxNi41eiIvPjxwYXRoIGlkPSJTdHJva2UtMiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjAuOTk3IiBkPSJNOCwyMmMwLDIuNDg0LTIuMDE2LDQuNS00LjUsNC41QzEuMDE1LDI2LjUtMSwyNC40ODQtMSwyMnMyLjAxNS00LjUsNC41LTQuNUM1Ljk4NCwxNy41LDgsMTkuNTE2LDgsMjJMOCwyMnoiLz48L2c+PC9nPjwvc3ZnPg==");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	bottom: 0;
	cursor: pointer;
	height: 30px;
	padding-bottom: 0;
	position: absolute;
	right: 0;
	width: 30px;
	z-index: 2;
}

.nm-basket-image-wrap {
	background-color: #f2f2f2;
	cursor: pointer;
	height: 46px;
	overflow: hidden;
	position: relative;
	width: 114px;
}

.nm-basket-image-wrap.nm-basket-image-white-bg {
	background-color: #fff;
}

.nm-basket-image-link {
	margin: var(--space-m) var(--space-s);
	order: -1;
}

.nm-basket .nm-car {
	line-height: 0;
	padding: 18px 0;
}

.nm-basket .nm-car sup {
	cursor: pointer;
}

.nm-basket .nm-basket-toggle-widgets-label .nm-icon-arrow {
	display: none;
}

.nm-basket .nm-car-model .nm-icon-arrow {
	height: 24px;
	margin-left: var(--space-xs);
	vertical-align: middle;
	width: 24px;
}

.nm-basket .nm-car-engine {
	display: none;
}

.nm-basket .nm-car .nm-car-price-amount {
	margin-top: var(--space-s);
}

.nm-basket .nm-car .nm-car-price-total {
	white-space: nowrap;
}

.nm-basket .nm-car .nm-car-price-label-footnote-sup {
	margin-right: var(--space-m);
}

.nm-basket .nm-car .nm-car-price-additional-disclaimer {
	margin-top: var(--space-s);
}

.nm-basket-main-info,
.nm-basket-lists {
	display: none;
}

.nm-basket-superwrap .nm-basket:not(.nm-basket-widget-is-open) .nm-basket-content {
	transition-delay: 0s;
}

body.nm-no-rate .nm-basket .nm-car-price-rate,
body.nm-no-rate .nm-basket .nm-car-price-rate-amount {
	display: none;
}

.nm-scope-no-prices .nm-basket-buttons-wrapper {
	margin-top: 0;
}

.nm-agent-is-wallet:not(.nm-is-summary) .nm-basket .nm-basket-wallet-btn.nm-showonly-wallet {
	display: none;
}

.nm-basket .nm-basket-wallet-icon {
	margin-top: var(--space-m);
}

.nm-supportbar-toggle:checked ~ .nm-supportbar-navigation {
	padding: 30px 0 20px;
}

.nm-basket .nm-infobox {
	cursor: pointer;
	display: block;
}

.nm-basket .nm-infobox-container {
	background-color: #000;
	box-sizing: border-box;
	color: #fff;
	display: none;
	font-size: 10px;
	left: -358px;
	padding: 18px 18px 24px;
	position: absolute;
	top: 9px;
	transform: translateY(-50%);
	width: 330px;
	z-index: 1;
}

.nm-basket .nm-infobox-container .audi-footnote-anchor__text {
	color: var(--color-white);
}

.nm-basket .nm-infobox-container.nm-infobox-show {
	display: block;
}

.nm-infobox-container .nm-configurator-change-rate {
	float: right;
	margin-left: var(--space-s);
}

.nm-infobox-container .nm-mode-switch-form {
	border-top: 1px solid #333;
	margin-top: var(--space-m);
	padding-top: 18px;
	width: 100%;
}

.nm-car-price-rate .nm-infobox-container .audi-checkbox {
	color: var(--color-white);
}

.nm-basket .nm-infobox-container::after {
	border-color: transparent transparent transparent #000;
	border-style: solid;
	border-width: 6px 0 6px 6px;
	content: "";
	height: 0;
	position: absolute;
	right: -6px;
	top: calc(50% - 6px);
	width: 0;
}

.nm-basket .nm-companycar-label {
	position: relative;
}

.nm-basket .nm-companycar-label .nm-infobox-container {
	padding: 18px;
	top: -28px;
}

.nm-basket .nm-companycar-label .nm-car-price-options {
	clear: both;
	float: left;
}

.nm-basket .nm-companycar-label .nm-car-price-taxablebenefit {
	float: right;
}

.nm-basket-buttons-wrapper {
	margin: var(--space-l) 0 var(--space-m);
}

.nm-basket-testdrive-btn,
.nm-basket-wallet-btn {
	display: none;
}

.nm-showonly-wallet {
	display: none;
}

.nm-is-summary .nm-basket-summary-btn {
	display: none;
}

.nm-is-summary .nm-basket-testdrive-btn,
.nm-agent-is-wallet .nm-basket .nm-basket-wallet-btn.nm-showonly-wallet {
	display: block;
}

.nm-basket .nm-basket-toggle-widgets-label {
	cursor: pointer;
	display: block;
	pointer-events: none;
	position: relative;
}

.nm-basket .nm-basket-content {
	transition: background-color .4s;
}

.nm-basket-content .nm-basket-image-wrap,
.nm-basket-content .nm-basket-lists,
.nm-basket-content .nm-basket-main-info {
	max-height: 460px;
	transition: all .4s ease-out;
}

.nm-basket-servicepackage {
	border-top: 1px solid var(--color-grey-10);
	margin-bottom: 0;
	margin-top: var(--space-m);
	padding-top: var(--space-m);
}

.nm-basket-servicepackage__price-row {
	display: flex;
	justify-content: space-between;
	position: relative;
	width: 100%;
}

.nm-basket-servicepackage__disclaimertext {
	margin-top: var(--space-s);
}

/* Configuration started
============================================================================= */

.nm-configuration-started .nm-basket .nm-configuration-start .nm-layer .nm-configuration-testdrive + .nm-configuration-overview {
	display: none;
}

.nm-layer .nm-configuration-started .nm-configuration-testdrive,
.nm-configuration-started .nm-configuration-overview {
	display: block;
}

/* Summary Page - show extended basket in mobile viewports -
   everything normal on desktop so restrict styling to max-width
========================================================================= */

@media all and (max-width: 1099px) {
	.nm-is-summary.nm-nav-fixed .nm-basket-superwrap,
	.nm-is-summary .nm-basket-superwrap {
		position: relative;
		top: auto;
	}

	.nm-is-summary .nm-basket-wrap {
		margin: 0 auto;
		max-width: 1177.6px;
		width: 92%;
	}

	.nm-is-summary .nm-basket-content {
		box-shadow: none;
		max-height: none;
		overflow: visible;
	}

	.nm-is-summary .nm-basket-toggle:checked ~ .nm-basket-superwrap .nm-basket-content-wrap,
	.nm-is-summary .nm-basket-content-wrap {
		flex-wrap: wrap;
		max-height: none;
	}

	.nm-is-summary .nm-basket .nm-car {
		padding-bottom: var(--space-xxl);
		width: 100%;
	}

	.nm-is-summary .nm-basket .nm-car-model {
		pointer-events: none;
	}

	.nm-is-summary .nm-basket .nm-car-model .nm-icon-arrow {
		display: none;
	}

	.nm-is-summary .nm-basket .nm-car-engine {
		display: block;
	}

	.nm-is-summary .nm-basket .nm-car .nm-car-price-amount {
		max-height: 0;
		overflow: hidden;
	}

	.nm-is-summary .nm-basket .nm-car-price-total {
		border-top: 1px solid #e5e5e5;
		margin-top: var(--space-l);
		padding-top: var(--space-l);
	}

	.nm-scope-no-prices .nm-is-summary .nm-basket .nm-car-price-total {
		display: none;
	}

	.nm-is-summary .nm-basket-main-info,
	.nm-is-summary .nm-basket-lists {
		display: block;
		margin-right: 10%;
		width: 40%;
	}

	.nm-is-summary .nm-basket-image-link,
	.nm-is-summary .nm-basket-icon-large {
		display: none;
	}

	.nm-is-summary .nm-basket-icon-linklist {
		margin-bottom: var(--space-xl);
	}

	.nm-is-summary .nm-basket-interaction {
		display: flex;
		flex-flow: wrap;
		justify-content: space-between;
	}

	.nm-is-summary .nm-basket-interaction-item {
		width: 50%;
	}

	.nm-is-summary .nm-basket-interaction-item:nth-child(n + 3) {
		margin-top: var(--space-l);
	}

	.nm-is-summary .nm-basket .nm-infobox-container {
		left: 0;
		top: 36px;
		transform: none;
		width: 100%;
	}

	.nm-is-summary .nm-basket .nm-infobox-container::after {
		border-color: transparent transparent #000;
		border-style: solid;
		border-width: 0 6px 6px;
		content: "";
		height: 0;
		left: 24px;
		position: absolute;
		top: -6px;
		width: 0;
	}

	.nm-is-summary .nm-basket-toggle-label {
		display: none;
	}

	.nm-is-summary .nm-basket-buttons-wrapper {
		margin: var(--space-xxl) 0 var(--space-m);
	}
}

@media all and (max-width: 599px) {
	.nm-is-summary .nm-basket-main-info,
	.nm-is-summary .nm-basket-lists {
		display: block;
		margin-right: 0;
		width: 100%;
	}
}
/*	========================================================================= */

@media all and (min-width: 480px) {
	.nm-basket-image-wrap {
		height: 101px;
		width: 248px;
	}

	.nm-basket-image-link {
		margin: var(--space-m) var(--space-m) var(--space-m) var(--space-s);
		order: -1;
	}

	.nm-basket-zoom {
		height: 36px;
		width: 36px;
	}

	.nm-basket-content-wrap {
		padding: 0;
	}

	.nm-basket .nm-car-model {
		margin-bottom: var(--space-xs);
	}

	.nm-basket .nm-car-engine {
		display: block;
		margin-bottom: var(--space-s);
	}
}

@media all and (min-width: 769px) {
	.nm-basket-image-link {
		margin: 0 var(--space-m) 0 0;
	}

	.nm-basket-image-wrap {
		height: 128px;
		width: 314px;
	}
}

@media all and (min-width: 1100px) {
	.nm-basket-wrap {
		margin: 0 auto;
		max-width: 1177.6px;
		width: 92%;
	}

	.nm-basket-content,
	.nm-basket-content-wrap {
		max-height: none;
	}

	.nm-basket-content {
		box-shadow: none;
		overflow: visible;
	}

	.nm-basket-content-wrap {
		display: block;
		padding: 0;
	}

	.nm-basket-button {
		min-width: 0;
		width: 100%;
	}

	.nm-basket .nm-car-model {
		pointer-events: none;
	}

	.nm-basket .nm-car .nm-car-price-amount {
		max-height: 0;
		overflow: hidden;
		transition: all .2s ease-out;
		transition-delay: .1s;
	}

	.nm-basket .nm-car .nm-car-price-label-additional {
		margin-right: var(--space-m);
	}

	.nm-basket .nm-car-price-additional-disclaimer {
		display: none;
	}

	.nm-basket-image-wrap {
		height: auto;
		justify-content: center;
		margin: 12px -18px 18px -18px;
		min-height: 85px;
		width: calc(100% + 36px);
	}

	.nm-basket .nm-basket-zoom {
		display: none;
	}

	.nm-basket-show-zoom .nm-basket-zoom {
		display: block;
	}

	.nm-basket-main-info,
	.nm-basket-lists {
		display: block;
	}

	.nm-basket-content {
		border: 1px solid #ccc;
		margin: 50px 0 var(--space-l) 0;
		padding: 18px 18px 24px;
		position: relative;
	}

	.nm-basket-image-link {
		margin: 0;
	}

	.nm-basket .nm-car {
		padding: 0;
	}

	.nm-basket .nm-car-model,
	.nm-basket .nm-car-engine {
		margin-bottom: 0;
	}

	.nm-basket .nm-basket-toggle-widgets-label .nm-car-model {
		padding-right: 30px;
	}

	.nm-not-sticky .nm-basket-toggle:checked ~ .nm-basket-superwrap .nm-basket-content-wrap {
		max-height: none;
	}

	.nm-basket-icon-large {
		display: none;
	}

	.nm-basket-content .nm-car-price-total {
		border-top: 1px solid #e5e5e5;
		margin-top: var(--space-m);
		padding-top: 18px;
		white-space: nowrap;
	}

	.nm-scope-no-prices .nm-basket-content .nm-car-price-total {
		display: none;
	}

	.nm-basket-interaction {
		display: flex;
		flex-flow: wrap;
	}

	.nm-basket-interaction-item {
		width: 50%;
	}

	.nm-basket-interaction-item:nth-child(n + 3) {
		margin-top: var(--space-l);
	}

	.nm-basket-icon-linklist {
		margin-bottom: 30px;
	}

	.nm-basket-scroll-check-bottom {
		background-color: transparent;
		bottom: 0;
		height: 1px; /* for IntersectionObserver in Edge */
		position: absolute;
		width: 100%;
	}

	[page-override="widescreen-overwrite"] .nm-basket-superwrap {
		display: none !important;
	}

	.nm-basket-superwrap {
		display: none;
		max-width: 1400px;
		position: absolute;
		width: 100%;
		z-index: 4;
	}

	.nm-basket-superwrap-hidden-measure {
		display: block;
		visibility: hidden;
	}

	.nm-has-basket .nm-basket-superwrap {
		display: block;
	}

	.nm-basket-container {
		float: right;
		padding-bottom: 40px;
		position: relative;
		transition: top .4s;
		width: 26%;
	}

	.nm-layer .nm-basket-wrap,
	.nm-layer-full .nm-basket-wrap {
		margin: 0 auto;
		max-width: 1177.6px;
		position: relative;
		width: 92%;
	}

	.nm-layer .nm-basket,
	.nm-layer-full .nm-basket {
		top: 0;
	}

	.nm-layer-full .nm-basket {
		padding-top: 20px;
	}

	.nm-basket .nm-basket-toggle-widgets-label .nm-icon-arrow {
		display: block;
		height: 24px;
		pointer-events: all;
		position: absolute;
		right: 0;
		top: 0;
		transform: rotate(-90deg);
		transition: transform .2s ease-out;
		width: 24px;
	}

	.nm-basket .nm-car-model .nm-icon-arrow {
		display: none;
	}

	.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-content .nm-basket-toggle-widgets-label .nm-icon-arrow {
		transform: rotate(90deg);
	}

	.nm-basket .nm-basket-toggle-widgets ~ .nm-basket-matching .nm-basket-toggle-widgets-label .nm-icon-arrow {
		transform: rotate(90deg);
	}

	.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching .nm-basket-toggle-widgets-label .nm-icon-arrow {
		transform: rotate(-90deg);
	}

	.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-content .nm-basket-toggle-widgets-label-fullarea {
		display: block;
	}

	.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-content {
		background-color: #e7e9ea;
	}

	.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-content:hover {
		background-color: #d3d5d7;
	}

	.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-content .nm-basket-image-wrap,
	.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-content .nm-basket-lists,
	.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-content .nm-basket-main-info {
		margin-bottom: 0;
		margin-top: 0;
		max-height: 0;
		min-height: 0;
		overflow: hidden;
	}

	.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-content .nm-car .nm-car-price-amount {
		max-height: 60px;
	}

	.nm-basket-toggle:checked ~ .nm-basket-superwrap .nm-basket-content-wrap {
		max-height: none;
	}

	.nm-nav-fixed .nm-basket-superwrap[data-is-stickable="false"] {
		position: relative;
		top: auto;
	}

	.nm-nav-fixed .nm-basket-superwrap.nm-basket-sticky-bottom,
	.nm-basket-sticky-bottom {
		bottom: 0;
		position: absolute;
		top: auto;
	}

	.nm-basket-sticky-bottom .nm-basket-container {
		transform: translateY(-100%);
	}
}

@media all and (min-width: 1440px) {
	[page-type="widescreen"] .nm-basket-icon-linklist {
		margin-bottom: var(--space-xl);
	}
}

/* Vertical Breakpoints
============================================================================= */

@media all and (max-height: 879px) and (min-width: 1100px) {
	.nm-basket-icon-large {
		display: block;
	}

	.nm-basket-icon-linktext,
	.nm-basket-icon-small {
		display: none;
	}

	.nm-basket-interaction-item {
		width: calc((100% - 48px) / 3);
	}

	.nm-basket-interaction-item:nth-child(4n + 4) {
		width: 48px;
	}

	.nm-basket-interaction-item:nth-child(n + 3) {
		margin-top: 0;
	}

	.nm-basket-icon {
		height: 48px;
		margin: 0;
		width: 48px;
	}
}

@media all and (max-height: 779px) and (min-width: 1100px) {
	.nm-basket .nm-car-price {
		display: none;
	}

	.nm-basket .nm-car-price-total {
		border-top: 0;
		margin-top: 0;
		padding-top: 0;
	}
}
