/*	media library packages
============================================================================= */

.nm-md-media-library-packages {
	margin-bottom: 40px;
	margin-right: 25px;
	width: -webkit-calc(50% - 25px);
	width: calc(50% - 25px);
}

.nm-md-media-library-packages:nth-child(odd) {
	clear: both;
}

.nm-md-media-library-packages img {
	margin-bottom: 10px;
}

.nm-md-media-library-packages p {
	float: left;
	margin-right: 10px;
	margin-top: 3px;
	max-width: 87%;
}

.nm-md-media-library-packages .nm-info {
	float: right;
}

.nm-md-media-library-packages .nm-module-wrap .nm-module {
	margin-right: 12.7%;
	width: 37.3%;
}

@media (max-width: 429px) {
	.nm-md-media-library-packages {
		margin-right: 0;
		width: 100%;
	}
}
