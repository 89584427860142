.nm-module-carstore .nm-carstore-headline {
	margin-bottom: 0;
}

.nm-module-carstore .nm-carstore-item {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	margin: 0 0 80px;
	position: relative;
}

.nm-module-carstore .nm-carstore-item:first-child {
	margin-top: 40px;
}

.nm-module-carstore .nm-carstore-item-position {
	background: #000;
	color: #fff;
	padding: 5px 10px;
	position: absolute;
}

.nm-module-carstore .nm-carstore-item-image {
	align-self: center;
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
}

.nm-module-carstore .nm-carstore-detail {
	margin-bottom: 20px;
}

.nm-module-carstore .nm-carstore-modify-section {
	opacity: 1;
}

.nm-module-carstore .nm-carstore-modify-section[disabled="disabled"] {
	cursor: default;
	opacity: .4;
}

.nm-module-carstore .nm-carstore-modify-select {
	height: 40px;
	vertical-align: top;
	width: calc(90% - 45px);
}

.nm-module-carstore .nm-carstore-delete-section {
	padding: 0 0 70px;
}

.nm-module-carstore .nm-carstore-button {
	float: left;
	margin: 15px 15px 0 0;
}

/* SCOPE RELATED SETTINGS */
.nm-scope-no-prices .nm-module-carstore .nm-price,
.nm-scope-no-prices .nm-module-carstore .nm-car-price-total {
	display: none;
}

@media (min-width: 480px) {
	.nm-module-carstore .nm-carstore-item {
		flex-flow: row nowrap;
		margin: 25px 0 80px;
	}

	.nm-module-carstore .nm-carstore-item-image {
		height: auto;
		margin: 0;
		max-width: 350px;
		width: 49%;
	}

	.nm-module-carstore .nm-carstore-item-content {
		width: 50%;
	}
}