.nm-summary-options-list {
	border-left: 1px solid var(--color-grey-10);
	border-right: 1px solid var(--color-grey-10);
	border-top: 1px solid var(--color-grey-10);
}

.nm-summary-options__base-price + .nm-summary-options-list {
	margin-top: var(--space-m);
}

.nm-summary-options-list__item-container {
	border-bottom: 1px solid var(--color-grey-10);
	padding: var(--space-l);
	position: relative;
}

.nm-summary-options-list__item {
	display: flex;
	flex-direction: column;
	min-height: 60px;
}

.nm-summary-options-list__price {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-l);
}

.nm-summary-options-list__text {
	--icon-space: var(--space-s);
	margin-bottom: var(--space-xs);
	width: calc(100% - ((2 * var(--icon-size-small)) + (2 * var(--icon-space))));
}

.nm-summary-options-list__icon-container {
	display: flex;
	position: absolute;
	right: var(--space-l);
}

.nm-summary-options-list__icon {
	display: flex;
	height: 24px;
	margin-left: var(--space-s);
	width: 24px;
}

.nm-summary-options-list__image-container {
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-summary-options-list__image-container--full {
	height: auto;
	padding-bottom: 0;
}

.nm-summary-options-list__image {
	height: auto;
	width: 100%;
}

.nm-summary-options-list__image--middle {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}

.nm-summary-options-list__image--bottom {
	bottom: 0;
	position: absolute;
}

.nm-summary-options-list .nm-j-configurator-status_11000,
.nm-summary-options-list .nm-j-configurator-status_11001,
.nm-summary-options-list .nm-j-configurator-status_11100,
.nm-summary-options-list .nm-j-configurator-status_10011,
.nm-summary-options-list .nm-j-configurator-status_10000,
.nm-summary-options-list .nm-j-configurator-status_10010 {
	display: none;
}

@media all and (min-width: 480px) {
	.nm-summary-options-list__text {
		--base-width-percentage: .64;
		--base-paddings: calc(2 * var(--space-l));
		margin-bottom: var(--space-s);
		padding-right: var(--space-l);
		width: calc((100% + var(--base-paddings)) * var(--base-width-percentage) - var(--base-paddings));
	}

	.nm-summary-options-list__image-container {
		--height-proportion-to-width: calc(140px / (16 / 9));
		height: var(--height-proportion-to-width);
		padding-bottom: 0;
		width: 140px;
	}

	.nm-summary-options-list__image-container--full {
		display: flex;
		height: auto;
		justify-content: center;
		padding-bottom: 0;
	}

	/* if the full-option is choosed it is not 16/9 view anymore */
	/* due to the need to overwrite, a higher specificity is needed */
	.nm-summary-options-list__image.nm-summary-options-list__image--full {
		height: 80px;
		width: 80px;
	}

	.nm-summary-options-list__price {
		margin-bottom: var(--space-s);
		position: absolute;
		right: var(--space-l);
		text-align: right;
		top: var(--space-l);
		width: calc(36% - var(--space-l));
	}

	.nm-summary-options-list__icon-container {
		max-width: 36%;
		right: var(--space-l);
	}

	.nm-summary-options-list__price + .nm-summary-options-list__icon-container {
		--space-from-top-including-price: calc((var(--space-s) + var(--space-l)) + 2.4rem);
		top: var(--space-from-top-including-price);
	}

	.nm-scope-no-prices .nm-summary-options-list__price + .nm-summary-options-list__icon-container,
	.nm-summary-options-list__price.nm-hidden + .nm-summary-options-list__icon-container {
		top: var(--space-l);
	}
}

@media all and (min-width: 768px) {
	.nm-summary-options-list__item {
		flex-direction: row;
	}

	.nm-summary-options-list__text {
		--base-width-percentage: .48;
		--base-paddings: calc(2 * var(--space-l));
		width: calc((100% + var(--base-paddings)) * var(--base-width-percentage) - var(--base-paddings));
	}

	.nm-summary-options-list__price {
		--width-without-paddings: calc(36% - 2 * var(--space-l));
		width: var(--width-without-paddings);
	}

	.nm-summary-options-list__image-container {
		--base-width-percentage: .16;
		--base-paddings: calc(2 * var(--space-l));
		height: 0;
		padding-bottom: 9%;
		width: calc((100% + var(--base-paddings)) * var(--base-width-percentage));
	}

	.nm-summary-options-list__image-container--full {
		display: flex;
		height: auto;
		justify-content: center;
		padding-bottom: 0;
	}
}
