.nm-module-foldout-techdata {
	margin: 0 auto;
	width: 92%;
}

.nm-module-foldout-techdata .nm-foldout-techdata-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: 36px;
}

.nm-module-foldout-techdata .nm-foldout-techdata-engine-container {
	background-color: #fff;
	margin-bottom: 42px;
	padding: 24px 4.34% 36px;
}

.nm-module-foldout-techdata .nm-foldout-techdata-engine-container .nm-foldout-techdata-carline-name {
	margin-bottom: 24px;
}

.nm-module-foldout-techdata .nm-foldout-techdata-carline-name.
.nm-module-foldout-techdata .nm-foldout-techdata-engine-name {
	font-weight: var(--font-weight-bold);
}

[data-theme="dark"] .nm-module-foldout-techdata .nm-foldout-techdata-engine-container .nm-foldout-techdata-carline-name,
[data-theme="dark"] .nm-module-foldout-techdata .nm-foldout-techdata-engine-container .nm-foldout-techdata-engine-name,
[data-theme="dark"] .nm-module-foldout-techdata .nm-foldout-techdata-engine-container .nm-foldout-techdata-price {
	color: #000;
}

.nm-module-foldout-techdata .nm-foldout-techdata-list-container {
	margin-bottom: 42px;
}

.nm-module-foldout-techdata .nm-foldout-techdata-list-container .nm-foldout-techdata-list-headline {
	margin-bottom: 36px;
}

.nm-module-foldout-techdata .nm-foldout-techdata-list-row {
	background-color: transparent;
	display: flex;
	flex-flow: row nowrap;
	hyphens: auto;
	justify-content: space-between;
	word-wrap: break-word;
}

.nm-module-foldout-techdata .nm-foldout-techdata-list-key,
.nm-module-foldout-techdata .nm-foldout-techdata-list-desc {
	padding: 12px 0 24px;
	width: 47%;
}

.nm-module-foldout-techdata .nm-foldout-techdata-list-key {
	font-weight: var(--font-weight-bold);
}

.nm-module-foldout-techdata .nm-foldout-techdata-list-row:first-of-type {
	border-top: 3px solid #000;
}

.nm-module-foldout-techdata .nm-foldout-techdata-list-row:not(:last-child) {
	border-bottom: 1px solid #000;
}

[data-theme="dark"] .nm-module-foldout-techdata .nm-foldout-techdata-list-row:first-child .nm-foldout-techdata-list-key,
[data-theme="dark"] .nm-module-foldout-techdata .nm-foldout-techdata-list-row:not(:last-child),
[data-theme="dark"] .nm-module-foldout-techdata .nm-foldout-techdata-list-row:last-child > dd {
	border-color: #fff;
}

.nm-module-foldout-techdata .nm-foldout-techdata-list-container .nm-foldout-techdata-list-buttons {
	text-align: right;
}

.nm-module-foldout-techdata .nm-foldout-techdata-list-button {
	margin-top: 30px;
}

.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container {
	margin-bottom: 42px;
}

.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container .nm-foldout-techdata-efficiency-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: 36px;
}

.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container > p {
	margin-bottom: 18px;
}

.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container > p a {
	font-family: AudiTypeWide, sans-serif;
	font-weight: var(--font-weight-bold);
	text-decoration: underline;
}

[data-theme="dark"] .nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container > p a {
	color: #fff;
}

.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container .nm-foldout-techdata-efficiency-image {
	background-color: #fff;
	margin: 30px 0;
	max-width: 355px;
	width: 100%;
}

.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container .nm-foldout-techdata-efficiency-image span {
	background-position: top left;
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
	height: 0;
	overflow: hidden;
	padding-bottom: 67.324%;
	width: 100%;
}

@media screen and (min-width: 768px) {
	.nm-module-foldout-techdata {
		width: 76%;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-engine-container {
		margin-bottom: 48px;
		padding-left: 5.26%;
		padding-right: 5.26%;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-container {
		margin-bottom: 48px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container {
		margin-bottom: 48px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-row:first-of-type {
		border-top: none;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-row:not(:last-child) {
		border-bottom: none;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-key {
		border-top: 3px solid #000;
		width: calc(26% - 6px);
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-desc {
		border-top: 1px solid #000;
		width: 74%;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-desc:last-child {
		border-bottom: 1px solid #000;
	}

	[data-theme="dark"] .nm-module-foldout-techdata .nm-foldout-techdata-list-key,
	[data-theme="dark"] .nm-module-foldout-techdata .nm-foldout-techdata-list-desc {
		border-color: #fff;
	}
}

@media screen and (min-width: 1024px) {
	.nm-module-foldout-techdata .nm-foldout-techdata-headline {
		margin-bottom: 42px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-engine-container {
		margin-bottom: 60px;
		padding-bottom: 42px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-container {
		margin-bottom: 60px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-container .nm-foldout-techdata-list-headline {
		margin-bottom: 42px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container {
		margin-bottom: 60px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container .nm-foldout-techdata-efficiency-headline {
		margin-bottom: 42px;
	}
}

@media screen and (min-width: 1440px) {
	.nm-module-foldout-techdata .nm-foldout-techdata-headline {
		margin-bottom: 54px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-engine-container {
		margin-bottom: 78px;
		padding-bottom: 54px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-container {
		margin-bottom: 78px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-container .nm-foldout-techdata-list-headline {
		margin-bottom: 54px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-button {
		margin-top: 36px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container {
		margin-bottom: 78px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container .nm-foldout-techdata-efficiency-headline {
		margin-bottom: 54px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container .nm-foldout-techdata-efficiency-image {
		margin: 36px 0;
	}
}

@media screen and (min-width: 1920px) {
	.nm-module-foldout-techdata .nm-foldout-techdata-headline {
		margin-bottom: 66px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-engine-container {
		margin-bottom: 96px;
		padding-bottom: 66px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-container {
		margin-bottom: 96px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-container .nm-foldout-techdata-list-headline {
		margin-bottom: 66px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-list-button {
		margin-top: 42px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container {
		margin-bottom: 96px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container .nm-foldout-techdata-efficiency-headline {
		margin-bottom: 66px;
	}

	.nm-module-foldout-techdata .nm-foldout-techdata-efficiency-container .nm-foldout-techdata-efficiency-image {
		margin: 42px 0;
	}
}
