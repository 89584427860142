.nm-module.nm-module-tiles-uncategorized {
	margin-bottom: 36px;
}

.nm-module-tiles-uncategorized-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: 6px;
}

.nm-module-tiles-uncategorized-tilelist-container {
	margin-bottom: 132px; /* default margin before applying height calculation */
	position: relative;
}

.nm-module-tiles-uncategorized-tilelist {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -6px;
}

.nm-module-tiles-uncategorized-tile-container {
	margin: 12px 6px 0;
	width: 90px;
}

.nm-module-tiles-uncategorized-tile {
	cursor: pointer;
	position: relative;
}

.nm-module-tiles-uncategorized-image {
	transition: opacity .2s;
}

.nm-module-tiles-uncategorized-tileinfo {
	background-color: #fff;
	display: none;
	left: 0;
	margin-top: 12px;
	min-height: 120px;
	position: absolute;
	width: 100%;
	z-index: 2;
}

.nm-module-tiles-uncategorized-description {
	margin-right: 12px;
	width: calc(100% - 36px);
}

.nm-module-tiles-uncategorized-description .nm-non-renderable {
	font-weight: var(--font-weight-bold);
}

.nm-module-tiles-uncategorized .nm-tile-fadeout .nm-module-tiles-uncategorized-image {
	opacity: .6;
}

.nm-module-tiles-uncategorized-tile-container:hover .nm-module-tiles-uncategorized-tileinfo {
	display: flex;
	z-index: 4;
}

.nm-module-tiles-uncategorized-conflictlink.nm-tooltip-container .nm-tooltip-popup {
	z-index: 5;
}

.nm-module-tiles-uncategorized-icon-selected {
	bottom: -6px;
	display: none;
	height: 24px;
	position: absolute;
	right: -6px;
	width: 24px;
}

.nm-module-tiles-uncategorized-conflictlink {
	display: none;
	height: 24px;
	position: absolute;
	right: -12px;
	top: -12px;
	width: 24px;
}

.nm-module-tiles-uncategorized-icon-alert-badge,
.nm-module-tiles-uncategorized-icon-cancel,
.nm-module-tiles-uncategorized-icon-info {
	height: 24px;
	width: 24px;
}

/* item selected -> show selected icon + descrption text */
.nm-j-configurator-status_11010 .nm-module-tiles-uncategorized-icon-selected,
.nm-j-configurator-status_10010 .nm-module-tiles-uncategorized-icon-selected,
.nm-j-configurator-status_10011 .nm-module-tiles-uncategorized-icon-selected {
	display: block;
}

.nm-j-configurator-status_11010 .nm-module-tiles-uncategorized-tileinfo,
.nm-j-configurator-status_10010 .nm-module-tiles-uncategorized-tileinfo,
.nm-j-configurator-status_10011 .nm-module-tiles-uncategorized-tileinfo {
	display: flex;
	z-index: 3;
}

/* item conflict -> show conflict icon */
.nm-j-configurator-status_10100 .nm-module-tiles-uncategorized-conflictlink,
.nm-j-configurator-status_10101 .nm-module-tiles-uncategorized-conflictlink,
.nm-j-configurator-status_11100 .nm-module-tiles-uncategorized-conflictlink,
.nm-j-configurator-status_11101 .nm-module-tiles-uncategorized-conflictlink {
	display: block;
}

/*
when an exclusive color is selected, the generic Q0Q0 tile is also selected by DPU
therefore we hide the Q0Q0 selected icon and tileinfo text and show it only if no other color is selected
more color codes: O0O0, BODY_Q0, BODY_O0
*/
.nm-module-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="Q0Q0"] .nm-module-tiles-uncategorized-icon-selected,
.nm-module-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="O0O0"] .nm-module-tiles-uncategorized-icon-selected,
.nm-module-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="BODY_Q0"] .nm-module-tiles-uncategorized-icon-selected,
.nm-module-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="BODY_O0"] .nm-module-tiles-uncategorized-icon-selected,
.nm-module-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="Q0Q0"] .nm-module-tiles-uncategorized-tileinfo,
.nm-module-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="O0O0"] .nm-module-tiles-uncategorized-tileinfo,
.nm-module-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="BODY_Q0"] .nm-module-tiles-uncategorized-tileinfo,
.nm-module-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="BODY_O0"] .nm-module-tiles-uncategorized-tileinfo {
	display: none;
}

/*
when the individual-color tile is actually clicked the data-tile-selected attribute is set with the mbvid code of it
this is done per javascript in the container.js (function: tilesExclusiv())
this attribute is used so it is known when the tile is clicked and the selected-icon and the tileinfo text can be shown
*/
.nm-module-tiles-exclusive[data-tile-selected="Q0Q0"] .nm-j-configurator-status_11010[data-configurator-id="Q0Q0"] .nm-module-tiles-uncategorized-icon-selected,
.nm-module-tiles-exclusive[data-tile-selected="O0O0"] .nm-j-configurator-status_11010[data-configurator-id="O0O0"] .nm-module-tiles-uncategorized-icon-selected,
.nm-module-tiles-exclusive[data-tile-selected="BODY_Q0"] .nm-j-configurator-status_11010[data-configurator-id="BODY_Q0"] .nm-module-tiles-uncategorized-icon-selected,
.nm-module-tiles-exclusive[data-tile-selected="BODY_O0"] .nm-j-configurator-status_11010[data-configurator-id="BODY_O0"] .nm-module-tiles-uncategorized-icon-selected,
.nm-module-tiles-exclusive[data-tile-selected="Q0Q0"] .nm-j-configurator-status_11010[data-configurator-id="Q0Q0"] .nm-module-tiles-uncategorized-tileinfo,
.nm-module-tiles-exclusive[data-tile-selected="O0O0"] .nm-j-configurator-status_11010[data-configurator-id="O0O0"] .nm-module-tiles-uncategorized-tileinfo,
.nm-module-tiles-exclusive[data-tile-selected="BODY_Q0"] .nm-j-configurator-status_11010[data-configurator-id="BODY_Q0"] .nm-module-tiles-uncategorized-tileinfo,
.nm-module-tiles-exclusive[data-tile-selected="BODY_O0"] .nm-j-configurator-status_11010[data-configurator-id="BODY_O0"] .nm-module-tiles-uncategorized-tileinfo {
	display: block;
}

@media screen and (min-width: 1024px) {
	.nm-module.nm-module-tiles-uncategorized {
		margin-bottom: 42px;
	}
}

@media screen and (min-width: 1440px) {
	[page-type="widescreen"] .nm-module.nm-module-tiles-uncategorized {
		margin-bottom: 54px;
	}
}

@media screen and (min-width: 1920px) {
	[page-type="widescreen"] .nm-module.nm-module-tiles-uncategorized {
		margin-bottom: 60px;
	}
}
