
.nm-iph-active .nm-basket-interaction {
	margin-bottom: 0;
	position: relative;
	width: -webkit-calc(129% - 32px);
	width: calc(129% - 32px);
}

.nm-iph-active .nm-basket-interaction li {
	height: 32px;
	margin-top: 5px;
	position: relative;
	width: 25%;
}

.nm-iph-active .nm-basket-interaction li a {
	left: 0;
	padding: 0 !important;
	position: absolute;
	text-indent: -9999em;
	top: 0;
	width: 32px;
}

.nm-iph-active .nm-basket-linklist {
	position: relative;
}

.nm-iph-item {
	display: none;
}

.nm-iph-active .nm-iph-item {
	display: block;
}

.nm-no-iph-item {
	display: block;
}

.nm-2col .nm-no-iph-item,
.nm-basket-buttons-wrapper .nm-no-iph-item {
	display: inline-flex;
}

.nm-iph-active .nm-no-iph-item {
	display: none;
}

.nm-iph-active .nm-basket-linklist .nm-icon-cancel {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDozNEY3ODQzODM3MjE2ODExODcxRkRGNUM1MjI2RjdGNCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3QUJCOTlDOTYxNUQxMUUxQjk3MkNFRDQ1QjUwNzdGMiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3QUJCOTlDODYxNUQxMUUxQjk3MkNFRDQ1QjUwNzdGMiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRCNzc4RkYyNUMyMTY4MTE4REJCQzQzNzNEOTU3OTJEIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjM0Rjc4NDM4MzcyMTY4MTE4NzFGREY1QzUyMjZGN0Y0Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+LhmcdAAAAI1JREFUeNrslm0KwCAIhnUn6Wjd/8+O4AxcLCKsQcjYK7wEJfmQH8QiQpF2ULABAAAAAMAUADOLKqvIUfFZG61lFHtSy8XV1pFVn5k7692TAB5Ec7YLYATR7e0EeAZMpu5VdgOQBT5N6U1d3Qpvw8+lILQIs+U8pA3DB5EXvIFYAWD8igEAAAD8HuASYADUY36JrRTJQAAAAABJRU5ErkJggg==");
	background-position: 0 0;
	background-repeat: no-repeat;
	cursor: pointer;
	display: inline-block;
	height: 30px;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
}

#nm-iph-wrapper li {
	display: none;
}

.nm-iph-active #nm-iph-wrapper li {
	display: block;
}

.nm-iph-active .nm-iph-name {
	max-width: calc(100% - 60px);
}

.nm-iph-active .nm-iph-name::after {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAIAAABPmPnhAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzE5MDUyMTk3QzhFMTFFNEI1NDNGRTgwNEYyMTU0QzciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzE5MDUyMUE3QzhFMTFFNEI1NDNGRTgwNEYyMTU0QzciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMTkwNTIxNzdDOEUxMUU0QjU0M0ZFODA0RjIxNTRDNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMTkwNTIxODdDOEUxMUU0QjU0M0ZFODA0RjIxNTRDNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqfRNDoAAABvSURBVHjaYjgv4HCGwfj9+v3/////8/4TMhcIGL6evwnkA0WBDCAfjcsAxG/mb0IWQuYyQAy5n9AAFLqk4As0H5nLxAAGzAK8QPLvh89AhMwlZDgQQ1z7af8ZiNOQuQzXDCKBHKAOiMeQuUAAEGAAxtqXbeQHCT4AAAAASUVORK5CYII=");
	background-repeat: no-repeat;
	content: "";
	height: 22px;
	margin: 5px;
	position: absolute;
	width: 22px;
}

.nm-iph-active .nm-iph-phone {
	display: block;
	margin: 5px 0 10px 15px;
}

@media all and (max-height: 669px) and (min-width: 1000px) {
	.nm-iph-active .nm-basket-wrap .nm-car-price {
		display: none;
	}

	.nm-iph-active .nm-basket-content .nm-car-price-total {
		border-top: 0;
		margin-top: 3px;
		padding-top: 0;
	}
}

@media screen and (max-width: 999px) {
	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket-interaction li {
		margin-bottom: 25px;
		width: 70px;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket-interaction li a,
	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket-interaction .nm-el-lk.nm-el-lk-icn::before {
		height: 40px;
		width: 40px;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket-lists {
		max-width: none;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket-linklist {
		border: 1px solid #d0d3d4;
		margin-top: 0;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li .nm-iph-name {
		margin-top: 10px;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li a {
		margin: 0 0 0 15px;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li:last-child a {
		margin-bottom: 8px;
	}
}

@media screen and (max-width: 599px) {
	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li a.nm-icon-cancel {
		width: 30px;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li.nm-iph-item {
		background: none;
		border-top: 0;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li .nm-iph-name {
		margin-top: 0;
		padding: 15px 0 5px;
	}

	.nm-is-summary.nm-iph-active .nm-iph-phone {
		margin: 0 0 15px;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li {
		background-position: 100% center;
		border-bottom: 0;
		border-top: 1px solid #d0d3d4;
		margin: 0 15px;
		width: calc(100% - 30px);
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li a {
		margin: 0;
		padding: 15px 0;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li:last-child {
		border-bottom: none;
	}

	.nm-state-is-responsive .nm-is-summary.nm-iph-active .nm-basket .nm-basket-linklist li:last-child a {
		margin-bottom: 0;
	}
}
