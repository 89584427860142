.nm-summary-options-total {
	color: var(--color-grey-70);
	margin-top: var(--space-l);
}

.nm-scope-no-prices .nm-summary-options-total {
	display: none;
}

.nm-summary-options__base-price + .nm-summary-options-total {
	margin-top: var(--space-xs);
}

.nm-summary-options-total__row {
	margin-top: var(--space-s);
}

.nm-summary-options-total__row:first-of-type {
	margin-top: 0;
}

.nm-summary-options-total__row--sum {
	color: var(--color-black);
	font-weight: var(--font-weight-bold);
}

.nm-summary-options-total__row--rotr {
	margin-bottom: var(--space-s);
}

.nm-summary-options-total__value {
	margin-left: var(--space-s);
}

.nm-summary-options-total__footnote {
	cursor: pointer;
}

.nm-summary-options-total__servicepackage {
	display: inline-flex;
	margin-bottom: var(--space-s);
	margin-top: var(--space-m);
}

.nm-summary-options-total__tooltip-headline {
	margin-top: var(--space-xxs);
}

.nm-summary-options-total__tooltip-text {
	margin-top: var(--space-s);
}

.nm-summary-options-total__disclaimer {
	color: var(--color-black);
	margin-top: var(--space-xs);
}

.nm-summary-options-total__tooltip-container {
	position: relative;
}

.nm-summary-options-total__tooltip-container .nm-summary-options-total__tooltip-popup {
	right: auto;
	transform: none;
	width: 240px;
}

.nm-summary-options-total__tooltip-container .nm-summary-options-total__tooltip-popup::after,
.nm-summary-options-total__tooltip-container .nm-summary-options-total__tooltip-popup::before {
	background: var(--color-black);
	left: calc(33% - 15px);
}

.nm-summary-options-total__tooltip-container .nm-summary-options-total__tooltip-popup-text {
	background: var(--color-black);
	padding-bottom: var(--space-l);
}

@media all and (min-width: 480px) {
	.nm-summary-options-total {
		text-align: right;
	}

	.nm-summary-options-total__tooltip-container .nm-summary-options-total__tooltip-popup {
		--offset-tooltip: calc(100% + 10px + var(--space-xs));
		right: var(--offset-tooltip);
		top: 12px;
		transform: translateX(0) translateY(-50%);
	}

	.nm-summary-options-total__tooltip-container.nm-tip-left .nm-summary-options-total__tooltip-popup {
		left: auto;
	}

	.nm-summary-options-total__tooltip-container .nm-summary-options-total__tooltip-popup::before,
	.nm-summary-options-total__tooltip-container .nm-summary-options-total__tooltip-popup::after,
	.nm-summary-options-total__tooltip-container.nm-tip-left .nm-summary-options-total__tooltip-popup::before,
	.nm-summary-options-total__tooltip-container.nm-tip-left .nm-summary-options-total__tooltip-popup::after {
		left: auto;
		right: -8px;
		top: calc(50% - 8px);
	}
}
