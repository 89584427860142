/* remove margin that is used to hide scrollbars */
.sc-module[data-module=details-stage-main] + .nm-content {
	margin-top: -30px;
}

.nm-module.nm-md-render-stage {
	margin-bottom: 0;
}

.nm-md-render-stage input {
	display: none;
}

.nm-md-render-stage .nm-render-stage-wrapper {
	height: 0;
	margin: 0 auto;
	overflow: hidden;
	padding-bottom: 31.25%;
	position: relative;
	width: 100%;
}

.nm-md-render-stage .nm-render-stage-landscape-item-wrap,
.nm-md-render-stage .nm-render-stage-portrait-item-wrap {
	display: none;
}

/* portrait */
@media screen and (orientation: portrait) {
	.nm-md-render-stage .nm-render-stage-portrait-item-wrap {
		display: block;
		height: 0;
		padding-bottom: 60%;
		width: 100%;
	}

	.nm-md-render-stage .nm-render-stage-wrapper {
		padding-bottom: 60%;
	}
}

/* landscape */
@media screen and (orientation: landscape) {
	.nm-md-render-stage .nm-render-stage-landscape-item-wrap {
		display: block;
		height: 0;
		padding-bottom: 31.25%;
		width: 100%;
	}
}

.nm-md-render-stage .nm-render-stage-navigation {
	display: none;
	left: 0;
	position: absolute;
	right: 0;
	top: calc(50% - 30px);
}

.nm-md-render-stage .nm-render-stage-navigation .nm-render-stage-prev,
.nm-md-render-stage .nm-render-stage-navigation .nm-render-stage-next {
	align-items: center;
	background-color: #000;
	cursor: pointer;
	display: flex;
	height: 51px;
	justify-content: center;
	position: absolute;
	top: -24px;
	width: 51px;
}

.nm-md-render-stage .nm-render-stage-navigation .nm-render-stage-prev {
	left: 0;
}

.nm-md-render-stage .nm-render-stage-navigation .nm-render-stage-next {
	right: 0;
}

.nm-md-render-stage .nm-render-stage-navigation .nm-render-stage-prev svg,
.nm-md-render-stage .nm-render-stage-navigation .nm-render-stage-next svg {
	fill: #fff;
	height: 48px;
	width: 48px;
}

.nm-md-render-stage .nm-render-stage-wrapper .nm-render-stage-dots {
	bottom: 18px;
	display: flex;
	height: 8px;
	justify-content: center;
	left: 0;
	position: absolute;
	right: 0;
	width: 100%;
}

.nm-md-render-stage .nm-render-stage-wrapper .nm-render-stage-dots li {
	background-color: #000;
	display: block;
	height: 8px;
	margin-left: 3px;
	opacity: .2;
	width: 20px;
}

.nm-md-render-stage .nm-render-stage-toggle {
	bottom: 12px;
	cursor: pointer;
	height: 24px;
	position: absolute;
	right: 12px;
	width: 24px;
}

.nm-md-render-stage .nm-render-stage-toggle svg {
	cursor: pointer;
	height: 24px;
	transform-origin: 50% 50%;
	transition-duration: .3s;
	transition-property: transform;
	width: 24px;
}

.nm-md-render-stage .nm-render-stage-toggle-input:checked ~ .nm-render-stage-wrapper .nm-render-stage-toggle svg {
	transform: rotate(45deg);
}

.nm-md-render-stage .nm-render-stage {
	font-size: 0;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
	white-space: nowrap;
	width: 100%;
}

.nm-md-render-stage .nm-render-stage--no-animation {
	scroll-behavior: auto;
}

.nm-md-render-stage .nm-render-stage .nm-render-stage-item-wrap {
	display: inline-block;
	transition: opacity .3s;
}

.nm-md-render-stage .nm-render-stage--loading .nm-render-stage-item-wrap {
	opacity: 0;
}

.nm-md-render-stage .nm-render-stage .nm-render-stage-landscape-item,
.nm-md-render-stage .nm-render-stage .nm-render-stage-portrait-item {
	height: 0;
	padding-bottom: 31.25%;
	width: 100%;
}

.nm-md-render-stage .nm-render-stage .nm-render-stage-portrait-item {
	padding-bottom: 60%;
}

.nm-md-render-stage .nm-render-stage .nm-render-stage-landscape-item,
.nm-md-render-stage .nm-render-stage .nm-render-stage-portrait-item {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: inline-block;
	height: 0;
	scroll-snap-align: start;
}

.nm-md-render-stage .nm-render-stage-thumbnails-wrapper {
	background-color: #fff;
	height: 0;
	overflow: hidden;
	padding-bottom: 0;
	position: relative;
	width: 100%;
	z-index: 5;
}

.nm-md-render-stage .nm-render-stage-toggle-input:checked ~ .nm-render-stage-thumbnails-wrapper {
	background-color: var(--color-grey-05);
	display: block;
	overflow: visible;
	padding-bottom: 12.5%;
	padding-top: 6px;
}

.nm-md-render-stage .nm-render-stage-thumbnails-wrapper::after {
	background-color: var(--color-grey-05);
	bottom: -6px;
	content: "";
	display: block;
	height: 16px;
	left: 0;
	position: absolute;
	width: 100%;
}

.nm-md-render-stage .nm-render-stage-thumbnails {
	display: none;
	font-size: 0;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-scroll-behavior: smooth;
	scroll-behavior: smooth;
	white-space: nowrap;
	width: 100%;
}

.nm-md-render-stage .nm-render-stage-toggle-input:checked ~ .nm-render-stage-thumbnails-wrapper .nm-render-stage-thumbnails {
	display: block;
}

.nm-md-render-stage .nm-render-stage-thumbnails .nm-render-stage-thumbnail-item-wrap {
	display: inline-block;
	margin-right: 6px;
	position: relative;
	width: calc(22% - 6px);
}

.nm-md-render-stage .nm-render-stage-thumbnails .nm-render-stage-thumbnail-item-wrap:last-child {
	margin-right: 0;
}

.nm-md-render-stage .nm-render-stage-thumbnails .nm-render-stage-thumbnail-item-wrap::before,
.nm-md-render-stage .nm-render-stage-thumbnails .nm-render-stage-thumbnail-item-wrap::after {
	border: 1px solid #000;
	bottom: 0;
	box-sizing: border-box;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 2;
}

.nm-md-render-stage .nm-render-stage-thumbnails .nm-render-stage-thumbnail-item-wrap::before {
	border: 1px solid #fff;
	border-bottom: 3px solid #fff;
	bottom: 1px;
	left: 1px;
	right: 1px;
	top: 1px;
}

.nm-md-render-stage .nm-render-stage-thumbnails .nm-render-stage-thumbnail-item {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 0;
	padding-bottom: 56.25%;
	width: 100%;
}

.nm-md-render-stage .nm-render-stage-thumbnail-navigation {
	display: none;
}

.nm-md-render-stage .nm-render-stage-thumbnail-navigation .nm-render-stage-thumbnail-prev,
.nm-md-render-stage .nm-render-stage-thumbnail-navigation .nm-render-stage-thumbnail-next {
	cursor: pointer;
	position: absolute;
	top: calc(50% - 20px);
}

.nm-md-render-stage .nm-render-stage-thumbnail-navigation .nm-render-stage-thumbnail-prev {
	left: 0;
}

.nm-md-render-stage .nm-render-stage-thumbnail-navigation .nm-render-stage-thumbnail-next {
	right: 0;
}

.nm-md-render-stage .nm-render-stage-thumbnail-navigation .nm-render-stage-thumbnail-prev .nm-render-stage-thumbnail-navigation-icon,
.nm-md-render-stage .nm-render-stage-thumbnail-navigation .nm-render-stage-thumbnail-next .nm-render-stage-thumbnail-navigation-icon {
	height: 30px;
	width: 30px;
}

@media all and (min-width: 768px) {
	.nm-md-render-stage .nm-render-stage-wrapper .nm-render-stage-dots {
		display: none;
	}

	.nm-md-render-stage .nm-render-stage-toggle {
		right: calc(50% - 12px);
	}

	.nm-md-render-stage .nm-render-stage-navigation {
		display: block;
	}
}

@media all and (min-width: 1024px) {
	.nm-md-render-stage .nm-render-stage-thumbnails .nm-render-stage-thumbnail-item-wrap {
		width: calc((100% - 42px) / 8);
	}

	.nm-md-render-stage .nm-render-stage-toggle-input:checked ~ .nm-render-stage-thumbnails-wrapper .nm-render-stage-thumbnails.nm-render-stage-thumbnails-centered {
		display: flex;
		justify-content: center;
	}

	.nm-md-render-stage .nm-render-stage-toggle-input:checked ~ .nm-render-stage-thumbnails-wrapper {
		padding-bottom: 7.25%;
	}

	.nm-md-render-stage .nm-render-stage-thumbnail-navigation {
		display: block;
	}

	.nm-md-render-stage .nm-render-stage-thumbnails {
		margin: auto;
		width: calc(100% - (2 * (6px + 12px + 20px)));
	}
}
