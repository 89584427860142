/*******************************************************************

	Summary Standard Equipment List
	scope is .nm-module-summary-standard-equipment-list. Add it to every declaration

********************************************************************/

/* this block controls the visibility of configurator items */
/* per default, all these status codes are hidden */
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01111,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01110,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01101,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01100,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01011,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01010,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01001,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01000,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00111,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00110,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00101,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00100,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00011,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00010,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00001,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00000,
.nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_10000 {
	display: none;
}

/* show them for debug purposes */
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01111,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01110,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01101,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01100,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01011,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01010,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01001,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_01000,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00111,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00110,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00101,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00100,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00011,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00010,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00001,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_00000,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_10010,
.nm-state-is-debug .nm-module-summary-standard-equipment-list .nm-equipment-list > li.nm-j-configurator-status_10000 {
	display: block;
}

/* hide all configurator items */
.nm-module-summary-standard-equipment-list .nm-equipment-list .nm-j-configurator-item {
	display: none;
}

/* and show only the contained and overwritten (replaced) standard */
.nm-module-summary-standard-equipment-list .nm-equipment-list .nm-j-configurator-item.nm-j-configurator-status_10011,
.nm-module-summary-standard-equipment-list .nm-equipment-list .nm-j-configurator-item.nm-j-configurator-status_11001 {
	display: flex; /* makes the item visible and add it to the flex-flow */
}

.nm-module-summary-standard-equipment-list {
	/* scope */
	/* back-button positioning */
	margin-top: 75px;
}

/* main headline */
.nm-module-summary-standard-equipment-list .nm-headline-main {
	font-weight: var(--font-weight-bold);
	margin-bottom: 30px;
}

/* --- inpage navigation --- */
.nm-module-summary-standard-equipment-list .nm-navigation {
	display: inline-flex;
	margin-bottom: 36px;
	margin-top: 42px;
}

/* navigation item */
.nm-module-summary-standard-equipment-list .nm-navigation-item {
	opacity: .5;
}

/* navigational highlight */
.nm-module-summary-standard-equipment-list .nm-navigation-item-active {
	opacity: 1;
	pointer-events: none;
}

/* navigational items */
.nm-module-summary-standard-equipment-list .nm-navigation-listitem:not(:last-child) {
	margin-right: 30px;
}

/* summary standard list headline */
.nm-module-summary-standard-equipment-list .nm-headline-list {
	font-weight: var(--font-weight-bold);
	margin-bottom: 24px;
}

/* --- summary standard equipment list --- */
.nm-module-summary-standard-equipment-list .nm-equipment-list {
	margin-bottom: 42px;
	width: 100%;
}

/* listitem */
.nm-module-summary-standard-equipment-list .nm-equipment-list-item:first-of-type {
	border-top: 1px solid #d8d8d8; /* first item gets the border top ... */
}

.nm-module-summary-standard-equipment-list .nm-equipment-list-item {
	align-items: flex-start;
	border-bottom: 1px solid #d8d8d8;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding: 12px 0 24px;
}

.nm-module-summary-standard-equipment-list .nm-engine-name,
.nm-module-summary-standard-equipment-list .nm-engine-description {
	font-weight: var(--font-weight-bold);
}

.nm-module-summary-standard-equipment-list .nm-text-wrap {
	width: calc(100% - 42px); /* 24px = with info i plus 6px + 12px gutter */
}

/* TODO: cleanup after optionslist redesign - overwrites module.lists.css:235 */
.nm-module-summary-standard-equipment-list .nm-list-item-text {
	padding: 0;
}

.nm-module-summary-standard-equipment-list .nm-image-wrap {
	margin-top: 12px;
	width: 100%;
}

/* TODO: cleanup after optionslist redesign. Overwrites module:lists.css:1115 */
.nm-module-summary-standard-equipment-list img {
	margin-top: 0 !important;
	max-width: 100% !important;
	min-width: 360px !important;
	width: 100%;
}

/* wrapper class */
/* needed for aligning images vertically */
.nm-module-summary-standard-equipment-list .nm-info-wrap {
	margin-left: 12px;
	margin-right: 6px;
	min-width: 24px;
}

.nm-module-summary-standard-equipment-list .nm-link-information {
	display: block;
}

.nm-module-summary-standard-equipment-list .nm-icon-system-info-small {
	cursor: pointer;
	height: 2.4rem;
	width: 2.4rem;
}

/* main breakpoint */
@media all and (min-width: 768px) {
	.nm-module-summary-standard-equipment-list .nm-navigation {
		margin-top: 48px;
	}

	.nm-module-summary-standard-equipment-list .nm-equipment-list {
		margin-bottom: 48px;
	}

	.nm-module-summary-standard-equipment-list .nm-equipment-list-item {
		flex-flow: row nowrap;
	}

	.nm-module-summary-standard-equipment-list .nm-image-wrap {
		margin: 0 0 0 24px;
		order: 2;
	}

	.nm-module-summary-standard-equipment-list .nm-text-wrap ~ .nm-image-wrap {
		margin-right: calc(16% + 24px); /* case text and image are visible, 4 x 4% grid plus gutter controls the distance to info-i and size of the image */
		max-width: 175px;
	}

	/* TODO: all !important declarations can be deleted after refactoring the options list */
	.nm-module-summary-standard-equipment-list img {
		min-width: auto !important;
	}

	.nm-module-summary-standard-equipment-list .nm-info-wrap {
		order: 3;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-summary-standard-equipment-list .nm-navigation {
		margin-bottom: 42px;
		margin-top: 60px;
	}

	.nm-module-summary-standard-equipment-list .nm-equipment-list {
		margin-bottom: 60px;
	}
}

@media all and (min-width: 1440px) {
	.nm-module-summary-standard-equipment-list .nm-headline-main {
		margin-bottom: 36px;
	}

	.nm-module-summary-standard-equipment-list .nm-navigation-listitem:not(:last-child) {
		margin-right: 36px;
	}

	.nm-module-summary-standard-equipment-list .nm-navigation {
		margin-bottom: 54px;
		margin-top: 78px;
	}

	.nm-module-summary-standard-equipment-list .nm-equipment-list {
		margin-bottom: 78px;
	}
}

@media all and (min-width: 1920px) {
	.nm-module-summary-standard-equipment-list .nm-headline-main {
		margin-bottom: 42px;
	}

	.nm-module-summary-standard-equipment-list .nm-navigation-listitem:not(:last-child) {
		margin-right: 42px;
	}

	.nm-module-summary-standard-equipment-list .nm-navigation {
		margin-bottom: 66px;
		margin-top: 96px;
	}

	.nm-module-summary-standard-equipment-list .nm-equipment-list {
		margin-bottom: 96px;
	}
}
