.nm-basket .nm-basket-matching {
	background-color: #fff;
	box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
	max-height: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	transition: max-height .4s;
	transition-delay: 0s;
	width: 100%;
}

.nm-matching-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-matching-toggle-label .nm-icon-used-cars {
	display: none;
}

.nm-matching-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-matching-toggle-label .nm-icon-cross-large {
	display: block;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.nm-basket-matching-circle {
	position: absolute;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li .nm-basket-matching-car-container,
.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li > a {
	max-width: 260px;
	width: 100%;
}

.nm-basket .nm-basket-matching-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto 25px;
	position: relative;
	width: 92%;
}

.nm-basket .nm-basket-matching .nm-el-hdl-04 {
	margin: 20px auto 2px;
	padding-right: 0;
	position: relative;
	width: 92%;
}

.nm-matching-toggle:checked ~ .nm-basket-superwrap .nm-basket .nm-basket-matching {
	max-height: 1000px;
}

.nm-matching-toggle:checked ~ .nm-basket-superwrap .nm-basket .nm-basket-matching:hover {
	background-color: #fff;
}

.nm-basket-matching-headline {
	padding-right: 30px;
	position: relative;
}

.nm-basket .nm-basket-matching input {
	display: none;
}

.nm-basket .nm-basket-matching:hover {
	background-color: #d3d5d7;
}

/* ToCheck nm-el,label */
.nm-basket .nm-basket-matching-wrap > label,
.nm-basket .nm-basket-matching-wrap > .nm-el-hdl-04,
.nm-basket .nm-basket-matching .nm-basket-matching-list-small-wrap {
	display: block;
	max-height: 999px;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-car-info {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-bottom: 20px;
	margin-top: 10px;
	min-height: 50px;
	position: relative;
}

.nm-basket .nm-basket-matching .nm-basket-matching-list-small-wrap,
.nm-basket .nm-basket-matching .nm-basket-matching-more-btn {
	max-width: 290px;
	width: 100%;
}

.nm-basket .nm-basket-matching .nm-basket-matching-list-small-wrap {
	margin-bottom: 15px;
}

.nm-basket .nm-basket-matching .nm-basket-matching-more-btn a {
	max-width: none;
	width: 100%;
}

.nm-basket .nm-basket-matching .nm-basket-matching-more-btn a::after {
	display: none;
}

.nm-basket .nm-basket-matching .nm-basket-matching-more-btn a span {
	margin: 0 auto;
}

.nm-basket .nm-basket-matching .nm-basket-matching-more-btn a span::after {
	background: no-repeat center left url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAJCAYAAAD+WDajAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQwMjk3RDBGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQwMjk3RDFGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZGNUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZGNkZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhOroJcAAACGSURBVHjaYjzDYKzAwMBwngECHI3/n7kAZTMwATkPgHQhEAsA8f6zjCYKcEkQAVSwAEhNgCpYD1QAohkY////D1PIABQEGW8AxCCjHZkYUMEBKA0yWgCuE6grAGQkEH+AOYwJKgEyaj5UVyHMxYxAr4BdCbUrEeo4MGCBqQYZh+xHEAAIMACW6i2Fr+XVoQAAAABJRU5ErkJggg==");
	content: "";
	margin-left: 7px;
	padding-left: 7px;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container {
	position: relative;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container ul {
	display: flex;
	position: relative;
	transform: translateX(0);
	transition: all .4s ease;
	width: 600%;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container ul li {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	margin-bottom: 23px;
	padding: 0 15px;
	position: relative;
	width: calc(100% / 6);
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container ul li label {
	background-position: top left;
	background-repeat: no-repeat;
	cursor: pointer;
	display: none;
	height: 19px;
	position: absolute;
	top: calc(50% - 10px);
	width: 11px;
	z-index: 10;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container ul li label.nm-basket-matching-prev {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAATCAYAAABGKffQAAAAAXNSR0IArs4c6QAAActpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx4bXA6Q3JlYXRvclRvb2w+QWRvYmUgSW1hZ2VSZWFkeTwveG1wOkNyZWF0b3JUb29sPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KKS7NPQAAAENJREFUKBVjYCAOJACVvSdGKUjhfyAG0XhBAlB2VCHOIFKABs98nCrQJBKgGkA0USABqIqoCICZNqoBFhL4aFAovQcAdZYgTfTleZIAAAAASUVORK5CYII=");
	left: 0;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container ul li label.nm-basket-matching-next {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAATCAYAAABGKffQAAAAAXNSR0IArs4c6QAAActpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx4bXA6Q3JlYXRvclRvb2w+QWRvYmUgSW1hZ2VSZWFkeTwveG1wOkNyZWF0b3JUb29sPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KKS7NPQAAAD5JREFUKBVjZmBgeA/EL4H4AhATBAlAFf+BGEQTBRKAqkY1EAoqkkNpPjRYFahmMtFOGJkKQeEMyikg3xMEAJDFIalrapkIAAAAAElFTkSuQmCC");
	right: 0;
}

.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(1):checked ~ .nm-basket-matching-wrap .nm-basket-matching-car-container ul li:nth-child(1) label,
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(2):checked ~ .nm-basket-matching-wrap .nm-basket-matching-car-container ul li:nth-child(2) label,
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(3):checked ~ .nm-basket-matching-wrap .nm-basket-matching-car-container ul li:nth-child(3) label,
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(4):checked ~ .nm-basket-matching-wrap .nm-basket-matching-car-container ul li:nth-child(4) label,
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(5):checked ~ .nm-basket-matching-wrap .nm-basket-matching-car-container ul li:nth-child(5) label,
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(6):checked ~ .nm-basket-matching-wrap .nm-basket-matching-car-container ul li:nth-child(6) label {
	display: block;
}

.nm-basket .nm-basket-matching .nm-basket-matching-counter {
	color: #b3b3b3;
	margin-bottom: 0;
	position: relative;
	z-index: 1;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container .nm-basket-matching-car-container {
	height: 0;
	margin-bottom: 10px;
	padding-bottom: 56.25%;
	position: relative;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container .nm-basket-matching-nav {
	height: 100%;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container .nm-basket-matching-nav a {
	cursor: pointer;
	display: block;
	height: 19px;
	width: 15px;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container .nm-basket-matching-car-container .nm-basket-matching-car-image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	float: left;
	height: 0;
	padding-bottom: 56.25%;
	width: 100%;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container .nm-basket-matching-car-info {
	min-height: 50px;
	position: relative;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container ul li .nm-basket-matching-car-info > span {
	box-sizing: border-box;
	display: block;
	margin-bottom: 4px;
	padding-right: 80px;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-container ul li .nm-basket-matching-car-info > span.nm-basket-matching-car-model {
	font-weight: 700;
}

.nm-basket .nm-basket-matching .nm-basket-matching-list-small {
	height: 0;
	overflow: hidden;
	padding-bottom: 18.75%;
	position: relative;
}

.nm-basket .nm-basket-matching .nm-basket-matching-list-small ul {
	display: flex;
	justify-content: space-between;
	left: 0;
	position: absolute;
	top: 0;
	transition: all .4s ease;
	width: 200%;
}

.nm-basket .nm-basket-matching .nm-basket-matching-list-small ul li {
	border: 1px solid transparent;
	box-sizing: border-box;
	width: calc((100% - 25px) / 6);
}

.nm-basket .nm-basket-matching .nm-basket-matching-list-small ul li label {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
	display: block;
	height: 0;
	padding-bottom: 56.25%;
}

.nm-basket .nm-basket-matching .nm-basket-matching-list-small ul li label.nm-basket-matching-car-fallback,
.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li .nm-basket-matching-car-image.nm-basket-matching-car-fallback {
	background-image: url("../assets-stockcars/fallback/n5c01.png");
}

.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(1):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(1),
.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(2):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(2),
.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(3):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(3),
.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(4):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(4),
.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(5):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(5),
.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(6):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(6) {
	background-color: #f2f2f2;
	border: 1px solid #d0d3d4;
}

.nm-basket .nm-basket-matching > input:nth-child(1):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul {
	left: 0;
}

.nm-basket .nm-basket-matching > input:nth-child(2):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul {
	left: 0;
}

.nm-basket .nm-basket-matching > input:nth-child(3):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul {
	left: calc(100% / -3);
}

.nm-basket .nm-basket-matching > input:nth-child(4):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul {
	left: calc(100% / -1.5);
}

.nm-basket .nm-basket-matching > input:nth-child(5):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul {
	left: calc(100% / -1);
}

.nm-basket .nm-basket-matching > input:nth-child(6):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul {
	left: calc(100% / -1);
}

.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching,
.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching:hover {
	background-color: transparent;
}

.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching .nm-basket-matching-wrap > *:not(.nm-basket-toggle-widgets-label) {
	display: block;
	max-height: 500px;
	transition: max-height .4s ease-out;
	transition-delay: .5s;
}

.nm-basket .nm-basket-toggle-widgets:checked ~ .nm-basket-matching .nm-basket-matching-wrap .nm-basket-matching-list-small {
	margin-bottom: 15px;
}

.nm-basket-matching-car-slider {
	border-radius: 4px;
	margin: 0 auto 25px;
	position: relative;
	width: 100%;
}

.nm-basket-matching-car-slider ul {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.nm-basket-matching-car-slider ul li {
	display: inline-block;
	margin: 0;
	padding: 0 15px;
	position: relative;
	width: calc(100% / 6);
}

.nm-basket-matching-car-slider ul li .nm-basket-matching-car-image {
	background-repeat: no-repeat;
	background-size: cover;
	box-sizing: border-box;
	height: 0;
	padding-bottom: 56.25%;
	width: 100%;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-prev,
.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-next {
	background-position: left center;
	background-repeat: no-repeat;
	cursor: pointer;
	display: block;
	height: 75px;
	position: absolute;
	top: 25%;
	width: 11px;
	z-index: 999;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-prev {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAATCAYAAABGKffQAAAAAXNSR0IArs4c6QAAActpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx4bXA6Q3JlYXRvclRvb2w+QWRvYmUgSW1hZ2VSZWFkeTwveG1wOkNyZWF0b3JUb29sPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KKS7NPQAAAENJREFUKBVjYCAOJACVvSdGKUjhfyAG0XhBAlB2VCHOIFKABs98nCrQJBKgGkA0USABqIqoCICZNqoBFhL4aFAovQcAdZYgTfTleZIAAAAASUVORK5CYII=");
	left: 0;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-next {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAATCAYAAABGKffQAAAAAXNSR0IArs4c6QAAActpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx4bXA6Q3JlYXRvclRvb2w+QWRvYmUgSW1hZ2VSZWFkeTwveG1wOkNyZWF0b3JUb29sPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KKS7NPQAAAD5JREFUKBVjZmBgeA/EL4H4AhATBAlAFf+BGEQTBRKAqkY1EAoqkkNpPjRYFahmMtFOGJkKQeEMyikg3xMEAJDFIalrapkIAAAAAElFTkSuQmCC");
	right: 0;
}

.nm-basket-matching-car-slider .slide-transition {
	transition: all .4s ease;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li .nm-basket-matching-car-info .nm-el-lbl {
	box-sizing: border-box;
	display: inline-block;
	margin-bottom: 4px;
	padding-right: 65px;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li .nm-basket-matching-car-info .nm-el-lbl.nm-basket-matching-car-model {
	display: inline;
	font-weight: 700;
	position: relative;
}

.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li .nm-basket-matching-car-info .nm-el-lbl.nm-basket-matching-car-model::after {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAALCAYAAABPhbxiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QTFDQkU5Qzc3NTNBMTFFMkExN0JGRkRDNUU4QjQzMEUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QTFDQkU5Qzg3NTNBMTFFMkExN0JGRkRDNUU4QjQzMEUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBMUNCRTlDNTc1M0ExMUUyQTE3QkZGREM1RThCNDMwRSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBMUNCRTlDNjc1M0ExMUUyQTE3QkZGREM1RThCNDMwRSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmlDMQkAAAEMSURBVHjabNLNSgJRGMbx49BGK8FlGzcFEUQboVVXIZUQFZILceENVEqQJm0DayNJu77beQeBkLtGMqJriMASd/1feILD4IEfc5jzPuedOTOxnsvknHMXSKGCOpI4xxbeYDWvzhsBblDCD45xgG8U8YAlPGIlGnQK5zFS+BBD7OIOC7j1w4G3yT32Ip1/UcATFtV5ORq0ca3wF2qoYoxtrc2rwWqMw3ETxhnKmq/hGWl0MYdOMCG0iR3N7ZRfkMCpQgMcTUVCduwtzGAfDc2vkMUn1tH3O9rCpQorCsW1ka2969q34v+Otktbj2SneYJp3dvAh2pC/3Nk9efE9f0sNIumQqFqQv+d/gQYAEgEOrpjGgfPAAAAAElFTkSuQmCC");
	background-position: top left;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	height: 11px;
	margin-left: 5px;
	width: 14px;
}

/* SLIDER CIRCLE
============================================================================= */
.nm-basket-matching-circle {
	background: #d0d3d4;
	border-radius: 50%;
	height: 50px;
	position: absolute;
	right: 0;
	top: 0;
	width: 50px;
}

.nm-basket-matching-circle .mask {
	clip: rect(0px, 50px, 50px, 25px);
}

.nm-basket-matching-circle .mask, .nm-basket-matching-circle .fill {
	border-radius: 50%;
	transition: transform 3s;
}

.nm-basket-matching-circle .mask, .nm-basket-matching-circle .fill {
	border-radius: 50%;
	height: 50px;
	position: absolute;
	width: 50px;
}

.nm-basket-matching-circle .mask .fill {
	background-color: var(--color-progressive-red);
	clip: rect(0px, 25px, 50px, 0px);
}

.nm-basket-matching-circle .inner-circle {
	background-color: #fff;
	border-radius: 50%;
	font-family: AudiTypeExtended;
	font-size: 1rem;
	font-weight: var(--font-weight-bold);
	height: 40px;
	line-height: 40px;
	margin-left: 5px;
	margin-top: 5px;
	position: absolute;
	text-align: center;
	width: 40px;
	z-index: 2;
}

.nm-basket-matching-circle .outer-circle {
	border: 10px solid #fff;
	border-radius: 50%;
	bottom: -10px;
	left: -10px;
	position: absolute;
	right: -10px;
	top: -10px;
	z-index: 1;
}

.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(1):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(1),
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(2):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(2),
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(3):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(3),
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(4):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(4),
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(5):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(5),
.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(6):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(6) {
	background-color: #f2f2f2;
	border: 1px solid #d0d3d4;
}

.nm-basket-matching .nm-basket-toggle-widgets-label .nm-icon-arrow {
	display: none;
}

body:not(.nm-has-basket) .nm-basket-navigation .nm-matching-toggle-label {
	display: none;
}

@media all and (min-width: 600px) {
	.nm-basket .nm-basket-matching-wrap {
		justify-content: space-between;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-list-small-wrap {
		margin-bottom: 0;
		margin-left: 4%;
		max-width: 430px;
		width: 65%;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li .nm-basket-matching-car-container {
		max-height: 530px;
		max-width: none;
		width: 48%;
	}

	.nm-basket-matching-car-slider ul li > a {
		width: 28%;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li .nm-basket-matching-car-info .nm-el-lbl {
		padding-right: 0;
	}

	.nm-basket-matching-circle {
		margin-top: 10px;
		position: relative;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-car-info {
		justify-content: center;
		margin-top: 0;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-prev,
	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-next {
		top: calc(50% - 10px);
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-more-btn {
		align-self: flex-end;
		width: 28%;
	}
}

@media all and (min-width: 768px) {
	.nm-matching-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-matching-toggle-label .nm-basket-navigation-item-label {
		display: block;
	}
}

@media all and (min-width: 1100px) {
	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-prev,
	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-next {
		top: 25%;
	}

	.nm-basket .nm-basket-toggle-widgets:not(:checked) ~ .nm-basket-matching .nm-basket-matching-list-small ul li label {
		pointer-events: none;
	}

	/* ToCheck nm-el */
	.nm-basket .nm-basket-matching .nm-el-hdl-04 {
		display: block;
		margin: 0 0 10px;
		width: calc(100% - 30px);
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-list-small-wrap {
		margin: 0;
	}

	.nm-basket-matching-headline .nm-icon-arrow {
		display: block;
		height: 24px;
		position: absolute;
		right: 0;
		top: 0;
		transform: rotate(-90deg);
		transition: transform .2s ease-out;
		width: 24px;
	}

	.nm-basket-matching-headline .nm-icon-arrow {
		transform: rotate(90deg);
	}

	.nm-basket-toggle-widgets:checked ~ .nm-basket-matching .nm-basket-matching-headline .nm-icon-arrow {
		transform: rotate(-90deg);
	}

	.nm-basket .nm-basket-matching {
		background-color: #e7e9ea;
		border: 1px solid #d0d3d4;
		box-shadow: none;
		box-sizing: border-box;
		max-height: none;
		overflow: hidden;
		padding: 12px 15px 15px;
		position: relative;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-more-btn {
		box-sizing: border-box;
		max-width: none;
		text-align: center;
		width: 100%;
	}

	.nm-basket-navigation label.nm-matching-toggle-label {
		display: none;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li .nm-basket-matching-car-container,
	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li > a {
		max-width: 260px;
		width: 100%;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-list-small-wrap,
	.nm-basket .nm-basket-matching .nm-basket-matching-more-btn {
		max-width: 290px;
		width: 100%;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-wrap {
		justify-content: center;
		margin-bottom: 20px;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider .nm-basket-matching-car-info {
		justify-content: flex-start;
		padding-right: 65px;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-counter {
		left: 0;
		position: absolute;
		top: 0;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider ul li > a .nm-basket-matching-car-info > span {
		padding-right: 65px;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-wrap {
		margin: 0;
		width: 100%;
	}

	.nm-basket-matching-circle {
		margin-top: 0;
		position: absolute;
	}

	.nm-basket .nm-basket-matching .nm-basket-matching-car-slider,
	.nm-basket .nm-basket-matching .nm-basket-matching-more-btn {
		max-height: 0;
		overflow: hidden;
	}

	.nm-basket .nm-basket-matching {
		transition: background-color .4s;
	}

	.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(1):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(1),
	.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(2):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(2),
	.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(3):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(3),
	.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(4):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(4),
	.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(5):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(5),
	.nm-basket .nm-basket-matching > input.nm-basket-matching-list-slider:nth-child(6):checked ~ .nm-basket-matching-wrap .nm-basket-matching-list-small-wrap ul li:nth-child(6) {
		background-color: transparent;
		border: none;

	}

	.nm-matching-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-matching-toggle-label .nm-icon-used-cars {
		display: block;
	}

	.nm-matching-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-matching-toggle-label .nm-icon-cross-large {
		display: none;
	}

	.nm-basket-matching .nm-basket-toggle-widgets-label .nm-icon-arrow {
		display: block;
		height: 24px;
		position: absolute;
		right: 0;
		top: 0;
		transform: rotate(90deg);
		transition: transform .2s ease-out;
		width: 24px;
	}

	.nm-basket-toggle-widgets:checked + .nm-basket-content + .nm-basket-matching .nm-basket-toggle-widgets-label .nm-icon-arrow {
		transform: rotate(-90deg);
	}

	.nm-basket .nm-basket-toggle-widgets:not(:checked) ~ .nm-basket-matching .nm-basket-toggle-widgets-label-fullarea {
		display: block;
	}
}
