/* TODO: TecDebt: change classnames */

/* configuration start layer */

.nm-simpleform.nm-start-layer {
	margin-bottom: 0;
}

.nm-start-layer .nm-start-information-overview {
	float: right;
	margin-bottom: 30px;
	overflow: hidden;
	width: -webkit-calc(48% - 40px);
	width: calc(48% - 40px);
}

.nm-start-layer .nm-start-inforamation-content {
	margin-bottom: 40px;
}

.nm-start-layer h2 ~ p {
	margin-bottom: 38px;
}

.nm-start-layer img.nm-unscaled-image {
	float: left;
	margin-left: -40px;
	margin-top: -30px;
	max-width: 62%;
	width: auto;
}

.nm-start-layer .nm-start-information-overview span.nm-el-hdl-04 {
	margin-bottom: 3px;
}

.nm-start-layer .nm-start-information-overview .nm-engine {
	display: block;
	margin-bottom: 19px;
}

.nm-start-layer .nm-start-information-overview .nm-car-price-total {
	margin-bottom: 3px;
}

.nm-start-layer .nm-car-price-basic-key,
.nm-start-layer .nm-car-price-equipment-key,
.nm-start-layer .nm-car-price-total-key {
	clear: both;
	float: left;
}

.nm-start-layer .nm-car-price-basic-value,
.nm-start-layer .nm-car-price-equipment-value,
.nm-start-layer .nm-car-price-total-value {
	float: left;
}

.nm-scope-no-prices .nm-car-price-equipment-value,
.nm-scope-no-prices .nm-car-price-total-value,
.nm-scope-no-prices .nm-conflict-layer-total-price {
	visibility: hidden;
}

@media (max-width: 749px) {
	.nm-state-is-responsive .nm-start-layer .nm-start-information-overview {
		float: left;
		position: relative;
		width: 100%;
	}

	.nm-start-layer .nm-start-inforamation-content {
		margin-bottom: 0;
		width: 50%;
	}

	.nm-state-is-responsive .nm-start-layer img.nm-unscaled-image {
		float: none;
		margin: -30px auto 0;
		max-width: 90%;
	}

	.nm-state-is-responsive .nm-start-layer.nm-configurationstart-loaded-layer .nm-btn-continue {
		bottom: 7px;
		max-width: 50%;
		position: absolute;
		right: 0;
	}
}

@media (max-width: 479px) {
	.nm-state-is-responsive .nm-start-layer img.nm-unscaled-image {
		margin: -30px auto 0;
		max-width: 70%;
	}

	.nm-state-is-responsive .nm-start-layer .nm-start-inforamation-content {
		margin-bottom: 10px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-start-layer .nm-btn-continue,
	.nm-state-is-responsive .nm-start-layer.nm-configurationstart-loaded-layer .nm-btn-continue {
		bottom: auto;
		margin-top: 5px;
		max-width: none;
		position: relative;
		right: auto;
	}

	.nm-state-is-responsive .nm-start-layer .nm-btn-continue,
	.nm-state-is-responsive .nm-start-layer.nm-configurationstart-loaded-layer .nm-btn-continue {
		float: left;
		margin-top: 0;
		max-width: 90%;
	}

	.nm-state-is-responsive .nm-start-layer .nm-start-information-overview span.nm-el-hdl-04,
	.nm-state-is-responsive .nm-start-layer .nm-start-information-overview .nm-car-price-total {
		margin-bottom: 0;
	}

	.nm-state-is-responsive .nm-start-layer .nm-start-information-overview .nm-engine {
		margin-bottom: 10px;
	}
}
