.nm-basket-navigation {
	background-color: #000;
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	height: 54px;
	justify-content: flex-end;
	margin-right: -15px;
	order: 3;
	padding-left: 6px;
	width: auto;
	z-index: 105;
}

.nm-main-navigation-alternative .nm-basket-navigation {
	background-color: #fff;
	padding-right: 0;
}

.nm-basket-navigation .nm-basket-navigation-item,
.nm-basket-navigation .nm-basket-navigation-summary-label {
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-flex;
	height: 54px;
	justify-content: center;
	padding: 0 6px;
	position: relative;
	z-index: 100;
}

.nm-basket-navigation .nm-basket-navigation-summary-label--active {
	pointer-events: none;
}

.nm-basket-navigation .nm-basket-navigation-summary-label--active .nm-basket-navigation-summary-label {
	cursor: default;
	font-weight: 700;
	text-decoration: underline;
}

.nm-basket-navigation.nm-hide-basket-icon .nm-basket-navigation-item {
	display: none;
}

.nm-basket-navigation .nm-basket-navigation-item::before {
	background-color: #333;
	content: "";
	height: 30px;
	left: 0;
	position: absolute;
	top: 12px;
	width: 1px;
}

.nm-basket-navigation .nm-basket-navigation-item:first-child::before {
	display: none;
}

.nm-basket-navigation .nm-basket-navigation-summary-label {
	padding: 0;
}

.nm-basket-navigation .nm-basket-navigation-summary-label .nm-basket-navigation-item-label {
	padding-right: 15px;
}

.nm-basket-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-basket-toggle-label .nm-icon-basket {
	display: none;
}

.nm-basket-navigation .nm-basket-navigation-item .nm-basket-navigation-item-label {
	display: none;
}

.nm-basket-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-basket-toggle-label .nm-icon-cross-large {
	display: block;
}

.nm-basket-navigation .nm-basket-navigation-item-icon {
	align-items: center;
	display: inline-flex;
	height: 54px;
	justify-content: center;
	width: 54px;
}

.nm-basket-navigation .nm-basket-navigation-item-icon .nm-icon-basket,
.nm-basket-navigation .nm-basket-navigation-item-icon .nm-icon-used-cars {
	height: 24px;
	stroke: #fff;
	width: 24px;
}

.nm-basket-navigation .nm-basket-navigation-item:hover .nm-icon-basket,
.nm-basket-navigation .nm-basket-navigation-item:hover .nm-icon-used-cars {
	stroke: #b3b3b3;
}

.nm-main-navigation-alternative .nm-basket-navigation .nm-basket-navigation-item-icon .nm-icon-basket,
.nm-main-navigation-alternative .nm-basket-navigation .nm-basket-navigation-item-icon .nm-icon-used-cars {
	stroke: #000;
}

.nm-basket-navigation .nm-basket-navigation-item-icon .nm-icon-cross-large {
	display: none;
	fill: #fff;
	height: 48px;
	width: 48px;
}

.nm-basket-navigation .nm-basket-navigation-item:hover .nm-icon-cross-large {
	fill: #b3b3b3;
}

.nm-main-navigation-alternative .nm-basket-navigation .nm-basket-navigation-item-icon .nm-icon-cross-large {
	fill: #000;
}

.nm-has-basket .nm-basket-navigation-summary-label {
	pointer-events: none;
}

@media all and (min-width: 768px) {
	.nm-basket-navigation {
		padding-left: 48px;
	}

	.nm-basket-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-basket-toggle-label .nm-basket-navigation-item-label {
		display: block;
	}
}

@media all and (min-width: 1024px) {
	.nm-basket-navigation {
		padding-left: 60px;
	}
}

@media all and (min-width: 1100px) {
	.nm-basket-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-icon-basket {
		display: block;
	}

	.nm-basket-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-icon-cross-large {
		display: none;
	}

	.nm-main-navigation-alternative .nm-basket-navigation-item-icon {
		display: none;
	}

	.nm-basket-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-basket-toggle-label .nm-icon-basket {
		display: block;
	}

	.nm-basket-toggle:checked ~ .nm-nav-wrap .nm-basket-navigation .nm-basket-toggle-label .nm-icon-cross-large {
		display: none;
	}

	.nm-basket-navigation .nm-basket-navigation-item .nm-basket-navigation-item-label {
		display: block;
	}

	.nm-has-basket .nm-basket-navigation-summary-label {
		pointer-events: all;
	}

	.nm-has-basket .nm-basket-navigation-summary-label--active .nm-basket-navigation-summary-label {
		pointer-events: none;
	}
}

@media all and (min-width: 1440px) {
	[page-type=widescreen] .nm-basket-navigation {
		padding-left: 78px;
	}
}

@media all and (min-width: 1920px) {
	[page-type=widescreen] .nm-basket-navigation {
		padding-left: 96px;
	}
}
