/* -----------------------------------------------------------------------

		Configurator modules
		Tiles
		option tiles, i.e. seats, headlights, steering wheels
		wheels, interior, exterior pattern

------------------------------------------------------------------------- */

.nm-list-item {
	display: inline-block;
	margin-left: 7%;
	vertical-align: top;
	width: 42.66666666%;
}

.nm-list-item.nm-j-configurator-container-hide {
	display: none;
}

/* option tiles - (ok) */

.nm-ok {
	margin-bottom: 50px;
}

.nm-ok .nm-j-configurator-item {
	position: relative;
}

.nm-ok-image {
	margin: 0 0 30px;
	max-width: 350px;
	width: 100%;
}

.nm-md-options-pattern-block .nm-ok-image {
	margin: 0 0 10px;
}

.nm-ok-image.nm-var {
	margin: 0 auto 30px;
	max-width: 200px;
	width: 46.705%;
}

.nm-ok .nm-checkbox-1,
.nm-ok .nm-checkbox-2,
.nm-ok .nm-checkbox-3 {
	float: left;
	margin: 0 10px 0 1px; /* 1px leftmargin ff */
	vertical-align: top;
}

.nm-ok-label {
	box-sizing: border-box;
	display: inline-block;
	float: left;
	max-width: 63%;
	overflow: hidden;
	position: relative;
}

.nm-ok .nm-conflict-icon {
	display: inline-block;
	left: 40px; /* overwrites the default of 30px */
}

.nm-ok .nm-conflict-icon:hover + .nm-conflict-tooltip {
	left: -165px;
}

.nm-ok .nm-info {
	display: inline-block;
	float: left;
	margin: 0 5px 0 10px;
}

.nm-ok .nm-el-pg.nm-el-pg-04.nm-at-pg-b {
	display: block;
	float: left;
	margin-bottom: 0;
}

.nm-ok .nm-price {
	clear: both;
	display: block;
	font-weight: 400;
	margin: -3px 0 10px;
}

.nm-md-options-pattern-block .nm-ok .nm-price {
	margin: -3px 0 24px;
}

.nm-scope-no-prices .nm-md-options-pattern-block .nm-ok .nm-price {
	visibility: hidden;
}

.nm-ok-text {
	margin: 0 0 30px;
}

.nm-c9-note .nm-c8 {
	margin-right: 2px;
}

.nm-c9-note {
	clear: both;
	display: inline-block;
	margin: 5px 0;
}

/* Option type - i.e. seats, lights (otp) */
.nm-otp img {
	display: block;
	float: left;
	margin-bottom: 20px;
	max-width: 160px;
	min-width: 133px;
	width: 49.5%; /* 160px/320px = 0,5 */
}

.nm-otp-text {
	float: left;
	vertical-align: top;
	width: 49.5%;
}

.nm-otp-list li {
	background: no-repeat left 5px url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKAgMAAAASgDxXAAAAA3NCSVQICAjb4U/gAAAACVBMVEX////MADPUJ1IvxT+MAAAAA3RSTlMAIv9wxm1zAAAACXBIWXMAAAsSAAALEgHS3X78AAAAGHRFWHRDcmVhdGlvbiBUaW1lADAzLjA3LjIwMTMcjJsiAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M1cbXjNgAAADBJREFUCJkVyCEBACEABLBh0NgnDWFQpCEEhpZ/TA6fWINyafUw++a+Wik9paaU1A+vmgcxjaE32AAAAABJRU5ErkJggg==");
	margin-bottom: 6px;
	padding-left: 21px;
}

/* Wheels, exterior, interior colours
======================================================================/

/* Module: Selection Pattern (Modul_AKD) */
.nm-tile-block {
	margin: 0;
	overflow: visible;
	position: relative;
	z-index: 0;
}

.nm-conflict-solution .nm-tile-block {
	margin-bottom: 62px;
}

.nm-tile-block strong {
	display: inline-block;
	margin-right: 5px;
}

.nm-tile-block .nm-info-wrap {
	vertical-align: middle;
}

.nm-tile-block .nm-tile-list {
	clear: left; /* clears headline and info-i */
	overflow: visible;
	padding: 0 0 90px;
	width: 102.666666666667%; /* 770/750  [750px width + tile (12px margin for the last tile + 2px padding + 6px border)] */
}

.nm-tile-block .nm-tile-list li {
	float: left;
	margin: 0 2px 4px -4px;
	width: 56px;
}

.nm-md-tiles[data-render-view="wheels"] .nm-tile-block .nm-tile-list li {
	width: 96px;
}

.nm-tile-block .nm-tile-element-image {
	float: left;
	position: relative;
}

.nm-tile-block .nm-tile-element-image img {
	width: 100%;
}

.nm-tile-block .nm-j-configurator-status_10010 .nm-tile-element-image a {
	border: 3px solid transparent;
}

.nm-pattern-tile .nm-j-configurator-status_10010 .nm-tile-element-image a::after {
	display: none;
}

.nm-tile-group-info {
	background: #fff;
	bottom: 0;
	display: block;
	height: 75px;
	left: 0;
	position: absolute;
	width: 100%;
	z-index: 6;
}

.nm-tile-element-info {
	background: #fff;
	bottom: 0;
	display: block;
	height: 75px;
	left: 0;
	overflow: hidden;
	position: absolute;
	width: 100%;
}

.nm-tile-element-info .nm-info {
	display: inline-block;
	vertical-align: middle;
}

.nm-tile-element-info .nm-non-renderable {
	display: block;
}

.nm-tile-element-info p,
.nm-tile-element-info span,
.nm-tile-group-info p,
.nm-tile-group-info span {
	margin-bottom: 0;
}

.nm-tile-element-info p {
	display: inline;
}

.nm-conflict-solution .nm-tile-element-info {
	bottom: -60px;
	height: 150px;
}

/* NOTE: Keep the list selectors for flawless hover */

li.nm-j-configurator-status_11010 .nm-tile-element-info,
li.nm-j-configurator-status_10010 .nm-tile-element-info,
li.nm-j-configurator-status_11000 .nm-tile-element-info,
li.nm-j-configurator-status_10011 .nm-tile-element-info {
	z-index: 5;
}

li.nm-j-configurator-status_11010 .nm-tile-element-info,
li.nm-j-configurator-status_10010 .nm-tile-element-info,
li.nm-j-configurator-status_10011 .nm-tile-element-info {
	z-index: 9;
}

.nm-tile-list li:hover .nm-tile-element-info {
	z-index: 10;
}

.nm-tile-group-info .nm-price,
.nm-tile-element-info .nm-price {
	display: block;
}

.nm-scope-no-prices .nm-tile-group-info .nm-price,
.nm-scope-no-prices .nm-tile-element-info .nm-price {
	visibility: hidden;
}

.nm-tile-element-image .nm-conflict-icon {
	left: auto; /* overwrites default left: 30px */
	margin-left: 10px;
	position: absolute;
	right: 3px;
	top: 3px;
	z-index: 10;
}

.nm-md-tiles[data-render-view="wheels"] .nm-tile-element-image .nm-conflict-icon {
	right: -5px;
	top: -5px;
}

.nm-tile-element-image .nm-conflict-icon:hover + .nm-conflict-tooltip {
	bottom: auto;
	left: 36px;
	right: auto;
	top: -85px;
}

.nm-md-tiles[data-render-view="wheels"] .nm-tile-element-image .nm-conflict-icon:hover + .nm-conflict-tooltip {
	left: 82px;
	top: -90px;
}

.nm-tile-element-image.nm-wheels .nm-conflict-icon:hover + .nm-conflict-tooltip {
	bottom: 80px;
	left: 130px;
	top: auto;
}

/* different positioning for conflict icons */
.nm-wheels .nm-conflict-icon {
	right: -2px;
	top: -2px;
	z-index: 10;
}

.nm-tile-block .nm-tile-element-image a {
	border: 2px solid transparent;
	display: block;
	padding: 1px;
	position: relative;
}

.nm-tile-block .nm-j-configurator-item-loading .nm-tile-element-image a,
.nm-tile-block .nm-j-configurator-item-loading .nm-tile-element-image a:hover {
	border: 2px solid #ccc;
}

.nm-tile-block li.nm-j-configurator-status_11010 .nm-tile-element-image a,
.nm-tile-block li.nm-j-configurator-status_10010 .nm-tile-element-image a,
.nm-tile-block li.nm-j-configurator-status_10011 .nm-tile-element-image a,
.nm-tile-block li.nm-j-configurator-status_11010 .nm-tile-element-image a:hover,
.nm-tile-block li.nm-j-configurator-status_10010 .nm-tile-element-image a:hover,
.nm-tile-block li.nm-j-configurator-status_11000 .nm-tile-element-image a:hover,
.nm-tile-block li.nm-j-configurator-status_11001 .nm-tile-element-image a:hover {
	border: 2px solid var(--color-progressive-red);
}

/* conflict */
.nm-tile-block li.nm-j-configurator-status_11101 .nm-tile-element-image a:hover,
.nm-tile-block li.nm-j-configurator-status_10101 .nm-tile-element-image a:hover,
.nm-tile-block li.nm-j-configurator-status_11100 .nm-tile-element-image a:hover,
.nm-tile-block li.nm-j-configurator-status_10100 .nm-tile-element-image a:hover {
	border: 2px solid #8a9395;
}

/* checked and choosen */
.nm-tile-block li.nm-j-configurator-status_11010 .nm-tile-element-image a::after,
.nm-tile-block li.nm-j-configurator-status_10010 .nm-tile-element-image a::after,
.nm-tile-block li.nm-j-configurator-status_10011 .nm-tile-element-image a::after {
	background: var(--color-progressive-red) no-repeat 10px 10px url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJAQMAAAA8dl8XAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAYdEVYdENyZWF0aW9uIFRpbWUAMjkuMDMuMjAxMxbGg5cAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAAAI0lEQVQImWNgUGBgKGBgeMDAeIDBuYHhOQNDHQODDQODBAMAQu8ErolmuY0AAAAASUVORK5CYII=");
	border-radius: 15px;
	bottom: -10px;
	content: " ";
	height: 30px;
	position: absolute;
	right: -6px;
	width: 30px;
	z-index: 100;
}

.nm-tile-block li.nm-j-configurator-status_11010[data-configurator-group]:hover .nm-tile-element-image a::after,
.nm-tile-block li.nm-j-configurator-status_10010[data-configurator-group]:hover .nm-tile-element-image a::after,
.nm-tile-block li.nm-j-configurator-status_10011[data-configurator-group]:hover .nm-tile-element-image a::after {
	background: var(--color-progressive-red) no-repeat 10px 10px url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJAQMAAAA8dl8XAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAYdEVYdENyZWF0aW9uIFRpbWUAMjkuMDMuMjAxMxbGg5cAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAAAI0lEQVQImWNgUGBgKGBgeMDAeIDBuYHhOQNDHQODDQODBAMAQu8ErolmuY0AAAAASUVORK5CYII=");
}

.nm-tile-block li.nm-j-configurator-status_11010:hover .nm-tile-element-image a::after,
.nm-tile-block li.nm-j-configurator-status_10010:hover .nm-tile-element-image a::after,
.nm-tile-block li.nm-j-configurator-status_10011:hover .nm-tile-element-image a::after {
	background: var(--color-progressive-red) no-repeat 0 0 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzRBM0Y2NzMwMEE3MTFFNEE2QUJDMEM4OUJFOTA5Q0YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzRBM0Y2NzQwMEE3MTFFNEE2QUJDMEM4OUJFOTA5Q0YiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NDVBOTE1MDAwQTcxMUU0QTZBQkMwQzg5QkU5MDlDRiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDNEEzRjY3MjAwQTcxMUU0QTZBQkMwQzg5QkU5MDlDRiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpzfB+kAAAA9SURBVHja7NVBCgAgCABBjf7/ZftCXbJg9iwMgmBWVXQ0oikwGAwGvw/PzbnTF5bfb5yOCwwGg8HXWgIMAJ6tBD6ipzREAAAAAElFTkSuQmCC");
}

.nm-layer-conflict .nm-tile-block li.nm-j-configurator-status_11010:hover .nm-tile-element-image a::after,
.nm-layer-conflict .nm-tile-block li.nm-j-configurator-status_10010:hover .nm-tile-element-image a::after,
.nm-layer-conflict .nm-tile-block li.nm-j-configurator-status_10011:hover .nm-tile-element-image a::after {
	background: var(--color-progressive-red) no-repeat 10px 10px url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJAQMAAAA8dl8XAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAYdEVYdENyZWF0aW9uIFRpbWUAMjkuMDMuMjAxMxbGg5cAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAAAI0lEQVQImWNgUGBgKGBgeMDAeIDBuYHhOQNDHQODDQODBAMAQu8ErolmuY0AAAAASUVORK5CYII=");
}

/* options pattern block */
.nm-module.nm-md-options-pattern-block {
	margin-bottom: 0;
}

.nm-md-options-pattern-block .nm-ok {
	margin-bottom: 0;
}

.nm-md-options-pattern-block .nm-ok .nm-el-pg.nm-el-pg-04.nm-at-pg-b {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.nm-md-options-pattern-block .nm-el-hdl-03 {
	margin-bottom: 15px;
}

.nm-md-options-pattern-block .nm-otp-list li {
	margin-bottom: 12px;
}

.nm-md-options-pattern-block .nm-ok-text {
	margin-bottom: 23px;
}

.nm-md-options-pattern-block .nm-ok-image.nm-var {
	margin: 0 auto 10px;
}

.nm-md-options-pattern-block .nm-module-list {
	margin-left: 0;
}

.nm-md-options-pattern-block .nm-list-item {
	margin-bottom: 59px;
	margin-left: 0;
	margin-right: 3.4%;
	width: 30.37%;
}

.nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(3),
.nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(6),
.nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(9) {
	margin-right: 0;
}

/* tiles */
.nm-module.nm-md-tiles {
	margin-bottom: 24px;
}

.nm-module.nm-md-teaser + .nm-module.nm-md-tiles {
	margin-top: -46px;
}

.nm-md-tiles h2 {
	margin-bottom: 18px;
}

.nm-md-tiles strong {
	margin-bottom: 9px;
}

/*
	tile "Q0Q0" should be shown as chosen in module exclusive tiles, when no rendered color is selected
	more color codes: O0O0, BODY_Q0, BODY_O0
*/

.nm-md-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="Q0Q0"] .nm-j-configurator-delegate-click::after,
.nm-md-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="O0O0"] .nm-j-configurator-delegate-click::after,
.nm-md-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="BODY_Q0"] .nm-j-configurator-delegate-click::after,
.nm-md-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="BODY_O0"] .nm-j-configurator-delegate-click::after {
	display: none;
}

.nm-md-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="Q0Q0"] .nm-j-configurator-delegate-click,
.nm-md-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="O0O0"] .nm-j-configurator-delegate-click,
.nm-md-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="BODY_Q0"] .nm-j-configurator-delegate-click,
.nm-md-tiles-exclusive .nm-j-configurator-status_11010[data-configurator-id="BODY_O0"] .nm-j-configurator-delegate-click {
	border: none;
}

.nm-md-tiles-exclusive[data-tile-selected="Q0Q0"] .nm-j-configurator-status_11010[data-configurator-id="Q0Q0"] .nm-j-configurator-delegate-click::after,
.nm-md-tiles-exclusive[data-tile-selected="O0O0"] .nm-j-configurator-status_11010[data-configurator-id="O0O0"] .nm-j-configurator-delegate-click::after
.nm-md-tiles-exclusive[data-tile-selected="BODY_Q0"] .nm-j-configurator-status_11010[data-configurator-id="BODY_Q0"] .nm-j-configurator-delegate-click::after
.nm-md-tiles-exclusive[data-tile-selected="BODY_O0"] .nm-j-configurator-status_11010[data-configurator-id="BODY_O0"] .nm-j-configurator-delegate-click::after {
	display: block;
}

.nm-md-tiles-exclusive[data-tile-selected="Q0Q0"] .nm-j-configurator-status_11010[data-configurator-id="Q0Q0"] .nm-j-configurator-delegate-click,
.nm-md-tiles-exclusive[data-tile-selected="O0O0"] .nm-j-configurator-status_11010[data-configurator-id="O0O0"] .nm-j-configurator-delegate-click,
.nm-md-tiles-exclusive[data-tile-selected="BODY_Q0"] .nm-j-configurator-status_11010[data-configurator-id="BODY_Q0"] .nm-j-configurator-delegate-click,
.nm-md-tiles-exclusive[data-tile-selected="BODY_O0"] .nm-j-configurator-status_11010[data-configurator-id="BODY_O0"] .nm-j-configurator-delegate-click {
	border: 3px solid var(--color-progressive-red);
}

.nm-state-is-debug .nm-tile-list {
	position: relative;
}

@media all and (max-width: 1279px) {
	.nm-2col .nm-md-options-pattern-block .nm-list-item {
		margin-bottom: 59px;
		margin-left: 0;
		margin-right: 3%;
		width: 48%;
	}

	.nm-2col .nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(2),
	.nm-2col .nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(4),
	.nm-2col .nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(6),
	.nm-2col .nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(8) {
		margin-right: 0;
	}
}

@media all and (max-width: 749px) {
	.nm-state-is-responsive .nm-tile-block .nm-el-hdl {
		max-width: calc(100% - 32px);
	}

	.nm-tile-block .nm-info-wrap {
		vertical-align: top;
	}
}

@media all and (max-width: 519px) {
	.nm-state-is-responsive .nm-2col .nm-md-options-pattern-block .nm-list-item,
	.nm-state-is-responsive .nm-md-options-pattern-block .nm-list-item {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}

	.nm-state-is-responsive .nm-2col .nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(2),
	.nm-state-is-responsive .nm-2col .nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(3),
	.nm-state-is-responsive .nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(2),
	.nm-state-is-responsive .nm-md-options-pattern-block .nm-list-item.nm-j-configurator-container-show:nth-child(3) {
		margin-right: 0;
	}

	.nm-state-is-responsive .nm-md-options-pattern-block .nm-ok-label {
		max-width: calc(100% - 100px); /* make space for conflict and info icon */
	}
}

@media all and (max-width: 464px) {
	.nm-state-is-responsive .nm-tile-group-info,
	.nm-state-is-responsive .nm-tile-element-info {
		height: 95px;
	}

	.nm-state-is-responsive .nm-tile-block .nm-tile-list {
		padding-bottom: 100px;
	}
}
