/*  media library configuration
============================================================================= */

.nm-md-media-library-configuration {
	margin-bottom: 60px;
}

.nm-md-media-library-configuration h1 {
	margin-bottom: 16px;
}

.nm-md-media-library-configuration p {
	margin-bottom: 17px;
}

.nm-md-media-library-configuration-buttons > a {
	float: left;
	margin-bottom: 20px;
	margin-right: 20px;
}
