.nm-layer-wrapper-equipment-search-layer {
	z-index: 101;
}

.nm-md-equipment-search {
	box-sizing: border-box;
	margin-top: 15px;
	min-height: 100vh;
	padding: 20px 0 80px;
	position: relative;
	z-index: 1;
}

.nm-equipment-search-form fieldset {
	border: none;
	height: auto;
	max-width: 590px;
	padding: 0;
	position: relative;
	width: auto;
}

.nm-equipment-search-input {
	background: #fff;
	border: 1px solid #d0d3d4;
	box-sizing: border-box;
	float: left;
	height: 40px;
	outline: none;
	padding: 10px 15px;
	width: calc(100% - 50px);
}

.nm-equipment-search-submit {
	background: no-repeat center center #000;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDUwIDUwIj48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMjkuMjIgMzAuNzhsLTEuNTY0LTEuNTYgMS4wOTQtLjk0IDEuNTYyIDEuNTY0TTM5LjA2MiA0MC42MjVjLS4xNTYgMC0uNDcgMC0uNDctLjE1NmwtOC4yOC04LjQ0IDEuMDk0LTEuMDkzIDguMjggOC40MzhjLjMxMy4zMTIuMzEzLjc4IDAgMS4wOTQtLjE1NS4xNTUtLjQ2Ny4xNTUtLjYyNC4xNTV6TTIwLjYyNSAzMGgtLjE1NmMtMi44MTMgMC01LjQ3LTEuMjUtNy4zNDUtMy4xMjUtMS44NzUtMi4wMy0yLjk3LTQuNTMtMi45Ny03LjM0NC4xNTctNS42MjQgNC44NDUtMTAuMTU1IDEwLjQ3LTEwLjE1NWguMTU2YzUuNzgzLjE1NiAxMC4zMTMgNC44NDQgMTAuMzEzIDEwLjQ3QzMwLjkzOCAyNS40NjggMjYuMjUgMzAgMjAuNjI1IDMwem0wLTE5LjIyYy00Ljg0NCAwLTguOTA2IDMuOTA3LTguOTA2IDguNzUgMCAyLjM0NS43OCA0LjUzMiAyLjUgNi4yNSAxLjcxOCAxLjcyIDMuOTA1IDIuNjU3IDYuMjUgMi42NTdoLjE1NWM0Ljg0NCAwIDguOTA2LTMuOTA2IDguOTA2LTguNzUuMTU3LTQuNjg4LTMuNzUtOC43NS04Ljc1LTguOTA2aC0uMTU1eiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoNTB2NTBIMHoiLz48L3N2Zz4=");
	background-size: 35px 35px;
	border: none;
	cursor: pointer;
	float: right;
	height: 40px;
	width: 40px;
}

.nm-equipment-search-submit:hover {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDUwIDUwIj48cGF0aCBmaWxsPSIjQzAzIiBkPSJNMjkuMjIgMzAuNzhsLTEuNTY0LTEuNTYgMS4wOTQtLjk0IDEuNTYyIDEuNTY0TTM5LjA2MiA0MC42MjVjLS4xNTYgMC0uNDcgMC0uNDctLjE1NmwtOC4yOC04LjQ0IDEuMDk0LTEuMDkzIDguMjggOC40MzhjLjMxMy4zMTIuMzEzLjc4IDAgMS4wOTQtLjE1NS4xNTUtLjQ2Ny4xNTUtLjYyNC4xNTV6TTIwLjYyNSAzMGgtLjE1NmMtMi44MTMgMC01LjQ3LTEuMjUtNy4zNDUtMy4xMjUtMS44NzUtMi4wMy0yLjk3LTQuNTMtMi45Ny03LjM0NC4xNTctNS42MjQgNC44NDUtMTAuMTU1IDEwLjQ3LTEwLjE1NWguMTU2YzUuNzgzLjE1NiAxMC4zMTMgNC44NDQgMTAuMzEzIDEwLjQ3QzMwLjkzOCAyNS40NjggMjYuMjUgMzAgMjAuNjI1IDMwem0wLTE5LjIyYy00Ljg0NCAwLTguOTA2IDMuOTA3LTguOTA2IDguNzUgMCAyLjM0NS43OCA0LjUzMiAyLjUgNi4yNSAxLjcxOCAxLjcyIDMuOTA1IDIuNjU3IDYuMjUgMi42NTdoLjE1NWM0Ljg0NCAwIDguOTA2LTMuOTA2IDguOTA2LTguNzUuMTU3LTQuNjg4LTMuNzUtOC43NS04Ljc1LTguOTA2aC0uMTU1eiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoNTB2NTBIMHoiLz48L3N2Zz4=");
}

.nm-equipment-search-submit:focus,
.nm-equipment-search-submit:active {
	outline: none;
}

.nm-equipment-search-reset {
	background: no-repeat center center transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDozNEY3ODQzODM3MjE2ODExODcxRkRGNUM1MjI2RjdGNCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3QUJCOTlDOTYxNUQxMUUxQjk3MkNFRDQ1QjUwNzdGMiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3QUJCOTlDODYxNUQxMUUxQjk3MkNFRDQ1QjUwNzdGMiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRCNzc4RkYyNUMyMTY4MTE4REJCQzQzNzNEOTU3OTJEIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjM0Rjc4NDM4MzcyMTY4MTE4NzFGREY1QzUyMjZGN0Y0Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+LhmcdAAAAI1JREFUeNrslm0KwCAIhnUn6Wjd/8+O4AxcLCKsQcjYK7wEJfmQH8QiQpF2ULABAAAAAMAUADOLKqvIUfFZG61lFHtSy8XV1pFVn5k7692TAB5Ec7YLYATR7e0EeAZMpu5VdgOQBT5N6U1d3Qpvw8+lILQIs+U8pA3DB5EXvIFYAWD8igEAAAD8HuASYADUY36JrRTJQAAAAABJRU5ErkJggg==");
	border: none;
	color: #fff;
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 40px;
	left: calc(100% - 90px);
	outline: none;
	position: absolute;
	top: 0;
	width: 40px;
}

.nm-equipment-search-result-audicode-wrap {
	height: auto;
	z-index: 100;
}

.nm-equipment-search-result-wrap {
	border-bottom: 1px solid #efefef;
	border-top: 1px solid #efefef;
	max-height: 360px;
	overflow-x: hidden;
	overflow-y: auto;
}

.nm-equipment-search-result-container {
	margin-top: 55px;
	-webkit-overflow-scrolling: touch;
	position: relative;
}

.nm-equipment-search-result-group {
	margin-bottom: 30px;
}

.nm-equipment-search-result-group > li > div {
	margin-bottom: 16px;
}

.nm-equipment-search-result-group h2 {
	margin-bottom: 10px;
	margin-top: 13px;
}

.nm-equipment-search-result-group h2 span {
	margin-right: 10px;
}

.nm-equipment-search-result-group h2 span:first-child::after {
	background: left center no-repeat url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAJCAYAAAD+WDajAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQwMjk3RDBGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQwMjk3RDFGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZGNUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZGNkZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhOroJcAAACGSURBVHjaYjzDYKzAwMBwngECHI3/n7kAZTMwATkPgHQhEAsA8f6zjCYKcEkQAVSwAEhNgCpYD1QAohkY////D1PIABQEGW8AxCCjHZkYUMEBKA0yWgCuE6grAGQkEH+AOYwJKgEyaj5UVyHMxYxAr4BdCbUrEeo4MGCBqQYZh+xHEAAIMACW6i2Fr+XVoQAAAABJRU5ErkJggg==");
	content: "";
	display: inline-block;
	height: 15px;
	margin-left: 10px;
	vertical-align: middle;
	width: 7px;
}

.nm-equipment-search-result-list {
	border-bottom: 1px solid #e7e9ea;
	overflow: hidden;
}

.nm-equipment-search-result-group:last-of-type .nm-equipment-search-result-list:last-child {
	border: none;
}

.nm-equipment-search-result-list li {
	margin-bottom: 30px;
}

.nm-equipment-search-result-list li {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.nm-equipment-search-result-list .nm-equipment-search-result-image {
	background: #e7e9ea;
	height: 80px;
	margin-right: 20px;
	min-width: 145px;
	overflow: hidden;
	position: relative;
	width: 145px;
}

.nm-equipment-search-result-item-wrap {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
}

.nm-equipment-search-result-list .nm-equipment-search-result-image img {
	height: auto;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-equipment-search-result-content-wrap {
	display: flex;
	flex-flow: row nowrap;
	margin-right: auto;
	width: calc(100% - 165px);
}

.nm-equipment-search-result-desc {
	padding-right: 10px;
	width: calc(60% - 60px);
}

.nm-equipment-search-result-desc p {
	margin-bottom: 5px;
}

.nm-equipment-search-result-content p {
	margin: 0 0 4px;
}

.nm-equipment-search-result-description + span {
	margin-top: 12px;
}

.nm-equipment-search-result-description .nm-info {
	float: left;
	margin-right: 20px;
}

.nm-equipment-search-result-icons {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	margin-left: auto;
	width: 60px;
}

.nm-equipment-search-result-icons .nm-info {
	float: left;
	margin-right: 10px;
}

.nm-equipment-search-result-icons .nm-conflict-icon {
	float: left;
	left: auto;
	margin: 0;
	position: relative;
	top: auto;
}

.nm-equipment-search-result-price {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	margin: 0 0 0 auto;
	padding-right: 10px;
	text-align: right;
	width: 21%;
}

.nm-equipment-search-result-add-btn {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
	min-width: 145px;
	text-align: right;
	width: 19%;
}

.nm-equipment-search .nm-error {
	float: none;
	min-height: 1px;
	position: absolute;
	top: 25px;
}

.nm-md-equipment-search .nm-equipment-search form {
	width: 100%;
}

.nm-equipment-search-main {
	padding: 20px 4%;
}

.nm-equipment-search-header {
	padding: 36px 4% 20px;
}

.nm-equipment-search-header h2 {
	margin-bottom: 22px;
}

.nm-equipment-search-footer {
	bottom: 0;
	height: 50px;
	left: 0;
	position: fixed;
	right: 0;
	z-index: 2;
}

.nm-equipment-search-footer__inner-wrapper {
	align-items: center;
	background-color: #e5e5e5;
	box-shadow: 0 0 5px rgba(0, 0, 0, .2);
	box-sizing: border-box;
	display: flex;
	flex-flow: row nowrap;
	height: 50px;
	justify-content: space-between;
	padding: 0 4%;
}

.nm-el-btn-equipment-search {
	padding-left: 45px;
}

.nm-equipment-search-footer span {
	margin: 0;
}

.nm-el-btn-equipment-search::before {
	background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyMXB4IiBoZWlnaHQ9IjIwcHgiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjEgMjAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjx0aXRsZT5zZWFyY2gtc21hbGw8L3RpdGxlPjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPjxwYXRoIGlkPSJTdHJva2UtMSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjAuOTk5IiBkPSJNMTMuNSw3YzAsMy45OC0yLjkzOSw2LjUwNS02LjUsNi41MDVTMC41LDEwLjU2NCwwLjUsN2MwLTMuNTY1LDIuOTM5LTYuNTA1LDYuNS02LjUwNVMxMy41LDMuNDM1LDEzLjUsN0wxMy41LDd6Ii8+PHBhdGggaWQ9IlN0cm9rZS0yIiBmaWxsPSJub25lIiBzdHJva2U9IiNGRkZGRkYiIGQ9Ik0yMCwyMGwtOC4zMzQtOC4zMzQiLz48L3N2Zz4=") 0 0 no-repeat;
	content: "";
	height: 20px;
	left: 15px;
	margin-top: -10px;
	position: absolute;
	top: calc(50% + 1px);
	width: 21px;
}

.nm-md-options-container .nm-md-equipment-search-btn {
	margin-top: 20px;
}

.nm-md-options-list .nm-md-equipment-search-btn {
	margin-top: -40px;
}

.nm-layer-wrapper-equipment-search-layer ~ .nm-layer-wrapper > .nm-layer.nm-layer-visible {
	background-color: #fff;
	min-height: 100%;
}

@media screen and (max-width: 1100px) {
	.nm-equipment-search-result-content {
		width: 340px;
	}
}

@media screen and (max-width: 749px) {
	.nm-equipment-search input[type="text"] {
		width: calc(100% - 50px);
	}

	.nm-equipment-search-result-list li {
		display: block;
		padding-right: 70px;
		position: relative;
	}

	.nm-equipment-search-result-content-wrap {
		display: block;
		width: 100%;
	}

	.nm-equipment-search-result-add-btn,
	.nm-equipment-search-result-desc,
	.nm-equipment-search-result-price {
		justify-content: flex-start;
		text-align: left;
		width: 100%;
	}

	.nm-equipment-search-result-price {
		margin: 10px 0 15px;
	}

	.nm-equipment-search-result-icons {
		position: absolute;
		right: 0;
		top: 0;
	}

	.nm-equipment-search-footer .nm-j-configurator-text-description,
	.nm-equipment-search-rate {
		display: none;
	}

	.nm-equipment-search-footer .nm-j-configurator-text-price {
		font-family: Verdana, sans-serif;
	}

	.nm-layer-wrapper-equipment-search-layer ~ .nm-layer-wrapper {
		left: 0;
		overflow-y: scroll;
		position: fixed;
		top: 0;
		z-index: 102;
	}

	.nm-equipment-search-submit {
		width: 49px;
	}

	.nm-equipment-search-form input[type="text"] {
		width: calc(100% - 60px) !important;
	}

	input.nm-equipment-search-reset {
		font-size: 0 !important;
		left: calc(100% - 100px);
	}

	.nm-equipment-search-result-list .nm-equipment-search-result-image {
		min-width: 135px;
		width: 135px;
	}

	.nm-equipment-search-result-content-wrap {
		width: calc(100% - 155px);
	}

	.nm-equipment-search-result-desc {
		hyphens: auto;
		word-break: break-all;
	}

	.nm-equipment-search-footer__inner-wrapper {
		display: block;
		height: 50px;
		padding: 6px 4%;
		top: calc(100vh - 50px - 15px);
	}

	.nm-equipment-search-footer > span {
		display: block;
		margin: 0;
	}
}

@media screen and (min-width: 750px) {
	.nm-equipment-search-footer__text-price-rate {
		text-align: right;
	}
}

@media screen and (min-width: 1024px) {
	.nm-equipment-search-footer {
		overflow-y: scroll;
	}

	.nm-equipment-search-footer__inner-wrapper {
		margin: 0 auto;
		max-width: 1440px;
		padding: 0 3.04%; /* 4:100=x:76 >> x=4*76/100 */
		position: relative;
		width: 76%;
	}
}

@media screen and (min-width: 1921px) {
	.nm-equipment-search-footer__inner-wrapper {
		padding: 0 57.6px; /* 4% of 1440px */
	}
}
