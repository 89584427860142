.nm-module-current-consumption {
	background-color: var(--color-grey-05);
	border-bottom: 1px solid var(--color-grey-20);
	box-sizing: border-box;
	display: block;
	max-width: 1400px;
	padding: var(--space-xs) 4% var(--space-s);
	width: 100%;
}

.nm-nav-fixed .nm-module-current-consumption {
	position: fixed;
	top: 54px;
	z-index: 8;
}

.nm-module-current-consumption-list {
	display: inline-block;
	margin: var(--space-xs) var(--space-xl) 0 0;
}

.nm-module-current-consumption-list:last-child {
	margin-right: 0;
}

.nm-module-current-consumption-key,
.nm-module-current-consumption-value {
	display: inline-block;
}

@media all and (min-width: 1401px) {
	.nm-module-current-consumption {
		padding-left: 56px;
		padding-right: 56px;
	}
}
