.modal-layer .modal-layer__content.audicode-rescue-wrapper {
	margin: auto;
	max-width: 750px;
	min-width: 320px;
}

.rescue-audicode {
	overflow: hidden;
}

.rescue-audi__close {
	height: var(--icon-size-large);
}

.rescue-audicode__text-container {
	padding: var(--space-l) 4% 0;
	position: relative;
}

.rescue-audicode__carline {
	margin-top: var(--space-s);
}

.rescue-audicode__description {
	margin-top: var(--space-s);
}

.rescue-audicode__price {
	font-weight: 700;
	margin-top: var(--space-xs);
}

.rescue-audicode__image-container {
	margin: -4% -5% 0;
	position: relative;
	width: 110%;
	z-index: -1;
}

.rescue-audicode__image-background {
	bottom: 40%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.rescue-audicode__image-picture {
	position: relative;
}

.rescue-audicode__image {
	margin: 0 auto;
	max-width: 100%;
}

.rescue-audicode__text-options-container {
	padding: var(--space-m) 4% var(--space-xxxl);
}

.rescue-audicode__options-changed {
	margin-top: var(--space-l);
}

.rescue-audicode__options-load-configuration {
	display: inline-block;
	margin-top: var(--space-m);
}

.rescue-audicode__options-pdf {
	display: inline-block;
	margin-top: var(--space-s);
}

.rescue-audicode__disclaimer-entries {
	margin-top: var(--space-xl);
}

.rescue-audicode__disclaimer {
	margin-top: var(--space-xxs);
}

.rescue-audicode__disclaimer-label,
.rescue-audicode__disclaimer-text,
.rescue-audicode__disclaimer-value {
	display: inline;
}

.rescue-audicode__disclaimer-value {
	padding-left: var(--space-xs);
}

@media all and (min-width: 480px) {
	.modal-layer .modal-layer__content.audicode-rescue-wrapper {
		margin-left: 4%;
		margin-right: 4%;
	}

	.rescue-audi__close {
		height: auto;
	}

	.rescue-audicode__text-container {
		padding-top: calc(var(--space-l) + var(--icon-size-large));
	}
}

@media all and (min-width: 768px) {
	.modal-layer .modal-layer__content.audicode-rescue-wrapper {
		margin: auto;
	}

	.rescue-audicode__text-options-container {
		padding-top: var(--space-xl);
	}
}

@media all and (min-width: 1024px) {
	.rescue-audicode__image-container {
		margin-bottom: -24px;
	}
}
