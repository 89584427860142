/*	module - trimline-list
============================================================================= */

.nm-tc .nm-c2-b {
	display: inline-block;
}

.nm-tc .nm-info-wrap {
	display: inline-block;
	vertical-align: top;
}

.nm-tc-text {
	overflow: hidden; /* keeps a columnized view */
}

.nm-tc-text ul,
.nm-tc-text ol {
	list-style: disc;
	margin-left: 15px;
	padding-left: 1px;
}

.nm-1col .nm-tc-text {
	margin-top: 5px;
	overflow: visible; /* shows oversized contents (ie) */
}

.nm-tc .nm-info {
	display: inline-block;
	margin-left: 5px;
	vertical-align: top;
}

.nm-tc {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 50px; /* minimal distance to next element */
}

.nm-tc.nm-j-configurator-status_00000 {
	display: none;
}

.nm-el-hdl + .nm-tc:not(.nm-tc-text-image) {
	margin-top: 40px;
}

.nm-tc.nm-tc-text-image {
	margin-bottom: 80px;
}

.nm-tc:not(.nm-tc-text-image) + .nm-tc.nm-tc-text-image {
	margin-top: 75px;
}

/* default distance to next element - if image is missing, distance will be the minimal of parent container nm-tc */
.nm-tc-image {
	float: left;
	margin: 0 4% 0 0;
	width: 48%;
}

.nm-tc-image + .nm-tc-text {
	height: 100%;
	margin: auto 0 -.5rem 0;
	width: 48%;
}

.nm-tc .nm-checkbox-1,
.nm-tc .nm-checkbox-3 {
	float: left;
}

.nm-tc-label {
	display: inline-block;
	margin: 5px 0 17px 10px;
	max-width: 90%;
}

.nm-tc-label > p {
	display: inline-block;
}

.nm-tc .nm-c2-b {
	margin: 0 5px 0 0;
}

.nm-tc-label + .nm-el-pg-04 {
	margin: 8px 0 0; /* fight empty div classes with style */
}

.nm-tc .nm-c2 p {
	margin-bottom: 0; /* WA: Content comes wrapped in selectors */
}

@media screen and (max-width: 627px) {
	.nm-state-is-responsive .nm-tc {
		display: block; /* trimeline-list items vertically */
	}

	.nm-state-is-responsive .nm-tc-image {
		float: none;
		margin: 0 0 20px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-tc-text {
		width: 100%;
	}
}
