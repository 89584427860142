/*	module - browse preconfigurations
============================================================================= */

.nm-md-preconfs-teaser {
	margin: 0 auto 80px;
	position: relative;
}

.nm-md-preconfs-teaser .nm-peephole {
	min-height: 486px; /* prevents the nm-dot navi from jumping around if images are not displayed */
	overflow: hidden;
	position: relative;
}

/* Navigation */
.nm-md-preconfs-teaser .nm-dot {
	background: no-repeat center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExODcxRkRFNEQ4MjQzNkNCRSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MEQ2NDJBQjc1MjkxMUUyODlBN0JBODVGOEVBOTRCMiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MEQ2NDJBQTc1MjkxMUUyODlBN0JBODVGOEVBOTRCMiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkZDQzYyOEE3MEIyMDY4MTE4OEM2RUJDQjJCNDUyRTJDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTE4NzFGREU0RDgyNDM2Q0JFIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+nEMGgAAAAGZJREFUeNpizC2tZAACaSDuBWIbBgg4AsTFQPyUBUioA/ExIBZiQIBwIHYFYlsmINGCJgkDILEWJqhKXMCViYEAACnYjUd+N0hBDRC/wyIJEqsEKbgJxHpAvBKIX0DxSqjYTYAAAwA4ehFyQv9bbAAAAABJRU5ErkJggg==");
}

.nm-md-preconfs-teaser .nm-dot.nm-selected {
	background: no-repeat center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExODcxRkRFNEQ4MjQzNkNCRSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MEQ2NDJBNzc1MjkxMUUyODlBN0JBODVGOEVBOTRCMiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MEQ2NDJBNjc1MjkxMUUyODlBN0JBODVGOEVBOTRCMiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkZDQzYyOEE3MEIyMDY4MTE4OEM2RUJDQjJCNDUyRTJDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTE4NzFGREU0RDgyNDM2Q0JFIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+SYrVsQAAAElJREFUeNpiYoAAaSBeAcRPoHgFVAwM1IH4LRD/R8MgMS2QgtVYJGF4HUjBBzwKPjMxEAAgBbvxyO8m5Eh1BjRvPodiuDcBAgwAyjEjmuCYvhEAAAAASUVORK5CYII=");
}

/* Swipe-Cursor only visible with dot navigation */
.nm-md-preconfs-teaser {
	cursor: url("data:image/png;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAA//AAAd/wAAGf+AAAH9gAADbYAAA2yAAAZsAAAGbAAAAGAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//4AB//8AAf//AAD//5AA///gAP//4AD//8AF///AB///5A////5///8="), auto;
}

.nm-md-preconfs-teaser.nm-preconfs-list-single {
	cursor: default;
}

/* Elements */
.nm-md-preconfs-teaser .nm-el-image {
	box-sizing: border-box;
	float: left;
	max-height: 435px;
	width: 60.9%;
}

.nm-md-preconfs-teaser .nm-el-text {
	box-sizing: border-box;
	float: right;
	margin-top: 23px;
	width: 39.1%;
}

.nm-md-preconfs-teaser .nm-hl-pu {
	margin-bottom: 15px;
}

.nm-el-image .nm-el-image-wrap {
	margin-left: -25%;
	width: 145%;
}

.nm-el-image .nm-el-inner-img {
	background-repeat: no-repeat;
	background-size: cover;
	height: 0;
	overflow: hidden;
	padding-bottom: 46.875%;
	width: 100%;
}

.nm-md-preconfs-teaser .nm-el-hdl-03 {
	margin-bottom: 2px;
}

.nm-md-preconfs-teaser .nm-el-lbl-01 {
	margin-bottom: 21px;
}

.nm-md-preconfs-teaser .nm-el-pg-02 {
	margin-bottom: 13px;
}

.nm-md-preconfs-teaser .nm-el-lbl-03 {
	margin-bottom: 27px;
}

.nm-md-preconfs-teaser .nm-el-btn-01 {
	max-width: 280px;
}

.nm-md-preconfs-teaser .nm-el-btn-01 .nm-md-preconfs-teaser-custom-config,
.nm-md-preconfs-teaser .nm-md-preconfs-teaser-custom-configuration .nm-el-btn-01 .nm-md-preconfs-teaser-default-config {
	display: none;
}

.nm-md-preconfs-teaser .nm-md-preconfs-teaser-custom-configuration .nm-el-btn-01 .nm-md-preconfs-teaser-custom-config {
	display: inline;
}

.nm-md-preconfs-teaser .nm-dots-navi {
	bottom: 95px;
	display: block;
	opacity: 1;
	position: relative;
	z-index: 10;
}

/*	module - browse preconfigurations - breakpoints for responsiveness
============================================================================= */

@media all and (max-width: 919px) {
	.nm-md-preconfs-teaser .nm-el-image,
	.nm-md-preconfs-teaser .nm-el-text {
		float: none;
		width: 100%;
	}

	.nm-md-preconfs-teaser .nm-el-text {
		max-width: 400px;
	}

	.nm-md-preconfs-teaser .nm-dots-navi {
		bottom: -20px;
		left: auto;
		width: 100%;
	}

	.nm-el-image .nm-el-image-wrap {
		height: 0;
		padding-bottom: 52%;
	}

	.nm-md-preconfs-teaser {
		margin: 0 auto 100px;
	}
}
