/*	module - recommendation
============================================================================= */

.nm-recommendation > h2 {
	margin-bottom: 13px;
}

.nm-recommendation > p {
	margin-bottom: 19px;
}

.nm-recommendation .nm-recommendation-container strong {
	margin-bottom: 9px;
}

.nm-recommendation .nm-recommendation-container .nm-module {
	margin-bottom: 74px;
}

.nm-recommendation .nm-info {
	margin-left: 0;
	margin-right: 5px;
}

.nm-recommendation .nm-md-teaser-equipment {
	margin: 33px 0 54px;
}

.nm-recommendation .nm-md-teaser-equipment + .nm-md-teaser-equipment {
	margin-top: 100px;
}

.nm-recommendation .nm-md-teaser-equipment strong {
	width: -webkit-calc(100% - 30px);
	width: calc(100% - 30px);
}

.nm-wrapper .nm-j-link-recommendations {
	display: none;
}

.nm-has-recommendations .nm-j-link-recommendations {
	display: inline-block;
}