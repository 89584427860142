
.nm-md-inline-rendering {
	margin-bottom: 30px;
}

.nm-md-inline-rendering .nm-md-in-landscape,
.nm-md-inline-rendering .nm-md-in-portrait {
	background-repeat: no-repeat;
	background-size: 100%;
	height: 0;
	width: 100%;
}

.nm-md-inline-rendering .nm-md-ave-container {
	display: none;
}

.nm-md-inline-rendering .nm-md-ave-container.nm-active {
	display: block;
}

.nm-md-inline-rendering .nm-3d-loading {
	background-image: url("../assets/img/ave-loading-image.jpg") !important;
	background-position: center center;
}

.nm-md-inline-rendering .nm-3d-unloading {
	background-color: rgb(232, 233, 235);
	background-image: none !important;
}

.nm-md-inline-rendering .nm-md-in-loader {
	background-image: url("../assets/img/homepage/spinner.gif");
	background-repeat: no-repeat;
	bottom: 35px;
	display: none;
	height: 5px;
	left: -webkit-calc(50% - 19px);
	left: calc(50% - 19px);
	position: absolute;
	width: 37px;
}

.nm-md-inline-rendering .nm-3d-loading ~ .nm-md-in-loader {
	display: block;
}

.nm-md-inline-rendering .nm-md-in-landscape-wrapper,
.nm-md-inline-rendering .nm-md-in-portrait-wrapper {
	overflow: hidden;
	position: relative;
}

.nm-md-inline-rendering .nm-icon-360-view::before,
.nm-md-inline-rendering .nm-icon-2d-view::before {
	left: 4px;
	top: 3px;
	width: 50px;
}

.nm-md-inline-rendering .nm-icon-360-view,
.nm-md-inline-rendering .nm-icon-2d-view {
	background: rgba(0, 0, 0, .3);
	border: 2px solid rgba(255, 255, 255, .1);
	border-radius: 50px;
	box-sizing: border-box;
	display: none;
	height: 50px;
	padding: 0 10px;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 50px;
}

.nm-md-inline-rendering .nm-icon-360-view:hover,
.nm-md-inline-rendering .nm-icon-2d-view:hover {
	background: rgba(0, 0, 0, .4);
	border: 2px solid rgba(255, 255, 255, .2);
}

.nm-md-inline-rendering .nm-icn-inline-rendering-zoom {
	bottom: 10px;
	display: inline-block;
	height: 40px;
	position: absolute;
	right: 10px;
	width: 40px;
}

.nm-md-inline-rendering .nm-icn-inline-rendering-zoom::before {
	background-position: 50% 50%;
	height: 40px;
	left: 0;
	width: 40px;
}

.nm-md-inline-rendering .nm-md-in-landscape {
	padding-bottom: 31.25%;
	transition: padding 1s;
}

.nm-md-inline-rendering .nm-md-in-portrait,
.nm-md-inline-rendering .nm-md-in-landscape.nm-ave-candidate {
	padding-bottom: 60%;
}

.nm-md-inline-rendering.nm-md-in-mode-landscape .nm-md-in-portrait-wrapper,
.nm-md-inline-rendering.nm-md-in-mode-portrait .nm-md-in-landscape-wrapper {
	display: none;
}

@media all and (max-aspect-ratio: 1/1) {
	.nm-md-inline-rendering.nm-md-in-mode-automatic .nm-md-in-landscape-wrapper {
		display: none;
	}
}

@media all and (min-aspect-ratio: 1/1) {
	.nm-md-inline-rendering.nm-md-in-mode-automatic .nm-md-in-portrait-wrapper {
		display: none;
	}
}
