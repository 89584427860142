/*******************************************************************

	Trimline Engine Combined
	scope is .nm-module-trimline-engine

********************************************************************/

.nm-module-trimline-engine {
	/* scope */
}

.nm-module-trimline-engine-wrapper {
	display: flex;
	flex-flow: column nowrap;
}

.nm-module-trimline-engine-headline-main {
	font-weight: var(--font-weight-bold);
	margin-bottom: 42px;
}

.nm-module-trimline-engine-container {
	margin-bottom: 42px;
}

/* --- trimline --- */

.nm-module-trimline-engine-headline-wrapper {
	margin-bottom: 18px;
	position: relative;
}

.nm-module-trimline-engine-headline {
	font-weight: var(--font-weight-bold);
	width: calc(100% - 42px); /* icon width plus gutter 18px */
}

.nm-scope-no-prices .nm-module-trimline-engine-headline-sub {
	display: none;
}

.nm-module-trimline-engine-price-tag {
	white-space: nowrap;
}

.nm-module-trimline-engine-headline-infolink {
	height: 24px;
	position: absolute;
	right: 0;
	top: 0;
	width: 24px;
}

.nm-module-trimline-engine-icon-info {
	height: 24px;
	width: 24px;
}

/* --- image --- */

.nm-module-trimline-engine-image-container {
	margin: 0 -4.8152% 18px;
}

.nm-module-trimline-engine-image {
	height: auto;
	width: 100%;
}

/* --- engine list --- */

.nm-module-trimline-engine-list-item {
	border: 1px solid #d9d9d9;
	border-top: none;
	padding: 24px;
	position: relative;
}

.nm-module-trimline-engine-list-item:first-of-type {
	border: 1px solid #d9d9d9;
}

.nm-module-trimline-engine-infolink {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	position: absolute;
	right: 24px;
	top: 24px;
}

.nm-module-trimline-engine-checkbox {
	cursor: pointer;
	display: inline-block;
	height: 24px;
	margin-bottom: 18px;
}

.nm-module-trimline-engine-icon-check {
	cursor: pointer;
	display: block;
	position: relative;
}

.nm-module-trimline-engine-list-label {
	align-items: baseline;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
}

.nm-module-trimline-engine-name {
	color: #4c4c4c;
	font-weight: var(--font-weight-bold);
	margin-right: 7px;
}

.nm-module-trimline-engine-name-sub {
	color: #4c4c4c;
}

.nm-module-trimline-engine-description {
	color: #4c4c4c;
	display: none;
	margin: 12px 0 18px;
}

.nm-module-trimline-engine-price {
	color: #4c4c4c;
	margin-top: 12px;
}

/* --- highlighted states --- */

.nm-module-trimline-engine-list-item[data-state="active"],
.nm-module-trimline-engine-list-item.nm-j-configurator-status_11010 {
	border: 1px solid #000;
}

/* checkbox highlighted/active */
.nm-module-trimline-engine-list-item.nm-j-configurator-status_11010 .nm-module-trimline-engine-checkbox {
	cursor: auto;
}

.nm-module-trimline-engine-list-item.nm-j-configurator-status_11010 .nm-module-trimline-engine-icon-check {
	opacity: 1;
}

/* engine name highlighted */
.nm-module-trimline-engine-list-item[data-state="active"] .nm-module-trimline-engine-name,
.nm-module-trimline-engine-list-item.nm-j-configurator-status_11010 .nm-module-trimline-engine-name {
	color: #000;
}

/* engine name additional highlighted */
.nm-module-trimline-engine-list-item[data-state="active"] .nm-module-trimline-engine-name-sub,
.nm-module-trimline-engine-list-item.nm-j-configurator-status_11010 .nm-module-trimline-engine-name-sub {
	color: #000;
}

/* engine description is visible */
.nm-module-trimline-engine-list-item[data-state="active"] .nm-module-trimline-engine-description,
.nm-module-trimline-engine-list-item.nm-j-configurator-status_11010 .nm-module-trimline-engine-description {
	color: #000;
	display: block;
}

/* engine price highlighted */
.nm-module-trimline-engine-list-item[data-state="active"] .nm-module-trimline-engine .nm-module-trimline-engine-price,
.nm-module-trimline-engine-list-item.nm-j-configurator-status_11010 .nm-module-trimline-engine .nm-module-trimline-engine-price {
	color: #000;
}

/* --- benefit list --- */

.nm-module-trimline-engine-benefit-wrapper .nm-j-configurator-status_11000 {
	display: none;
}

.nm-module-trimline-engine-benefit-wrapper .nm-j-configurator-status_11010 ~ .nm-module-trimline-engine-benefit-list-first {
	display: none;
}

.nm-module-trimline-engine-benefit-list {
	margin-top: 30px;
}

/* container breakpoint */
@media all and (min-width: 640px) {
	.nm-module-trimline-engine-wrapper {
		flex-flow: row wrap;
		overflow: hidden; /* prevents an unintended scrolling */
		width: 108.69%; /* 100% divided 92% = 1.0869... */
	}

	.nm-module-trimline-engine-container {
		margin-right: 4%;
		width: 44%; /* 11 gridcols plus 1 gridcol for the margin */
	}

	/* trimline image is not overlapping anymore */
	.nm-module-trimline-engine-image-container {
		margin: 0 0 18px;
	}
}

/* container breakpoint */
@media all and (min-width: 1024px) {
	/* default state: 4 containers show up in a row with 4 columns and 20% width */
	.nm-module-trimline-engine-wrapper[data-trimline-counter="4"] .nm-module-trimline-engine-container {
		width: 20%;
	}

	/* 1 or 2 containers show up in a row with 1 column or 2 columns and 44% width */
	.nm-module-trimline-engine-wrapper[data-trimline-counter="1"] .nm-module-trimline-engine-container,
	.nm-module-trimline-engine-wrapper[data-trimline-counter="2"] .nm-module-trimline-engine-container {
		width: 44%;
	}

	/* 3 containers show up in a row with 3 columns and 28% width */
	.nm-module-trimline-engine-wrapper[data-trimline-counter="3"] .nm-module-trimline-engine-container {
		width: 28%;
	}
}

/* breakpoint for vertical rhythm according design system */
@media all and (min-width: 768px) {
	.nm-module-trimline-engine-headline-main {
		margin-bottom: 48px;
	}

	.nm-module-trimline-engine-container {
		margin-bottom: 48px;
	}
}

/* breakpoint for vertical rhythm according design system */
/* styling on basic elements */

@media all and (min-width: 1024px) {
	.nm-module-trimline-engine-headline-main {
		margin-bottom: 60px;
	}

	.nm-module-trimline-engine-container {
		margin-bottom: 60px;
	}
}

/* breakpoint for vertical rhythm according design system */
/* styling on basic elements */

@media all and (min-width: 1440px) {
	.nm-module-trimline-engine-headline-main {
		margin-bottom: 78px;
	}

	.nm-module-trimline-engine-container {
		margin-bottom: 78px;
	}

	.nm-module-trimline-engine-benefit-list {
		margin-top: 36px;
	}
}

/* breakpoint for vertical rhythm according design system */
/* styling on basic elements */

@media all and (min-width: 1920px) {
	.nm-module-trimline-engine-headline-main {
		margin-bottom: 96px;
	}

	.nm-module-trimline-engine-container {
		margin-bottom: 96px;
	}

	.nm-module-trimline-engine-benefit-list {
		margin-top: 42px;
	}
}
