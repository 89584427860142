.nm-offer-rate-container {
	height: 48px;
	position: relative;
	width: 48px;
}

.nm-offer-rate {
	display: block;
	height: 48px; /* WA for FF: add dimensions explicitly for a working intersection observer */
	width: 48px;
}

.nm-offer-rate-text {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}

.nm-offer-rate-image {
	line-height: 0;
}

.nm-offer-rate-icon {
	height: 48px;
	transform: rotate(90deg) scaleY(-1);
	width: 48px;
}

.nm-offer-rate-path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	stroke-linecap: butt;
}
