.carstore-list-item {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.carstore-list-item:not(:first-of-type) {
	margin-top: var(--space-xxxl);
}

.carstore-list-item:last-of-type {
	margin-bottom: var(--space-xxxl);
}

.carstore-list-item--fail:first-of-type {
	margin-top: var(--space-l);
}

.carstore-list-item__image-container {
	display: flex;
	justify-content: center;
	margin-top: var(--space-l);
	max-width: 320px;
}

.carstore-list-item__image {
	width: 100%;
}

.carstore-list-item__image--fail {
	box-sizing: border-box;
	padding: 0 12%;
}

.carstore-list-item__carline {
	font-weight: var(--font-weight-bold);
	margin-top: var(--space-l);
}

.carstore-list-item__engine {
	margin-top: var(--space-xs);
}

.carstore-list-item__price {
	font-weight: var(--font-weight-bold);
	margin-top: var(--space-s);
}

.carstore-list-item__form {
	margin-top: var(--space-s);
}

.carstore-list-item__legend {
	display: none;
}

.carstore-list-item__form-dialogue {
	display: none;
}

.carstore-list-item--show-dialogue .carstore-list-item__form-administration,
.carstore-list-item--show-dialogue .carstore-list-item__description {
	display: none;
}

.carstore-list-item--show-dialogue .carstore-list-item__form-dialogue {
	display: flex;
}

.carstore-list-item__audicode {
	color: var(--color-grey-50);
	margin-top: var(--space-s);
}

.carstore-list-item__button {
	margin-top: var(--space-xxl);
	max-width: initial;
	width: 100%;
}

.carstore-list-item__button--delete {
	margin-top: var(--space-l);
}

.carstore-list-item__button--cancel {
	margin-top: var(--space-xs);
}

@media all and (max-width: 767px) {
	.carstore-list-item__form-select-wrapper {
		width: auto; /* overwrites the default width of basis select that overflows its container */
	}
}

@media all and (min-width: 768px) {
	.carstore-list-item {
		flex-flow: row wrap;
	}

	.carstore-list-item--fail {
		margin-top: var(--space-l);
	}

	.carstore-list-item__description {
		order: -1;
		width: 40%;
	}

	.carstore-list-item__image-container {
		margin-left: var(--space-l);
		margin-top: 0;
		order: 0;
		width: 60%;
	}

	.carstore-list-item__form {
		order: 1;
		width: 40%;
	}

	.carstore-list-item--show-dialogue .carstore-list-item__form {
		margin-top: var(--space-l);
		order: -1;
		width: 40%;
	}

	.carstore-list-item__button {
		width: auto;
	}

	.carstore-list-item__form-select-wrapper {
		max-width: 630px;
	}
}
