.nm-tooltip-container .nm-tooltip-popup {
	color: #fff;
	position: absolute;
	right: 0;
	text-align: left;
	top: 42px;
	transform: translateX(33%) translateX(-18px); /* double translate for IE11 || 1/3 width - 1/2 width caution icon */
	visibility: hidden;
	width: 160px;
	z-index: 3;
}

.nm-tooltip-container .nm-tooltip-popup::before,
.nm-tooltip-container .nm-tooltip-popup::after {
	background: #4c4c4c;
	box-shadow: 0 0 2px rgba(0, 0, 0, 50);
	content: "";
	height: 15px;
	position: absolute;
	right: calc(33% - 15px); /* 1/3 container width - triangle width */
	top: -8px;
	transform: rotate(45deg);
	width: 15px;
	z-index: 1;
}

.nm-tooltip-container .nm-tooltip-popup::after {
	box-shadow: 0 0 0 transparent;
	z-index: 2;
}

.nm-tooltip-container .nm-tooltip-popup-text {
	background: #4c4c4c;
	box-shadow: 0 0 2px rgba(0, 0, 0, 50);
	font-weight: normal;
	display: block;
	padding: 33px 18px 18px; /* xl + xxs top distance for text */
	position: relative;
	word-wrap: break-word;
	z-index: 2;
}

.nm-tooltip-container .nm-tooltip-show,
.nm-tooltip-container .nm-tooltip-hide {
	cursor: pointer;
	display: inline-block;
}

.nm-tooltip-container .nm-tooltip-hide {
	position: absolute;
	right: 8px;
	top: 8px;
	z-index: 4;
}

.nm-tooltip-container .nm-j-tooltip-visible,
.nm-tooltip-container .nm-tooltip-show:hover ~ .nm-tooltip-popup {
	visibility: visible;
}

.nm-tooltip-container.nm-tip-left .nm-tooltip-popup,
.nm-tooltip-container.nm-tip-right .nm-tooltip-popup {
	top: 12px;
	transform: translateX(0) translateY(-50%);
}

.nm-tooltip-container.nm-tip-left .nm-tooltip-popup::before,
.nm-tooltip-container.nm-tip-left .nm-tooltip-popup::after {
	left: -8px;
	top: calc(50% - 8px);
}

.nm-tooltip-container.nm-tip-left .nm-tooltip-popup {
	left: 42px;
}

.nm-tooltip-container.nm-tip-right .nm-tooltip-popup {
	right: 42px;
}

.nm-tooltip-container.nm-tip-right .nm-tooltip-popup::before,
.nm-tooltip-container.nm-tip-right .nm-tooltip-popup::after {
	right: -8px;
	top: calc(50% - 8px);
}

@media screen and (min-width: 400px) {
	.nm-tooltip-container .nm-tooltip-popup {
		width: 240px;
	}

	.nm-tooltip-container .nm-tooltip-popup-text {
		width: 160px;
		padding-left: 18px;
		padding-right: 18px;
	}
}
